import { Cookies } from 'react-cookie';
import history from '../../history';
// import { parseURI } from '../../helpers/UriHelper';
import { getProductUrl, parseURI } from '../../helpers/UriHelper';
import devUri from '../../config/keys_dev';

/*
 * name: clearJumpCookie
 * action: get browser based cookie for the jump redirect URL
 * params:
 *   {}
 * */
const clearJumpCookie = () => {
  const cookies = new Cookies();
  let baseDomain = '';
  const hostName = window?.location?.hostname;
  const hostNameSplit = hostName.split('.');
  const hostLength = hostNameSplit?.length;
  const hostTLD = hostNameSplit[hostNameSplit?.length - 1].split(':')[0].toLowerCase();

  if (hostTLD === 'com') {
    // ex: via-trm.com
    baseDomain = `.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
  } else if (hostTLD === 'nz') {
    //ex: go.360international.ac.nz
    baseDomain = `.${hostNameSplit[hostLength - 3]}.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
  }

  try {
    cookies.remove('jump_redirect');

    cookies.remove('jump_redirect', { path: '/' });

    cookies.remove('jump_redirect', { domain: `${baseDomain}`, sameSite: 'lax', path: '/' });
  } catch (e) {
    // removeCookie('jump_redirect');
  }
};

/*
 * name: getJumpCookie
 * action: get browser based cookie for the jump redirect URL
 * params:
 *   {}
 * */
const getJumpCookie = () => {
  const cookies = new Cookies();

  try {
    return cookies.get('jump_redirect');
  } catch (e) {
    return '';
  }
};

/*
 * name: redirectOnJumpCookie
 * action: redirect if there is a jump cookie set
 * params:
 *   {}
 * */
const redirectOnJumpCookie = () => {
  const jumpCookie = getJumpCookie();

  try {
    if (jumpCookie && jumpCookie.length > 0) {
      clearJumpCookie();
      setTimeout(() => {
        const url = new URL(jumpCookie);
        const uri = parseURI(url);

        history.push(uri.relative);
      }, 1);
    }
  } catch (e) {
    clearJumpCookie();
  }
};

/*
 * name: setAuthCookie
 * action: sets a browser based cookie for the authenticated user
 * params:
 *   {
 *     email: string,
 *     user_id: string,
 *     token: string,
 *   }
 * */
const setAuthCookie = data => {
  const cookies = new Cookies();
  let baseDomain = '';
  const hostName = window?.location?.hostname;
  const hostNameSplit = hostName.split('.');
  const hostLength = hostNameSplit?.length;
  const hostTLD = hostNameSplit[hostNameSplit?.length - 1].split(':')[0].toLowerCase();

  if (hostTLD === 'com') {
    // ex: via-trm.com
    baseDomain = `.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
  } else if (hostTLD === 'nz') {
    //ex: go.360international.ac.nz
    baseDomain = `.${hostNameSplit[hostLength - 3]}.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
  }

  try {
    // clear cookies before setting to new values
    cookies.remove('user_id');
    cookies.remove('token');
    // cookies.remove('expires_at');
    cookies.remove('via-org-id');
    cookies.remove('sub-domain');

    cookies.remove('user_id', { path: '/' });
    cookies.remove('token', { path: '/' });
    // cookies.remove('expires_at', { path: '/' });
    cookies.remove('via-org-id', { path: '/' });
    cookies.remove('sub-domain', { path: '/' });

    cookies.remove('user_id', { path: '/', sameSite: 'lax', domain: baseDomain });
    cookies.remove('token', { path: '/', sameSite: 'lax', domain: baseDomain });
    // cookies.remove('expires_at', { path: '/', sameSite: 'lax', domain: baseDomain });
    cookies.remove('via-org-id', { path: '/', sameSite: 'lax', domain: baseDomain });
    cookies.remove('sub-domain', { path: '/', sameSite: 'lax', domain: baseDomain });

    // set new cookies values
    cookies.set('user_id', data.user_id, { path: '/', sameSite: 'lax', domain: baseDomain });
    cookies.set('via-org-id', data.client_id, { path: '/', sameSite: 'lax', domain: baseDomain });
    cookies.set('sub-domain', data.subdomain, { path: '/', sameSite: 'lax', domain: baseDomain });
    cookies.set(
      'token',
      {
        'X-User-Email': data.email,
        'X-User-Token': data.token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      { path: '/', sameSite: 'lax', domain: baseDomain },
    );
    // cookies.set('expires_at', data.expires_at, { path: '/', sameSite: 'lax', domain: baseDomain });

    return true;
  } catch (e) {
    return false;
  }
};

/*
 * name: getLoginPath
 * action: returns relative path as a string based on user role and account settings
 * params:
 *   {
 *     admin_sign_in: boolean,
 *     feature_legacy: boolean,
 *     feature_vt: boolean,
 *     freemium: boolean,
 *     redirect_jmp: string,
 *     last_visited_product: string
 *   }
 * */
const getLoginPath = data => {
  const ADMIN = '/client/dashboard';
  const TRAVELER = '/traveler/dashboard?login=true';
  const TERMS = '/terms';
  const INBOUND_ADMIN = getProductUrl('inbound');
  const INBOUND_TRAVELER = `${getProductUrl('inbound')}/intl/dashboard`;
  const CONTRACT = `${getProductUrl('contracts')}`;
  const lastVisitedProduct = data?.last_visited_product;

  if (data?.check_agreement && !data.tap_agreement) {
    //redirect to terms, if not accepted by admin/traveler
    return TERMS;
  }

  // Check if we have any last visited product info, else do default redirection
  if (lastVisitedProduct) {
    //if admin sign in
    if (data.admin_sign_in) {
      // if last visited product is via abroad
      if (lastVisitedProduct === 'via_abroad') {
        return ADMIN;
      }

      // if last visited product is via_international
      if (lastVisitedProduct === 'via_international') {
        return INBOUND_ADMIN;
      }

      // if last visited product is via_contracts
      if (lastVisitedProduct === 'via_contracts') {
        return CONTRACT;
      }
    } else {
      // check if traveler user
      if (lastVisitedProduct === 'via_abroad') {
        return TRAVELER;
      }

      if (lastVisitedProduct === 'via_international') {
        return INBOUND_TRAVELER;
      }
    }
  }

  // Default redirection
  if (
    (data.via_abroad && data.via_international && data.via_contracts) ||
    (data.via_abroad && !data.via_international && !data.via_contracts)
  ) {
    if (data.admin_sign_in) {
      //admin
      return ADMIN;
    } else {
      //traveler -
      return TRAVELER;
    }
  } else if (data.via_abroad && data.via_international && !data.via_contracts) {
    //redirect to inbound admin
    if (data.admin_sign_in) {
      return INBOUND_ADMIN;
    } else {
      //redirect to INBOUND TRAVELER
      return INBOUND_TRAVELER;
    }
  } else if (data.via_abroad && data.via_contracts && !data.via_international) {
    //redirect to contracts
    return CONTRACT;
  }

  if (data.admin_sign_in) {
    //admin
    return ADMIN;
  } else {
    //traveler -
    return TRAVELER;
  }
};

export { clearJumpCookie, getJumpCookie, getLoginPath, redirectOnJumpCookie, setAuthCookie };

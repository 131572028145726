import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { fetchTrackingStepOptions } from '../../../../../actions/lookupActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';

import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ApplicationTrackingStepFilter(props) {
  const dispatch = useDispatch();
  const trackingStepOptions = useSelector(state => state.lookup.allTrackingStepOptions);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!trackingStepOptions.trackingStepOptions) {
        dispatch(fetchTrackingStepOptions());
      } else {
        let options = [];

        trackingStepOptions.trackingStepOptions.map(item => {
          options.push(item);
        });

        if (props.addInterested) {
          options.splice(0, 0, 'Interested');
        }

        const optionValues = buildSelectOptions(options, false, false);

        setSourceOptions(optionValues);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [trackingStepOptions]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ApplicationTrackingStepFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  addInterested: propTypes.bool,
};

ApplicationTrackingStepFilter.defaultProps = {
  displayText: 'Tracking Step',
  selectedValues: [],
  addInterested: false,
};

export default enhance(ApplicationTrackingStepFilter);

export const ApplicationTrackingStepMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationTrackingStepMemo({
    selectedValues,
    onFilterChange,
    addInterested = false,
  }) {
    return (
      <ApplicationTrackingStepFilter
        id={'tracking_step'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('tracking_step', values);
        }}
        displayText={`Tracking Step`}
        addInterested={addInterested}
      />
    );
  }),
);

export const ApplicationTrackingStepsMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationTrackingStepMemo({
    selectedValues,
    onFilterChange,
    addInterested = false,
  }) {
    return (
      <ApplicationTrackingStepFilter
        id={'tracking_steps'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('tracking_steps', values);
        }}
        displayText={`Tracking Step`}
        addInterested={addInterested}
      />
    );
  }),
);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _concat from 'lodash/concat';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import FilterCheckBox from '../../filterTypes/filterCheckBox';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(
  withStyles(sFilterAccordion),
);

function GenericCheckboxFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }

    let valueNames = [];

    props.sourceData.forEach(item => {
      if (item.length > 0) {
        valueNames = _concat(valueNames, item);
      }
    });
    const optionValues = buildSelectOptions(valueNames, false, true);

    setSourceOptions(optionValues);
  }, [props]);

  const selectedValues = (values) => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>
            {props.displayText}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterCheckBox
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={(e) => selectedValues(e)}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

GenericCheckboxFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
};

GenericCheckboxFilter.defaultProps = {
  displayText: '',
  selectedValues: [],
};

export default enhance(GenericCheckboxFilter);

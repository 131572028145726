import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function FormStatusFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }

    const optionValues = buildSelectOptions(props.sourceData, false, true);
    setSourceOptions(optionValues);
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

FormStatusFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
};

FormStatusFilter.defaultProps = {
  displayText: 'General Form Status',
  selectedValues: [],
};

export default enhance(FormStatusFilter);

export const FormStatusFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function FormStatusFilterMemo({
    selectedValues,
    onFilterChange,
    sourceData = [],
  }) {
    return (
      <FormStatusFilter
        id={'form_status'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('form_status', values);
        }}
        sourceData={['Not Started', 'Started', 'Submitted', 'Accepted']}
      />
    );
  }),
);

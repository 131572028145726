import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Formik, Form, Field } from 'formik';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import InputSelect from '../../../../components/library/inputSelect';
import { fetchClientAdmins } from '../../../../../actions/lookupActions';
import sBatchUpdateMessageRecipient from './batchUpdateMessageRecipient.scss';
import OneClickModal from '../oneClickModal';

export const enhance = compose(withStyles(sBatchUpdateMessageRecipient));

function BatchUpdateMessageRecipient(props) {
  const dispatch = useDispatch();
  const [submitValues, setSubmitValues] = useState();
  const [admins, setAdmins] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const messageRecipients = useSelector(state => state.lookup.allClientAdmins);
  const messageRecipientAction = useSelector(state => state.reports.updateMessageRecipient);
  const confirmationMessage = props.removeRecipient
    ? `You are about to remove one or more message recipients from ${props.selectedUsers.length} ${
        props.selectedUsers.length === 1
          ? `${props.customAliases.alias_traveler.toLowerCase()}`
          : `${props.customAliases.alias_travelers.toLowerCase()}`
      }. Removed message recipients will no longer receive email messages to/from the ${props.selectedUsers.length} ${
        props.selectedUsers.length === 1
          ? `${props.customAliases.alias_traveler.toLowerCase()}`
          : `${props.customAliases.alias_travelers.toLowerCase()}`
      } selected.`
    : `You are about to add one or more message recipients to ${props.selectedUsers.length} ${
        props.selectedUsers.length === 1
          ? `${props.customAliases.alias_traveler.toLowerCase()}`
          : `${props.customAliases.alias_travelers.toLowerCase()}`
      }.`;

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        message_recipient: [],
      };
      return values;
    });
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      message_recipient: [],
    };
    return values;
  });

  useEffect(() => {
    if (!messageRecipients.clientAdmins) {
      dispatch(fetchClientAdmins());
    } else {
      let active = [];
      let archived = [];

      messageRecipients.clientAdmins.map(item => {
        if (!item.attributes.archived) {
          active.push({
            value: item.id,
            label: item.attributes.full_name_or_email,
          });
        } else {
          archived.push({
            value: item.id,
            label: item.attributes.full_name_or_email,
          });
        }
      });

      const groupOptions = [
        { label: 'Active', options: active },
        { label: 'Archived', options: archived },
      ];

      setAdmins(groupOptions);
    }
  }, [messageRecipients]);

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
        headerText={props.removeRecipient ? 'Remove Message Recipient' : 'Add Message Recipient'}
      >
        <div className={sBatchUpdateMessageRecipient['batch-update-message-recipient']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              if (values.message_recipient.length < 1) {
                errors.message_recipient = 'Error: Message Recipient Required';
              }

              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                message_recipient_ids: values.message_recipient.map(recipient => recipient.value),
                traveler_ids: props.selectedUsers,
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowConfirm(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchUpdateMessageRecipient.row}>
                  <div className={sBatchUpdateMessageRecipient.item}>
                    <Field name="message_recipient">
                      {({ form }) => (
                        <InputSelect
                          id={values.message_recipient}
                          name={values.message_recipient}
                          labelText="Message Recipients"
                          aria-label="Message Recipients Dropdown"
                          errorMessage={errors.message_recipient}
                          isRequired
                          helpText=""
                          isMulti={true}
                          value={values.message_recipient}
                          options={admins}
                          placeholder={admins.label}
                          onChange={e => form.setFieldValue('message_recipient', e)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sBatchUpdateMessageRecipient.footer}>
                  <div className={sBatchUpdateMessageRecipient['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchUpdateMessageRecipient['button-content']}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      type="submit"
                      aria-label="Update Message Recipient Button"
                    >
                      {props.removeRecipient ? 'Remove' : 'Add'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        headline={props.removeRecipient ? 'Remove Message Recipient' : 'Add Message Recipient'}
        show={showConfirm}
        onClose={() => {
          resetForm();
          setShowConfirm(false);
          props.setSelectedPrograms();
        }}
        paragraphOne={confirmationMessage}
        paragraphTwo="Are you sure you want to continue?"
        cancelMessage={'Cancel'}
        confirmActionMessage={props.removeRecipient ? 'Remove Message Recipient' : 'Add Message Recipient'}
        source={props.removeRecipient ? 'remove_message_recipient' : 'add_message_recipient'}
        selectedPrograms={props.formIds}
        actionState={messageRecipientAction}
        batchUpdateMessageRecipientSuccess={`${props.userIds.length} ${
          props.userIds.length === 1
            ? props.customAliases.alias_traveler.toLowerCase()
            : props.customAliases.alias_travelers.toLowerCase()
        } updated `}
        resetForm={() => resetForm()}
        submitValues={submitValues}
        fetchApps={props.fetchApps}
      />
    </>
  );
}

BatchUpdateMessageRecipient.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

BatchUpdateMessageRecipient.defaultProps = {
  fetchApps: true,
};

export default enhance(BatchUpdateMessageRecipient);

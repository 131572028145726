import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import _get from 'lodash/get';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

export default function QuestionVideoPromptAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'video';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const websiteCheck = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    video_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    video_instructions: yup.string(),
    video_admin_only: yup.boolean(),
    video_url: yup
      .string()
      .required('Video URL is required')
      .matches(websiteCheck, 'Video URL must be a valid URL'),
  });

  const { control, handleSubmit, formState } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      video_label: edit ? question.label : '',
      video_instructions: edit ? question.instructions || '' : '',
      video_admin_only: false,
      video_url: edit ? question.url : '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const values = {
        admin_only: false,
        instructions: data.video_instructions,
        label: data.video_label,
        url: data.video_url,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id={'videoAdd'} onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name={'video_label'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'Video Label'}
                errorMsg={_get(formState.errors.video_label, 'message') || ''}
                id={'video_label'}
                label={'Video Label'}
                openSans
                placeholder={'Enter Label'}
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name={'video_instructions'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Instructions"
                  errorMsg={_get(formState.errors.video_instructions, 'message') || ''}
                  placeholder={`Enter Instructions`}
                  label={`Instructions`}
                  id={'video_instructions'}
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name={'video_url'}
          render={({ field: { register, ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                id={'video_url'}
                label={'Video URL'}
                errorMsg={_get(formState.errors.video_url, 'message') || ''}
                required
                placeholder={'Enter YouTube or Vimeo URL'}
                ref={register}
                {...rest}
              />
            </InputContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Video Prompt' : 'Add Video Prompt to Form'}
            form="videoAdd"
            type={'submit'}
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}

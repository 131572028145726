import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sModal from 'react-responsive-modal/styles.css';
import { compose } from 'redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap';
import s from '../Header.css';
import Hubspot from '../../Hubspot';
import Ability from '../../../hoc/Ability/Ability';
import SelectAccessModal from '../select-access-modal';
import TravelFeedAlertSidebar from '../../TravelFeedAlertSidebar/TravelFeedAlertSidebar';
import { getRiskAlerts } from '../../../actions/riskAlertActions';
import { isAllAlertsRead } from '../../../helpers/RiskAlertHelper';
import NotificationMenu from '../../NotificationMenu';
import { updateLastVisitedProduct } from '../../../actions/authActions';
import { Cookies } from 'react-cookie';
// import useSession from '../../../hooks/useSession';
// import SessionExpireModal from '../../SessionExpireModal';

function AdminHeader(props) {
  const dispatch = useDispatch();
  const cookies = new Cookies(); 
  const { riskAlerts } = useSelector(state => state.riskAlert);
  const { clientFeatureList } = useSelector(state => state.profile);
  const [isSendAlertIcon, setIsSendAlertIcon] = useState(false);
  const [showSelectAccess, setShowSelectAccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // const { sessionExpire, setSessionExpire } = useSession({
  //   show: false,
  //   countDownSeconds: 60
  // });

  // const { showSessionExpire, handleCloseSessionModal, countDownSeconds } = useSession();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    dispatch(getRiskAlerts());
  }, []);
  return (
    <nav>
      {props.currentViewAdmin && <Hubspot />}
      <Navbar className={s.header} expand="lg">
        <img
          src={props.theme_logo && props.theme_logo !== '/default.png' ? props.theme_logo : props.logo}
          alt="School Logo"
          className={s.logo}
        />
        <NavbarToggler
          data-toggle="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={props.toggleMenu}
          className="navbar-light bg-light"
        />
        <Collapse isOpen={props.isOpen} navbar className="main-nav-wrapper">
          <Nav navbar className={`${s.navsLinks}`}>
            <NavItem>
              <NavLink className={s.customDropdown} href="/client/dashboard" style={{ color: '#fff' }}>
                Home
              </NavLink>
            </NavItem>
            <Ability module="traveler_information_settings" action="view_traveler">
              {!props.freemium && !props.lead && props.clientFeatureList.legacy && (
                <NavItem>
                  <NavLink className={s.customDropdown} href="/client/traveler-dashboard" style={{ color: '#fff' }}>
                    {props.alias_travelers}
                  </NavLink>
                </NavItem>
              )}
            </Ability>

            {props.clientFeatureList.legacy && (
              <Ability module="events_settings" action="view_events">
                {!props.freemium && !props.lead && (
                  <NavItem>
                    <NavLink className={s.customDropdown} href="/client/event-dashboard" style={{ color: '#fff' }}>
                      Events
                    </NavLink>
                  </NavItem>
                )}
              </Ability>
            )}
            {(props.freemium || props.lead) && (
              <NavItem>
                <NavLink className={s.customDropdown} href="/client/engagement_analytics" style={{ color: '#fff' }}>
                  Analytics
                </NavLink>
              </NavItem>
            )}
            {props.clientFeatureList.legacy && (
              <Ability module="program_settings" action="view_as_admin">
                <NavItem>
                  <NavLink
                    className={s.customDropdown}
                    href="/client/program-dashboard/internal"
                    style={{ color: '#fff' }}
                  >
                    {props.alias_programs}
                  </NavLink>
                </NavItem>
              </Ability>
            )}

            {props.clientFeatureList.legacy && !props.freemium && !props.lead && (
              <NavItem>
                <NavLink className={s.customDropdown} href="/client/application-dashboard" style={{ color: '#fff' }}>
                  Applications
                </NavLink>
              </NavItem>
            )}
            {props.clientFeatureList.legacy && !props.freemium && !props.lead && (
              <NavItem>
                <NavLink className={s.customDropdown} href="/client/traveler-forms-dashboard" style={{ color: '#fff' }}>
                  Forms
                </NavLink>
              </NavItem>
            )}
          </Nav>
          {props.clientFeatureList.travel_plans && !props.lead && (
            <Nav navbar className={`${s.navItemLink}`}>
              <NavItem>
                <NavLink href="/plans/plan-list" style={{ color: '#fff' }}>
                  Via Travel
                </NavLink>
              </NavItem>
            </Nav>
          )}
          {props.clientFeatureList.form_builder &&
            !props.clientFeatureList.mailer_automations &&
            props.clientFeatureList.legacy &&
            !props.freemium &&
            !props.lead && (
              <Nav navbar className={`${s.navItemLink}`}>
                <NavItem>
                  <NavLink href="/client/forms-dashboard" style={{ color: '#fff' }} tabIndex="0">
                    Builder
                  </NavLink>
                </NavItem>
              </Nav>
            )}
          {props.clientFeatureList.legacy &&
            !props.freemium &&
            !props.lead &&
            props.clientFeatureList.form_builder &&
            props.clientFeatureList.mailer_automations && (
              <Nav navbar className={`${s.navItemLink}`}>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  data-value="menu"
                  className={`${s.dropdownZindex}`}
                  tabIndex="0"
                >
                  <DropdownToggle className={s.dropdownDiv}>
                    <div>Builder</div>
                  </DropdownToggle>
                  <DropdownMenu className={s.dropDownSubmenu} tabIndex={0}>
                    {props.clientFeatureList.form_builder && (
                      <a
                        tabIndex={0}
                        className={s.builderMenuItem}
                        href="/client/forms-dashboard"
                        onKeyDown={e => {
                          if (e.keyCode === 32 || e.keyCode === 13) {
                            window.location.href = '/client/forms-dashboard';
                          }
                        }}
                      >
                        Forms
                      </a>
                    )}
                    {props.clientFeatureList.mailer_automations && (
                      <a
                        tabIndex={0}
                        className={s.builderMenuItem}
                        href="/client/automation-dashboard"
                        onKeyDown={e => {
                          if (e.keyCode === 32 || e.keyCode === 13) {
                            window.location.href = '/client/automation-dashboard';
                          }
                        }}
                      >
                        Automations
                      </a>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            )}
          {props.clientFeatureList.reports && !props.lead && (
            <Nav navbar className={`${s.navItemLink}`}>
              <NavItem>
                <NavLink href="/reports/applications" style={{ color: '#fff' }}>
                  Reports
                </NavLink>
              </NavItem>
            </Nav>
          )}

          {(() => {
            if (clientFeatureList?.inbound) {
              // Get current URL from window object
              const currentUrl = window.location.href;

              // Regular expression to extract subdomain and domain
              const subdomainRegex = /\/\/([^/]+)\//;

              // Extract the subdomain and domain
              const match = currentUrl.match(subdomainRegex);
              const subdomain = match ? match[1] : null;

              // Split the domain into second-level domain and top-level domain
              const domainParts = subdomain ? subdomain.split('.') : [];
              const secondLevelDomain = domainParts.length >= 2 ? domainParts[0] : null;
              const topLevelDomain = domainParts.length >= 2 ? domainParts.slice(1).join('.') : null;

              return (
                <Nav navbar className={`${s.navItemLink}`}>
                  <NavItem>
                    <NavLink
                      href={`https://${secondLevelDomain}.inbound.${topLevelDomain}`}
                      target="_blank"
                      style={{ color: '#fff' }}
                      onClick={() => {
                        dispatch(updateLastVisitedProduct(cookies.get("user_id"), "via_international"))
                      }}
                    >
                      Inbound
                    </NavLink>
                  </NavItem>
                </Nav>
              );
            }

            return null;
          })()}

          {/* {clientFeatureList?.inbound && (
            <Nav navbar className={`${s.navItemLink}`}>
              <NavItem>
                <NavLink href="http://via-qa.inbound.via-trm-staging.com" target="_blank" style={{ color: '#fff' }}>
                  Inbound
                </NavLink>
              </NavItem>
            </Nav>
          )} */}

          <Nav
            navbar
            className="navs-links ml-auto"
            style={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            {/* <div tabIndex="-1" className="dropdown-divider" /> */}
            <NavItem className={s.profileMainMenu}>
              <section className={`${s.userInfo}`}>
                <section className={`${s.dropdownSection} ${s.profileMenu}`} tabIndex="0">
                  <div className={s.userAlert}>
                    {props.clientFeatureList.risk_alerts === true &&
                    props.clientPermissions.risk_alerts_settings.includes('view') ? (
                      <div className={s.userNotice}>
                        <button
                          // href="#"
                          style={{
                            border: 'none',
                            background: 'none',
                          }}
                          aria-label="Alert"
                          type="button"
                          // role="button"
                          onClick={e => {
                            e.preventDefault();
                            setShowAlert(true);
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM10 15.2458C9.81458 15.2458 9.63332 15.1908 9.47915 15.0878C9.32498 14.9848 9.20482 14.8384 9.13386 14.667C9.06291 14.4957 9.04434 14.3072 9.08051 14.1254C9.11669 13.9435 9.20598 13.7765 9.33709 13.6454C9.4682 13.5143 9.63525 13.425 9.8171 13.3888C9.99896 13.3526 10.1875 13.3712 10.3588 13.4421C10.5301 13.5131 10.6765 13.6333 10.7795 13.7874C10.8825 13.9416 10.9375 14.1229 10.9375 14.3083C10.9375 14.5569 10.8387 14.7954 10.6629 14.9712C10.4871 15.147 10.2486 15.2458 10 15.2458ZM11.0181 5.81687L10.7491 11.5356C10.7491 11.7345 10.67 11.9253 10.5294 12.066C10.3887 12.2066 10.198 12.2856 9.99906 12.2856C9.80015 12.2856 9.60938 12.2066 9.46873 12.066C9.32808 11.9253 9.24906 11.7345 9.24906 11.5356L8.98 5.81969V5.81734C8.9741 5.67997 8.99607 5.54282 9.04458 5.41415C9.09308 5.28549 9.16713 5.16797 9.26225 5.06868C9.35737 4.96938 9.47161 4.89037 9.59807 4.83639C9.72454 4.78241 9.86062 4.75458 9.99813 4.75458C10.1356 4.75458 10.2717 4.78241 10.3982 4.83639C10.5246 4.89037 10.6389 4.96938 10.734 5.06868C10.8291 5.16797 10.9032 5.28549 10.9517 5.41415C11.0002 5.54282 11.0221 5.67997 11.0162 5.81734L11.0181 5.81687Z"
                              fill={isAllAlertsRead(riskAlerts) ? '#666666' : '#A82425'}
                            />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <div
                        className={`${s.userNotice} ${s.userNoticeDisabled}`}
                        onClick={() => setIsSendAlertIcon(!isSendAlertIcon)}
                        onMouseLeave={() => setIsSendAlertIcon(false)}
                      >
                        {isSendAlertIcon && <span>You do not have permission to access</span>}
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM10 15.2458C9.81458 15.2458 9.63332 15.1908 9.47915 15.0878C9.32498 14.9848 9.20482 14.8384 9.13386 14.667C9.06291 14.4957 9.04434 14.3072 9.08051 14.1254C9.11669 13.9435 9.20598 13.7765 9.33709 13.6454C9.4682 13.5143 9.63525 13.425 9.8171 13.3888C9.99896 13.3526 10.1875 13.3712 10.3588 13.4421C10.5301 13.5131 10.6765 13.6333 10.7795 13.7874C10.8825 13.9416 10.9375 14.1229 10.9375 14.3083C10.9375 14.5569 10.8387 14.7954 10.6629 14.9712C10.4871 15.147 10.2486 15.2458 10 15.2458ZM11.0181 5.81687L10.7491 11.5356C10.7491 11.7345 10.67 11.9253 10.5294 12.066C10.3887 12.2066 10.198 12.2856 9.99906 12.2856C9.80015 12.2856 9.60938 12.2066 9.46873 12.066C9.32808 11.9253 9.24906 11.7345 9.24906 11.5356L8.98 5.81969V5.81734C8.9741 5.67997 8.99607 5.54282 9.04458 5.41415C9.09308 5.28549 9.16713 5.16797 9.26225 5.06868C9.35737 4.96938 9.47161 4.89037 9.59807 4.83639C9.72454 4.78241 9.86062 4.75458 9.99813 4.75458C10.1356 4.75458 10.2717 4.78241 10.3982 4.83639C10.5246 4.89037 10.6389 4.96938 10.734 5.06868C10.8291 5.16797 10.9032 5.28549 10.9517 5.41415C11.0002 5.54282 11.0221 5.67997 11.0162 5.81734L11.0181 5.81687Z"
                            fill={'#666666'}
                          />
                        </svg>
                      </div>
                    )}

                    <div className={s.userEmail}>
                      <Dropdown isOpen={props.messageDropdownOpen} toggle={props.toggleMessage}>
                        <DropdownToggle aria-label="Dropdown Open">
                          <svg
                            width="21"
                            height="17"
                            viewBox="0 0 21 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              bottom: '0px',
                              position: 'absolute',
                            }}
                          >
                            <path
                              d="M17.799 5.05131L17.799 5.05133L17.8017 5.04916C18.0023 4.88858 18.1317 4.65548 18.1618 4.40027L17.9135 4.37097L18.1618 4.40027C18.1919 4.14506 18.1204 3.88825 17.9627 3.68537C17.8049 3.4825 17.5737 3.34986 17.3189 3.31615C17.0641 3.28244 16.8063 3.35036 16.6013 3.50523L16.6012 3.50521L16.5984 3.5074L10.6472 8.13602L4.69731 3.5084C4.59648 3.42801 4.48074 3.36835 4.35674 3.33287L4.28796 3.57322L4.35674 3.33287C4.23216 3.29722 4.10174 3.28668 3.97305 3.30187C3.84436 3.31705 3.71997 3.35767 3.60711 3.42135C3.49425 3.48502 3.39517 3.57049 3.31563 3.6728C3.23609 3.77509 3.17768 3.89218 3.14378 4.01725C3.10989 4.14232 3.10118 4.27289 3.11818 4.40135C3.13518 4.52981 3.17754 4.65362 3.2428 4.76557C3.30774 4.87697 3.39406 4.97442 3.49679 5.05232L10.047 10.1469L10.047 10.1469C10.2186 10.2804 10.4298 10.3528 10.6472 10.3528C10.8646 10.3528 11.0758 10.2804 11.2474 10.1469L11.2475 10.1469L17.799 5.05131ZM1.37986 1.56345C1.81066 1.13266 2.39475 0.890336 3.00398 0.889648H18.2905C18.8997 0.890336 19.4838 1.13266 19.9146 1.56345C20.3454 1.99428 20.5878 2.57843 20.5884 3.18771V14.1063C20.5878 14.7156 20.3454 15.2997 19.9146 15.7305C19.4838 16.1614 18.8996 16.4037 18.2903 16.4044H3.00412C2.39483 16.4037 1.81069 16.1614 1.37986 15.7305C0.949064 15.2998 0.706742 14.7157 0.706055 14.1064V3.18757C0.706742 2.57834 0.949064 1.99425 1.37986 1.56345Z"
                              fill="#666666"
                              stroke="#666666"
                              strokeWidth="0.5"
                            />
                          </svg>
                        </DropdownToggle>
                        <DropdownMenu className={s.messageMenu} right>
                          <DropdownItem>
                            {props.newMessagesLoading ? (
                              <span>Loading...</span>
                            ) : props.messages.length > 0 ? (
                              props.messages
                            ) : (
                              <span>Looks like you're all caught up! There are no new messages.</span>
                            )}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                    <NotificationMenu />

                    {/* Dropdown */}
                    <section className={`${s.dropdownSection} ${s.profileMenu}`} tabIndex="0">
                      <Dropdown isOpen={props.dropdownOpen} toggle={props.toggle} data-value="menu">
                        <DropdownToggle
                          aria-label="Dropdown Open"
                          style={{
                            position: 'relative',
                            right: '-30px',
                            top: '7px',
                          }}
                        >
                          <div>
                            <img
                              style={{
                                bottom: '-7px',
                                right: '-7px',
                              }}
                              alt="Profile Image"
                              className={s.userProfileImg}
                              src={`${props.avatarUrl}?cb=${new Date().getTime().toString()}`}
                            />
                          </div>
                        </DropdownToggle>
                        <DropdownMenu right className={s.dropdownMenu}>
                          <a
                            tabIndex={0}
                            className={s.menuItem}
                            href="/client/org-settings"
                            onKeyDown={e => {
                              if (e.keyCode === 32 || e.keyCode === 13) {
                                window.location.href = '/client/org-settings';
                              }
                            }}
                          >
                            <img src="/img/header/settings.svg" width="17px" alt="Settings" className={s.iconImg17} />
                            <span className={s.menuItemValue}>Settings</span>
                          </a>

                          {props.admin_access && (
                            <div
                              tabIndex={0}
                              className={s.menuItem}
                              onClick={() => {
                                setShowSelectAccess(true);
                              }}
                              onKeyDown={e => {
                                if (e.keyCode === 32 || e.keyCode === 13) {
                                  setShowSelectAccess(true);
                                }
                              }}
                            >
                              <img src="/img/header/swap.svg" width="17px" alt="Change Role" className={s.iconImg17} />
                              <span className={s.menuItemValue}>Change Role</span>
                            </div>
                          )}
                          <div
                            tabIndex={0}
                            className={s.menuItem}
                            onClick={() => {
                              window.open(
                                'https://login.circle.so/sign_up?request_host=viasparkscommunity.circle.so&user%5Binvitation_token%5D=ec9ff016e9d24aae745e047d12b1788edd3d4318-bbbf9387-7b99-4443-8ede-aa10d0e76455#email',
                                '_blank',
                              );
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 32 || e.keyCode === 13) {
                                window.open(
                                  'https://login.circle.so/sign_up?request_host=viasparkscommunity.circle.so&user%5Binvitation_token%5D=ec9ff016e9d24aae745e047d12b1788edd3d4318-bbbf9387-7b99-4443-8ede-aa10d0e76455#email',
                                  '_blank',
                                );
                              }
                            }}
                          >
                            <img
                              src="/img/header/people-circle.svg"
                              width="20px"
                              alt="ViaSparks"
                              className={s.iconImg20}
                            />
                            <span className={s.menuItemValue}>ViaSparks</span>
                            <img
                              src="/img/header/external-link-outline.svg"
                              width="17px"
                              alt="Open external link"
                              className={s.iconImg17}
                            />
                          </div>
                          <div
                            tabIndex={0}
                            className={s.menuItem}
                            onClick={() => {
                              window.open('https://help.viatrm.com', '_blank');
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 32 || e.keyCode === 13) {
                                window.open('https://help.viatrm.com', '_blank');
                              }
                            }}
                          >
                            <img
                              src="/img/header/question-mark-circle.svg"
                              width="17px"
                              alt="Knowledge Base"
                              className={s.iconImg17}
                            />
                            <span className={s.menuItemValue}>Knowledge Base</span>
                            <img
                              src="/img/header/external-link-outline.svg"
                              width="17px"
                              alt="Open external link"
                              className={s.iconImg17}
                            />
                          </div>
                          <div
                            tabIndex={0}
                            className={s.menuItem}
                            onClick={() => {
                              props.signOut();
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 32 || e.keyCode === 13) {
                                props.signOut();
                              }
                            }}
                          >
                            <img src="/img/header/sign-out.svg" width="20px" alt="Sign Out" className={s.iconImg20} />
                            <span className={s.menuItemValue}>Sign Out</span>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </section>
                    {/* End dropdown */}
                  </div>

                  {/* <TravelFeedAlertIcon openSidebar={() => setShowAlert(true)}/> */}

                  {/* <button onClick={() =>  console.log("opening sidebar")}>Btn</button> */}
                </section>
              </section>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <SelectAccessModal
        show={showSelectAccess}
        onClose={() => setShowSelectAccess(false)}
        freemium={props.freemium}
        admin_access={props.admin_access}
        admin_sign_in={props.admin_sign_in}
        clientFeatureList={props.clientFeatureList}
      />

      <TravelFeedAlertSidebar show={showAlert} handleClose={() => setShowAlert(false)} />

      {/*{showSessionExpire && <SessionExpireModal show={showSessionExpire} handleClose={handleCloseSessionModal} countDownSeconds={countDownSeconds} />}*/}
    </nav>
  );
}

AdminHeader.propTypes = {
  addDefaultSrc: PropTypes.func,
  alias_programs: PropTypes.string,
  alias_travelers: PropTypes.string,
  appDropdownOpen: PropTypes.bool,
  avatarUrl: PropTypes.string,
  currentViewAdmin: PropTypes.bool,
  customBranding: PropTypes.object,
  dropdownOpen: PropTypes.bool,
  formDropdownOpen: PropTypes.bool,
  freemium: PropTypes.bool,
  isOpen: PropTypes.bool,
  lead: PropTypes.bool,
  logo: PropTypes.string,
  messages: PropTypes.any,
  messageDropdownOpen: PropTypes.bool,
  newMessagesLoading: PropTypes.bool,
  onError: PropTypes.func,
  profileInfo: PropTypes.object,
  signOut: PropTypes.func,
  travelerMessage: PropTypes.func,
  travel_plans_url: PropTypes.string,
  theme_logo: PropTypes.string,
  toggle: PropTypes.func,
  toggleApplication: PropTypes.func,
  toggleForm: PropTypes.func,
  toggleMenu: PropTypes.func,
  toggleMessage: PropTypes.func,
  clientFeatureList: PropTypes.object,
};

export default compose(withStyles(s, sModal))(AdminHeader);

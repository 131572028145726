import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputSelect from '../../../library/inputSelect';
import InputText from '../../../library/inputText';
import FilterCheckBox from '../filterCheckBox';
import sFilterNumericCheckboxRange from './filterNumericCheckboxRange.scss';
import _isEqual from 'lodash/isEqual';

export const enhance = compose(withStyles(sFilterNumericCheckboxRange));

function FilterNumericCheckboxRange(props) {
  const [loading, setLoading] = useState(true);
  const [rangeType, setRangeType] = useState(props.selectedValues[0] || 'is');
  const [singleNumericValue, setSingleNumericValue] = useState(props.selectedValues[1]);
  const [fromNumericValue, setFromNumericValue] = useState(props.selectedValues[2]);
  const [toNumericValue, setToNumericValue] = useState(props.selectedValues[3]);
  const [checkOptions, setCheckOptions] = useState(props.selectedValues[4] ? props.selectedValues[4] : []);
  const [defaultIndex, setDefaultIndex] = useState();
  const typeOptions = [
    { value: 'is', label: 'Is' },
    { value: 'is_between', label: 'Is between (inclusive)' },
    { value: 'is_greater', label: 'Is greater than or equal to' },
    { value: 'is_less', label: 'Is less than or equal to' },
  ];
  const checkboxOptions = [
    { label: 'Not Requested  ', value: 'Not Requested' },
    { label: 'Not Provided  ', value: 'Not Provided' },
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.selectedValues[0]) {
      let defaultIndexValue = typeOptions.find(item => item.value === props.selectedValues[0]);
      if (!defaultIndexValue || defaultIndexValue === undefined) {
        defaultIndexValue = typeOptions[0];
      }

      setDefaultIndex(defaultIndexValue);
      setRangeType(props.selectedValues[0]);
      setSingleNumericValue(props.selectedValues[1]);
      setFromNumericValue(props.selectedValues[2]);
      setToNumericValue(props.selectedValues[3]);
      setCheckOptions(props.selectedValues[4] ? props.selectedValues[4] : []);
    } else {
      if (!props.bypassDefaultSetup) {
        let defaultIndexValue = typeOptions.find(item => item.value === rangeType);
        setDefaultIndex(defaultIndexValue);
        setFromNumericValue('');
        setToNumericValue('');
        setSingleNumericValue('');
      }
    }
  }, [props.selectedValues]);

  useEffect(() => {
    let filterValues = [];
    let saveType = rangeType;

    if (typeof saveType === 'object') {
      saveType = typeOptions[0].value;
    }

    if (saveType === 'is_between') {
      filterValues.push(saveType);
      filterValues.push('');
      filterValues.push(fromNumericValue ? fromNumericValue.toString() : '');
      filterValues.push(toNumericValue ? toNumericValue.toString() : '');
      filterValues.push(checkOptions);
    } else {
      filterValues.push(saveType);
      filterValues.push(singleNumericValue ? singleNumericValue.toString() : '');
      filterValues.push('');
      filterValues.push('');
      filterValues.push(checkOptions);
    }

    if (!loading) {
      if (!_isEqual(filterValues, props.selectedValues)) {
        props.change(filterValues);
      }
    }
  }, [rangeType, singleNumericValue, fromNumericValue, toNumericValue, checkOptions]);

  return (
    <div className={sFilterNumericCheckboxRange.numericCheckboxRangeFilter}>
      <InputSelect
        id="rangeType"
        labelText=""
        styles={{
          menu: provided => ({ ...provided, zIndex: 99999 }),
        }}
        options={typeOptions}
        onChange={e => {
          setRangeType(e.value);
        }}
        defaultValue={defaultIndex}
        value={defaultIndex}
      />

      {rangeType && rangeType !== 'is_between' && (
        <div className={sFilterNumericCheckboxRange.singleValue}>
          <div className={sFilterNumericCheckboxRange.singleValue}>
            <InputText
              type="number"
              placeholder=""
              value={singleNumericValue || ''}
              labelText="Number"
              id="singleDuration"
              name="singleDuration"
              size="normal"
              labelSize="normal"
              step={props.step}
              onChange={e => {
                setSingleNumericValue(e.target.value);
              }}
            />
          </div>
        </div>
      )}

      {rangeType && rangeType === 'is_between' && (
        <div className={sFilterNumericCheckboxRange.rangeValues}>
          <div className={sFilterNumericCheckboxRange.rangeValue}>
            <InputText
              type="number"
              placeholder=""
              value={fromNumericValue || ''}
              labelText="Number"
              id="fromDuration"
              name="fromDuration"
              size="normal"
              labelSize="normal"
              step={props.step}
              onChange={e => {
                setFromNumericValue(e.target.value);
              }}
            />
          </div>
          <span className={sFilterNumericCheckboxRange.separator}>and</span>
          <div className={sFilterNumericCheckboxRange.rangeValue}>
            <InputText
              type="number"
              placeholder=""
              value={toNumericValue || ''}
              labelText="Number"
              id="toDuration"
              name="toDuration"
              size="normal"
              labelSize="normal"
              step={props.step}
              onChange={e => {
                setToNumericValue(e.target.value);
              }}
            />
          </div>
        </div>
      )}
      {!props.hidePresetValues && (
        <div className={sFilterNumericCheckboxRange.checkOptions}>
          <FilterCheckBox
            id="checkbox_options"
            sourceData={checkboxOptions}
            selectedValues={checkOptions}
            change={e => setCheckOptions(e)}
          />
        </div>
      )}
    </div>
  );
}

FilterNumericCheckboxRange.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  step: propTypes.string,
  hidePresetValues: propTypes.bool,
  bypassDefaultSetup: propTypes.bool,
};

FilterNumericCheckboxRange.defaultProps = {
  displayText: 'Filter Options',
  selectedValues: [],
  step: '1',
  hidePresetValues: false,
  bypassDefaultSetup: false,
};

export default enhance(FilterNumericCheckboxRange);

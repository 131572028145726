import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { fetchProgramTypes } from '../../../../../actions/lookupActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ProgramTypeFilter(props) {
  const dispatch = useDispatch();
  const programTypes = useSelector(state => state.lookup.allProgramTypes);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!programTypes.programTypes) {
        dispatch(fetchProgramTypes());
      } else {
        let options = [];

        programTypes.programTypes.map(item => {
          if (item.attributes.name.length > 0) {
            options.push(item.attributes.name);
          }
        });

        const optionValues = buildSelectOptions(options, false, true);
        setSourceOptions(optionValues);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [programTypes]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramTypeFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ProgramTypeFilter.defaultProps = {
  displayText: 'Program Type',
  selectedValues: [],
};

export default enhance(ProgramTypeFilter);

export const ProgramTypeFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramTypeFilterMemo({ selectedValues, onFilterChange }) {
    const customAliases = useSelector(state => state.profile.customAliases);

    return (
      <ProgramTypeFilter
        id={'program_type'}
        selectedValues={selectedValues}
        displayText={`${customAliases.alias_program} Type`}
        change={values => {
          onFilterChange('program_type', values);
        }}
      />
    );
  }),
);

export const PreferredProgramTypeFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function PreferredProgramTypeFilterMemo({ selectedValues, onFilterChange }) {
    const customAliases = useSelector(state => state.profile.customAliases);

    return (
      <ProgramTypeFilter
        id={'preferred_program_types'}
        selectedValues={selectedValues}
        displayText={`Preferred ${customAliases.alias_program} Type`}
        change={values => {
          onFilterChange('preferred_program_types', values);
        }}
      />
    );
  }),
);

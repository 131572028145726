import React, { useEffect, useState } from 'react';
import { getDaysArrayByMonth, getYearArrayOptions, monthsArrayOptions } from '../../../../../helpers/CommonHelper';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import styled from 'styled-components';
import SelectInput from './SelectInput';
import Error from './Error';
import ErrorRequired from './ErrorRequired';

const Container = styled.div`
  margin: 1.125rem auto 0 auto; 
`

const Label = styled.div`
  color: #373737;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.018px;
`;

export default function DatePickerDropDownInput({
  label = '',
  dayLabel = 'Day',
  dayPlaceHolder = 'Select Day',
  monthLabel = 'Month',
  monthPlaceHolder = 'Select Month',
  yearLabel = 'Year',
  yearPlaceholder = 'Select Year',
  yearOptionsAgo = 5,
  yearOptionsLater = 10,
  onChange,
  errorMessage = '',
  isRequired = false,
  value = null,
  defaultValue=null
}) {
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [year, setYear] = useState(null);
  const [dayOptions, setDayOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  
  useEffect(() => {
    let yOptions = getYearArrayOptions(yearOptionsAgo, yearOptionsLater);
    setYearOptions(yOptions);
  }, [yearOptionsAgo, yearOptionsLater]);

  useEffect(() => {
    if(defaultValue) {
      const momentDate = moment(defaultValue);
      const selectedMonth = (momentDate.month() + 1).toString().padStart(2, "0");
      let yOptions = getYearArrayOptions(yearOptionsAgo, yearOptionsLater);
      let dOptions = getDaysArrayByMonth(String(selectedMonth).toString());
      
      setDayOptions(dOptions);
      
      setDay(dOptions.find(
        item => item.value === momentDate.date()
      ));
      setYear(yOptions.find(
        item => item.value === momentDate.year()
      ));
      setMonth(monthsArrayOptions.find(
        month => month.value === selectedMonth
      ));   
    }
  }, []);

  useEffect(() => {
    if (day?.value && year?.value && month?.value) {
      convertDropDownValuesToDateObj(year.value, month.value, day.value);
    } else {
      // onChange(null);
    }
  }, [day?.value, year?.value, month?.value]);

  useEffect(() => {
    if (value) {
      if (!day || !month || !year) {
        bindDateObjToDropDownValues(value);
      }
    } else {
      // resetValues();
    }
  }, [value]);

  //reset values
  const resetValues = () => {
    setYear(null);
    setMonth(null);
    setDay(null);
  };

  const bindDateObjToDropDownValues = date => {
    console.log(date,"selected date");
    let yearValue = date.getFullYear();
    let monthValue = date.getMonth() + 1;
    let dayValue = date.getDay();

    let monthOption = monthsArrayOptions.find(i => parseInt(i.value) === monthValue);
    setMonth(monthOption);

    let dayOptions = getDaysArrayByMonth(String(monthOption.value).toString());
    
    setDayOptions(dayOptions);
    let dayOption = dayOptions.find(i => parseInt(i.value) === dayValue);
    setDay(dayOption);

    let yOptions = getYearArrayOptions(yearOptionsAgo, yearOptionsLater);
    let yearOption = yOptions.find(i => parseInt(i.value) === yearValue);
    setYear(yearOption);
  };

  const convertDropDownValuesToDateObj = (yearValue, monthValue, dayValue) => {
    const date = new Date(parseInt(yearValue), parseInt(monthValue) - 1, parseInt(dayValue));
    onChange(date);
  };

  const handleChangeMonth = val => {
    setMonth(val);
    let options = [];
    if (val?.value) {
      options = getDaysArrayByMonth(val.value);
    }
    setDayOptions(options);
  };

  return (
    <Container>
      {label && <Label>{label} {isRequired && <ErrorRequired/>} </Label>}

      <Row>
        <Col>
          <SelectInput
            options={monthsArrayOptions}
            label={monthLabel}
            placeholder={monthPlaceHolder}
            handleOnChange={handleChangeMonth}
            value={month}
            fullWidth={true}
          />
        </Col>

        <Col>
          <SelectInput
            options={dayOptions}
            label={dayLabel}
            placeholder={dayPlaceHolder}
            handleOnChange={val => setDay(val)}
            value={day}
          />
        </Col>

        <Col>
          <SelectInput
            options={yearOptions}
            label={yearLabel}
            placeholder={yearPlaceholder}
            handleOnChange={val => setYear(val)}
            value={year}
          />
        </Col>
      </Row>

      {errorMessage && <Error message={errorMessage} />}
    </Container>
  );
}

exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes xR10S{to{-webkit-transform:rotate(1turn)}}@keyframes xR10S{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._14C71{display:-ms-flexbox;display:flex;width:100%}._14C71 ._39i7O{-ms-flex:1 1;flex:1 1;-ms-flex-pack:center;justify-content:center;overflow:hidden}._14C71 ._39i7O ._2-bmW{height:100%;width:100%}._14C71 ._2VYmU{-ms-flex:1 1;flex:1 1;padding:0 24px}._14C71 ._2VYmU ._1pP-j{padding-top:24px;-ms-flex:1 1;flex:1 1}._14C71 ._2VYmU ._5CywT{display:-ms-flexbox;display:flex;border-bottom:2px solid #ebebeb;-ms-flex-pack:justify;justify-content:space-between}._14C71 ._2VYmU ._5CywT ._3NwHi{padding:24px 24px 24px 0}._14C71 ._2VYmU ._5CywT ._3NwHi p{padding:4px 0}", ""]);

// exports
exports.locals = {
	"ov-header": "_14C71",
	"img-wrapper": "_39i7O",
	"img-fit": "_2-bmW",
	"header-info-wrapper": "_2VYmU",
	"header-info-title": "_1pP-j",
	"header-info-details-wrapper": "_5CywT",
	"header-info-details-block": "_3NwHi",
	"spin": "xR10S"
};
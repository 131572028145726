exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1PAGw{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:left}._2G-GA{padding:45px}._3Atqh{padding:0}", ""]);

// exports
exports.locals = {
	"text": "_1PAGw",
	"container": "_2G-GA",
	"modelBody": "_3Atqh"
};
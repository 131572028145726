import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { fetchLanguages } from '../../../../../actions/lookupActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ProgramLanguageFilter(props) {
  const dispatch = useDispatch();
  const languages = useSelector(state => state.lookup.allLanguages);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!languages.languages) {
        dispatch(fetchLanguages());
      } else {
        let options = [];

        languages.languages.map(item => {
          if (item.attributes.name.length > 0) {
            options.push(item.attributes.name);
          }
        });

        const optionValues = buildSelectOptions(options, false, true);
        setSourceOptions(optionValues);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [languages]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramLanguageFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ProgramLanguageFilter.defaultProps = {
  displayText: 'Language of Instruction',
  selectedValues: [],
};

export default enhance(ProgramLanguageFilter);

export const ProgramLanguageFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramLanguageFilterMemo({ selectedValues, onFilterChange }) {
    return (
      <ProgramLanguageFilter
        id={'preferred_program_languages'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('preferred_program_languages', values);
        }}
        displayText={'Preferred Language'}
      />
    );
  }),
);

import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _concat from 'lodash/concat';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';
import _map from 'lodash/map';

export const enhance = compose(withStyles(sFilterAccordion));

function ApplicationMajorsFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }

    let valueNames = [];

    props.sourceData.forEach(item => {
      if (typeof item === 'string') {
        if (
          !!item &&
          item.length > 0 &&
          item.toLowerCase() !== 'not requested' &&
          item.toLowerCase() !== 'not provided'
        ) {
          valueNames = _concat(valueNames, item);
        }
      } else {
        if (item) {
          item.forEach(i => {
            if (!!i && i.length > 0 && i.toLowerCase() !== 'not requested' && i.toLowerCase() !== 'not provided') {
              valueNames = _concat(valueNames, i);
            }
          });
        }
      }
    });

    const optionValues = buildSelectOptions(valueNames, true, true);
    const predefined = [
      { label: 'Not Requested', value: 'Not Requested' },
      { label: 'Not Provided', value: 'Not Provided' },
    ];

    const groupOptions = [
      { label: '', options: predefined },
      { label: '', options: optionValues },
    ];

    if (!props.hidePresetValues) {
      setSourceOptions(groupOptions);
    } else {
      setSourceOptions(optionValues);
    }
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ApplicationMajorsFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
  hidePresetValues: propTypes.bool,
};

ApplicationMajorsFilter.defaultProps = {
  displayText: 'Major/Minor',
  selectedValues: [],
  hidePresetValues: false,
};

export default enhance(ApplicationMajorsFilter);

export const ApplicationMajorsFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationMajorsFilterMemo({
    selectedValues,
    onFilterChange,
    sourceData = [],
    hidePresetValues = false,
  }) {
    return (
      <ApplicationMajorsFilter
        id={'majors'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('majors', values);
        }}
        sourceData={sourceData}
        hidePresetValues={hidePresetValues}
      />
    );
  }),
);

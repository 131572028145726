import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { fetchApplicationStatusOptions } from '../../../../../actions/lookupActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';

import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ApplicationStatusFilter(props) {
  const dispatch = useDispatch();
  const appStatusOptions = useSelector(state => state.lookup.allApplicationStatusOptions);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!appStatusOptions.applicationStatusOptions) {
        dispatch(fetchApplicationStatusOptions());
      } else {
        let options = [];

        appStatusOptions.applicationStatusOptions.map(item => {
          options.push(item);
        });

        const optionValues = buildSelectOptions(options, false, false);
        setSourceOptions(optionValues);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [appStatusOptions]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ApplicationStatusFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ApplicationStatusFilter.defaultProps = {
  displayText: 'Application Status',
  selectedValues: [],
};

export default enhance(ApplicationStatusFilter);

export const ApplicationStatusFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationStatusFilterMemo({
    selectedValues = null,
    onFilterChange,
  }) {
    return (
      <ApplicationStatusFilter
        id={'application_status'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('application_status', values);
        }}
      />
    );
  }),
);

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Truncate from 'react-truncate';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaDate from '../../../components/library/viaDate';
import ViaIcon from '../../components/ViaIcon';
import Headline from '../../../components/library/headline';
import { defaultPlanLocationImage } from '../../../../shared/helpers/plans';
import sPlanCardSmall from './planCardSmall.scss';
import Paragraph from '../../../components/library/paragraph';
import admin from '../../../../shared/images/person.png';

import grouplocation from '../../../../shared/images/group-location.png';
import groupdate from '../../../../shared/images/group-date.png';
import groupinfo from '../../../../shared/images/group-info.png';

export const enhance = compose(
  withStyles(sPlanCardSmall),
  withRouter,
);

function PlanCardSmall(props) {
  //const planAttr = props.plan.attributes;
  const planAttr = props.plan;
  const [allLocs, setAllLocs] = useState('');
  const [img, setImg] = useState({ src: defaultPlanLocationImage(), alt: 'travel location' });

  useEffect(() => {
    let locsString = '';
    if (planAttr.locations && planAttr.locations.length > 0) {
      planAttr.locations.map(loc => {
        locsString = locsString + loc.locality + ', ' + loc.country_common_name + '; ';
      });
      locsString = locsString.slice(0, -2);

      setImg({
        src: planAttr.locations[0].image_url,
        alt: `Location image for ${planAttr.locations[0].locality}, ${planAttr.locations[0].country_common_name}`,
      });
    }
    setAllLocs(locsString);
  }, []);

  return (
    <div
      className={sPlanCardSmall['plan-card']}
      tabIndex={0}
      onClick={() => {
        props.history.push(`/plans/plan-overview/${props.plan.id}`, {
          backTo: props.groupSearch ? 'group-travel-search' : 'my-travel',
        });
      }}
      onKeyDown={e => {
        if (e.keyCode === 32 || e.keyCode === 13) {
          props.history.push(`/plans/plan-overview/${props.plan.id}`, {
            backTo: props.groupSearch ? 'group-travel-search' : 'my-travel',
          });
        }
      }}
    >
      <div>
        <div className={sPlanCardSmall['row-item-img']}>
          <img alt={`${img.alt}`} longdesc={`${img.alt}`} src={img.src} />
        </div>
        <div className={sPlanCardSmall.content}>
          <div className={sPlanCardSmall['row-item-wrapper']}>
            <div className={sPlanCardSmall['row-item-title']}>
              <div className={sPlanCardSmall['title']}>
                {planAttr.name.substring(0, 60)}
              </div>
              <div className={sPlanCardSmall.right}>
                {planAttr.group ? (
                  <img src={admin} alt="Group plan" longdesc="Group plan" name="group" size="smallPlusW10" />
                ) : (
                  <ViaIcon name="solo" color="darkText" size="small" ariaLabel={sPlanCardSmall['row-item-title']} />
                )}
              </div>
            </div>

            <div className={sPlanCardSmall['card-content-details']}>
              {/*<div*/}
              {/*  className={sPlanCardSmall['row-item-content']}*/}
              {/*  dangerouslySetInnerHTML={{ __html: planAttr.description }}*/}
              {/*></div>*/}
              <div className={sPlanCardSmall['row-item-content']}>
                <div className={sPlanCardSmall['row-item-content-img']}>
                  <img src={groupdate} alt="Plan Date" longdesc="Plan Date" />
                </div>
                <ViaDate date={planAttr.start_date} /> - <ViaDate date={planAttr.end_date} />
              </div>
              <div className={sPlanCardSmall['row-item-content']}>
                <div className={sPlanCardSmall['row-item-content-img']}>
                  <img src={groupinfo} alt="Information" longdesc="Information" />
                </div>
                <div>{planAttr.plan_type_name}</div>
              </div>
              <div className={sPlanCardSmall['row-item-content']}>
                <div className={sPlanCardSmall['row-item-content-img']}>
                  <img src={grouplocation} alt="Location" longdesc="Location" />
                </div>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  <Paragraph>{allLocs}</Paragraph>
                </Truncate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PlanCardSmall.propTypes = {
  plan: propTypes.object.isRequired,
  groupSearch: propTypes.bool,
};

PlanCardSmall.defaultProps = {
  groupSearch: false,
};

export default enhance(PlanCardSmall);

/* <ViaIcon name="group" color="darkText" size="smallPlusW10" /> */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sSwitchDashboardBanner from './switchDashboardBanner.scss';
import propTypes from 'prop-types';

function EventSwitchDashboardBanner({ linkPath, dashboardType }) {
  const clientFeatureList = useSelector(state => state.profile.clientFeatureList);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (dashboardType.toLowerCase() === 'event' && clientFeatureList?.traveler_event_beta_dashboards) {
      setShowBanner(true);
    }
  }, [clientFeatureList]);

  return showBanner ? (
    <div className={sSwitchDashboardBanner['switch-banner']}>
      <div className={sSwitchDashboardBanner['switch-left-div']} />
      🚀 New Via Events Dashboards are now in beta!
      <a href={linkPath} className={sSwitchDashboardBanner['switch-link']}>
        Switch now to try out the new dashboard features.
      </a>
    </div>
  ) : null;
}

EventSwitchDashboardBanner.propTypes = {
  linkPath: propTypes.string.isRequired,
  dashboardType: propTypes.string.isRequired,
};

export default withStyles(sSwitchDashboardBanner)(EventSwitchDashboardBanner);

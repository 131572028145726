import fetch from 'node-fetch';
import { keys } from '../../../../config/keys';
import { Cookies } from 'react-cookie';

async function fetchCountryList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/via_countries`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchProgramTypeList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/program_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchAllProgramNameList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/programs/thin?unauthorize_programs=true`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.programs?.map(c => c?.title?.trim()).sort() || [];
      return dataList;
    });
}

async function fetchInternalProgramNameList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/programs/thin?source=internal`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.programs?.map(c => c?.title?.trim()).sort() || [];
      return dataList;
    });
}

async function fetchAuthorizedProgramNameList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/programs/thin?source=authorized`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.programs?.map(c => c?.title?.trim()).sort() || [];
      return dataList;
    });
}

async function fetchUnauthorizedProgramNameList(orgId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/programs/thin?source=unauthorized&org_id=${orgId}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.programs?.map(c => c?.title?.trim()).sort() || [];
      return dataList;
    });
}

async function fetchApplicationStatusList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/application_status_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchApplicationTemplateList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/form_templates/thin?template_type=Application`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name).sort() || [];
      return dataList;
    });
}

async function fetchTrackingStepList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/tracking_step_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchTermTagsList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/tags`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.map(c => c?.name).sort() || [];
      return dataList;
    });
}

async function fetchTermNameList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/term_names`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name).sort() || [];
      return dataList;
    });
}

async function fetchPlanTypeList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/plan_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name).sort() || [];
      return dataList;
    });
}

async function fetchGenderIdentityList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/gender_identities`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchTransportationTypeList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/tp_transportation_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchHousingTypeList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/tp_housing_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchActivityTypeList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/tp_activity_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchAdminList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/admins`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = [];
      let active = [];
      let archived = [];
      data?.data?.map(admin => {
        if (admin?.attributes?.archived) {
          archived.push(admin?.attributes?.full_name_or_email);
        } else {
          active.push(admin?.attributes?.full_name_or_email);
        }
      });
      dataList = active.concat(archived);
      return dataList;
    });
}

async function fetchYearInSchoolList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/year_in_school_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchTravelerPayForProgramList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/program_payment_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchSubjectAreaList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/subject_areas`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchLanguageList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/languages`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchPreferredHousingTypeList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/housing_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchTravelerCertaintyList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/travel_certainty_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchTravelerGoalList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/travel_goal_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchTravelerBarrierList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/participation_barrier_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchApplicationTagList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/taggable/tags?tag_type=Application`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name?.trim()) || [];
      return dataList;
    });
}


async function fetchPlanFormTagList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/taggable/tags?tag_type=Plan_Form`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name?.trim()) || [];
      return dataList;
    });
}

async function fetchFormTagList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/taggable/tags?tag_type=Form`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name?.trim()) || [];
      return dataList;
    });
}

async function fetchProgramTermTagList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/tags`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.map(c => c?.name?.trim()) || [];
      return dataList;
    });
}

async function fetchProgramTermTagListAll() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/client/tags?all=true`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.map(c => c?.name?.trim()) || [];
      return dataList;
    });
}

async function fetchTravelerTypeList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/traveler_type_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data || [];
      return dataList;
    });
}

async function fetchResidencyStatusList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/residency_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data || [];
      return dataList;
    });
}

async function fetchRaceList() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/race_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data || [];
      return dataList;
    });
}

async function fetchCustomFields() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return await fetch(`${keys.baseUri}/api/custom_fields?active_custom_fields=true`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data?.active_custom_fields || [];
    });
}

export async function getCustomFields() {
  const options = await fetchCustomFields();
  return options;
}

export async function getFilterRaceList() {
  const filterOptions = await fetchRaceList();
  return filterOptions;
}

export async function getFilterResidencyStatusList() {
  const filterOptions = await fetchResidencyStatusList();
  return filterOptions;
}

export async function getFilterTravelerTypeList() {
  const filterOptions = await fetchTravelerTypeList();
  return filterOptions;
}

export async function getFilterAllProgramNameList() {
  const filterOptions = await fetchAllProgramNameList();
  return filterOptions;
}

export async function getFilterInternalProgramNameList() {
  const filterOptions = await fetchInternalProgramNameList();
  return filterOptions;
}

export async function getFilterAuthorizedProgramNameList() {
  const filterOptions = await fetchAuthorizedProgramNameList();
  return filterOptions;
}

export async function getFilterUnauthorizedProgramNameList(orgId) {
  const filterOptions = await fetchUnauthorizedProgramNameList(orgId);
  return filterOptions;
}

export async function getFilterProgramTermTagList() {
  const filterOptions = await fetchProgramTermTagList();
  return filterOptions;
}

export async function getFilterProgramTermTagListAll() {
  const filterOptions = await fetchProgramTermTagListAll();
  return filterOptions;
}

export async function getFilterApplicationTagList() {
  const filterOptions = await fetchApplicationTagList();
  return filterOptions;
}

export async function getFilterPlanFormTagList() {
  const filterOptions = await fetchPlanFormTagList();
  return filterOptions;
}


export async function getFilterFormTagList() {
  const filterOptions = await fetchFormTagList();
  return filterOptions;
}

export async function getFilterTravelerBarrierList() {
  const filterOptions = await fetchTravelerBarrierList();
  return filterOptions;
}

export async function getFilterTravelerGoalList() {
  const filterOptions = await fetchTravelerGoalList();
  return filterOptions;
}

export async function getFilterTravelerCertaintyList() {
  const filterOptions = await fetchTravelerCertaintyList();
  return filterOptions;
}

export async function getFilterPreferredHousingTypeList() {
  const filterOptions = await fetchPreferredHousingTypeList();
  return filterOptions;
}

export async function getFilterLanguageList() {
  const filterOptions = await fetchLanguageList();
  return filterOptions;
}

export async function getFilterSubjectAreaList() {
  const filterOptions = await fetchSubjectAreaList();
  return filterOptions;
}

export async function getFilterTravelerPayForProgramList() {
  const filterOptions = await fetchTravelerPayForProgramList();
  return filterOptions;
}

export async function getFilterYearInSchoolList() {
  const filterOptions = await fetchYearInSchoolList();
  return filterOptions;
}

export async function getFilterAdmins() {
  const filterOptions = await fetchAdminList();
  return filterOptions;
}

export async function getFilterActivityTypes() {
  const filterOptions = await fetchActivityTypeList();
  return filterOptions;
}

export async function getFilterHousingTypes() {
  const filterOptions = await fetchHousingTypeList();
  return filterOptions;
}

export async function getFilterTransportationTypes() {
  const filterOptions = await fetchTransportationTypeList();
  return filterOptions;
}

export async function getFilterGenderIdentities() {
  const filterOptions = await fetchGenderIdentityList();
  return filterOptions;
}

export async function getFilterPlanTypes() {
  const filterOptions = await fetchPlanTypeList();
  return filterOptions;
}

export async function getFilterCountries() {
  const filterOptions = await fetchCountryList();
  return filterOptions;
}

export async function getFilterProgramTypes() {
  const filterOptions = await fetchProgramTypeList();
  return filterOptions;
}

export async function getFilterApplicationStatusOptions() {
  const filterOptions = await fetchApplicationStatusList();
  return filterOptions;
}

export async function getApplicationTemplateOptions() {
  const filterOptions = await fetchApplicationTemplateList();
  return filterOptions;
}

export async function getFilterTrackingStepOptions() {
  const filterOptions = await fetchTrackingStepList();
  return filterOptions;
}

export async function getFilterTermTimingOptions() {
  const filterOptions = ['Past', 'Upcoming', 'On-site'];
  return filterOptions;
}

export async function getFilterTermTagOptions() {
  const filterOptions = await fetchTermTagsList();
  return ['(none)', ...filterOptions];
}

export async function getFilterTermNameOptions() {
  const filterOptions = await fetchTermNameList();
  return filterOptions;
}

import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FilterCheckBox from '../../filterTypes/filterCheckBox';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ApplicationFinancialAidFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  let sourceOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'Not Sure', value: 'Not Sure' },
  ];

  if (!props.hidePresetValues) {
    sourceOptions.push({ label: 'Not Provided  ', value: 'Not Provided' });
    sourceOptions.push({ label: 'Not Requested  ', value: 'Not Requested' });
  }

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterCheckBox
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ApplicationFinancialAidFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  hidePresetValues: propTypes.bool,
};

ApplicationFinancialAidFilter.defaultProps = {
  displayText: 'Financial Aid',
  selectedValues: [],
  hidePresetValues: false,
};

export default enhance(ApplicationFinancialAidFilter);

export const ApplicationFinancialAidFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationFinancialAidFilterMemo({
    selectedValues = null,
    onFilterChange,
    hidePresetValues = false,
    displayText = 'Receives Financial Aid',
  }) {
    return (
      <ApplicationFinancialAidFilter
        id={'receives_financial_aid'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('receives_financial_aid', values);
        }}
        displayText={displayText}
        hidePresetValues={hidePresetValues}
      />
    );
  }),
);

import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterAlternatesMultiSelect from '../../filterTypes/filterAlternatesMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ApplicationAlternatesFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }

    let valueNames = [];
    props.sourceData.forEach(item => {
      if (item.length > 0) {
        item.forEach(value => {
          let splitArr = value.split(': ');
          let stripStr = `${splitArr[0]}: `;
          let newValue = value.replace(stripStr, '');
          valueNames.push(newValue);
        });
      }
    });

    const optionValues = buildSelectOptions(valueNames, true, true);

    setSourceOptions(optionValues);
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterAlternatesMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ApplicationAlternatesFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
};

ApplicationAlternatesFilter.defaultProps = {
  displayText: 'Alternate',
  selectedValues: [],
};

export default enhance(ApplicationAlternatesFilter);

export const ApplicationAlternatesFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationAlternatesFilterMemo({
    selectedValues,
    onFilterChange,
    sourceData = [],
  }) {
    return (
      <ApplicationAlternatesFilter
        id={'alts'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('alts', values);
        }}
        sourceData={sourceData}
      />
    );
  }),
);

import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FilterDataRange from '../../filterTypes/filterDateRange';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ApplicationStartedFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);

  useEffect(() => {
    if (props.selectedValues) {
      const isDate = props.selectedValues[1] || props.selectedValues[2] || props.selectedValues[3] ? true : false;
      const calcExpanded = manualExpanded || isDate ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {!!props.selectedValues && (
            <FilterDataRange id={props.id} selectedValues={props.selectedValues} change={e => selectedValues(e)} />
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ApplicationStartedFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ApplicationStartedFilter.defaultProps = {
  displayText: 'Date Application Started',
};

export default enhance(ApplicationStartedFilter);

export const FormStartedFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function FormStartedFilterMemo({ selectedValues = null, onFilterChange }) {
    return (
      <ApplicationStartedFilter
        id={'form_created_on'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('form_created_on', values);
        }}
        displayText="Date Form Started"
      />
    );
  }),
);

export const ApplicationStartedFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationStartedFilterMemo({
    selectedValues = null,
    onFilterChange,
  }) {
    return (
      <ApplicationStartedFilter
        id={'application_created_on'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('application_created_on', values);
        }}
      />
    );
  }),
);

import styled from 'styled-components';

export const TableContainer = styled.div`
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  background-color: #fff;
  width: 100%;
  padding: 10px 10px 20px 10px;
  box-sizing: border-box;
`;

export const Table = styled.table`
  box-sizing: border-box;
  width: 100%;
  table-layout: fixed;

  th:first-child,
  tr:first-child {
    width: 40%;
  }

  tr:nth-of-type(even) {
    background-color: #f7f7f7;
  }

  tr:nth-of-type(odd) {
    background-color: #ebebeb;
  }
`;

export const TableHead = styled.thead`
  background-color: #ffffff;
`;

export const TableHeadRow = styled.tr`
  height: 42px;
`;

export const TableHeader = styled.th`
  padding: 0 9px;
  font-family: AvenirNextMedium, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #043544;
  background-color: #fff;
`;

export const TableHeaderCentered = styled(TableHeader)`
  text-align: center;
`;

export const TableBody = styled.tbody`
  margin: 10px;
  color: #505050;
  background-color: #fff;
  padding: 0 9px;
`;

export const TableRow = styled.tr`
  height: 42px;
  font-size: 14px;
`;

export const TableColumn = styled.td`
  padding: 10px;
`;

export const TableHeaderColumn = styled.td`
  padding: 10px;
`;

export const TableColumnCentered = styled.td`
  padding: 10px;
  cursor: pointer;
  text-align: center;
`;

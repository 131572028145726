import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Formik, Form, Field } from 'formik';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import InputSelect from '../../../../components/library/inputSelect';
import InputText from '../../../../components/library/inputText';
import sBatchUpdateCustomField from './batchUpdateCustomField.scss';
import OneClickModal from '../oneClickModal';

export const enhance = compose(withStyles(sBatchUpdateCustomField));

function BatchUpdateCustomField(props) {
  const [submitValues, setSubmitValues] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const customFieldStatus = useSelector(state => state.reports.updateCustomField);

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        custom_field: '',
        custom_field_value: '',
      };
      return values;
    });
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      custom_field: '',
      custom_field_value: '',
    };
    return values;
  });

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
        headerText={`Update Custom Field`}
      >
        <div className={sBatchUpdateCustomField['batch-update-custom-field']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              if (values.custom_field_value === '') {
                errors.custom_field_value = 'Error: Custom Field Content Required';
              }

              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                custom_field_id: values.custom_field.value,
                custom_field_value: values.custom_field_value,
                traveler_ids: props.selectedUsers,
              };
              setSubmitValues(submitValues);
              props.onClose(false);
              setShowConfirm(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchUpdateCustomField.row}>
                  <div className={sBatchUpdateCustomField.item}>
                    <Field name="custom_field">
                      {({ form }) => (
                        <InputSelect
                          id={values.custom_field}
                          name={values.custom_field}
                          labelText="Custom Field"
                          aria-label="Custom Field Options Dropdown"
                          errorMessage={errors.custom_field}
                          isRequired
                          helpText=""
                          isMulti={false}
                          value={values.custom_field}
                          options={props.custom_fields}
                          placeholder={props.custom_fields.label}
                          onChange={e => form.setFieldValue('custom_field', e)}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                {values.custom_field ? (
                  <div className={sBatchUpdateCustomField.row}>
                    <div className={sBatchUpdateCustomField.item}>
                      <Field name="custom_field_value">
                        {({ form }) => (
                          <InputText
                            id={values.custom_field_value}
                            name={values.custom_field_value}
                            labelText="Custom Field Content"
                            aria-label="Type new custom field content here..."
                            errorMessage={errors.custom_field_value}
                            isRequired
                            placeholder="Type new custom field content here..."
                            helpText=""
                            value={values.custom_field_value}
                            onChange={e => form.setFieldValue('custom_field_value', e.target.value)}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                ) : null}

                <div className={sBatchUpdateCustomField.footer}>
                  <div className={sBatchUpdateCustomField['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchUpdateCustomField['button-content']}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      type="submit"
                      aria-label="Update Custom Field Button"
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        headline={'Update Custom Field'}
        show={showConfirm}
        onClose={() => {
          resetForm();
          setShowConfirm(false);
          props.setSelectedPrograms();
        }}
        paragraphOne={`You are about to update the custom field of ${props.userIds.length} ${
          props.userIds.length === 1
            ? props.customAliases.alias_traveler.toLowerCase()
            : props.customAliases.alias_travelers.toLowerCase()
        }.`}
        paragraphTwo={'Ready to update?'}
        cancelMessage={'Cancel'}
        confirmActionMessage={'Update'}
        source="update_custom_field"
        selectedPrograms={props.formIds}
        actionState={customFieldStatus}
        updateCustomFieldSuccess={`${props.userIds.length} ${
          props.userIds.length === 1
            ? props.customAliases.alias_traveler.toLowerCase()
            : props.customAliases.alias_travelers.toLowerCase()
        } updated `}
        resetForm={() => resetForm()}
        submitValues={submitValues}
        fetchApps={props.fetchApps}
      />
    </>
  );
}

BatchUpdateCustomField.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

BatchUpdateCustomField.defaultProps = {
  fetchApps: true,
};

export default enhance(BatchUpdateCustomField);

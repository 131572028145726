import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './DefaultSelect.css';
const DefaultSelect = ({
  id,
  name,
  onChange,
  value,
  options,
  defaultLabel,
  style
}) => {
  return (
    <select name={name} id={id} onChange={onChange} value={value} style={style}>
      {defaultLabel && <option>{defaultLabel}</option>}
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default withStyles(s)(DefaultSelect);

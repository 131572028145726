export default function checkTabNotEmpty(program, tab) {
  switch (tab) {
    case 'aboutProgram':
      return (
        program.types.length > 0 ||
        (program.language.primary !== null && program.language.primary.length >= 12) ||
        program.housing.types.some(type => type.included) ||
        (program.housing.notes !== null && program.housing.notes.length >= 12) ||
        (program.location.notes !== null && program.location.notes.length >= 12) ||
        program.location.locations.length > 0 ||
        (program.hostOrganization.name !== null && program.hostOrganization.name.length >= 12) ||
        (program.hostOrganization.notes !== null && program.hostOrganization.notes.length >= 12) ||
        program.activity.activities.length > 0 ||
        program.activity.excursions.length > 0 ||
        program.activity.addons.length > 0 ||
        (program.activity.notes !== null && program.activity.notes.length >= 12)
      );
    case 'academics':
      return (
        (program.academic.notes !== null && program.academic.notes.length >= 12) ||
        program.academic.subjects.length > 0 ||
        (program.academic.course.notes !== null && program.academic.course.notes.length >= 12)
      );
    case 'termInformation':
      return program.terms.length === 0 || program.terms.length > 0;
    case 'whatsIncluded':
      return program.amenities.included.length > 0 || program.amenities.excluded.length > 0;
    case 'eligibility':
      return program.eligibility !== null && program.eligibility.length >= 12;
    case 'costAndFunding':
      return program.terms.length > 0 || (program.scholarshipInfo !== '' && program.scholarshipInfo.length >= 12);
    case 'resources':
      return program.attachments.length > 0;
    default:
      return false;
  }
}

import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { getTermNames } from '../../../../../actions/termNamesActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelectWithIs from '../../filterTypes/filterMultiSelectWithIs';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ProgramTermNameFilter(props) {
  const dispatch = useDispatch();
  const termNames = useSelector(state => state.termNames.termNames.data);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      if (props.selectedValues[1] && props.selectedValues[1].length > 0) {
        props.selectedValues[1] = props.selectedValues[1].sort();
      }

      const isValues =
        props.selectedValues[0] && props.selectedValues[1] && props.selectedValues[1].length > 0 ? true : false;
      const calcExpanded = manualExpanded || isValues ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (!termNames) {
        dispatch(getTermNames());
      } else {
        let isBlank = ['Blank'];
        let active = [];
        let archived = [];

        termNames.data.map(item => {
          if (item.attributes.name.length > 0) {
            if (!item.attributes.archived) {
              active.push(item.attributes.name);
            } else {
              archived.push(item.attributes.name);
            }
          }
        });

        const isBlankValues = buildSelectOptions(isBlank, false, false);
        const activeValues = buildSelectOptions(active, true, true);
        const archivedValues = buildSelectOptions(archived, true, true);
        const groupOptions = [
          { label: '', options: isBlankValues },
          { label: 'Active', options: activeValues },
          { label: 'Archived', options: archivedValues },
        ];

        setSourceOptions(groupOptions);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [termNames]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {props.withIs ? (
            <FilterMultiSelectWithIs
              id={props.id}
              sourceData={sourceOptions}
              selectedValues={props.selectedValues}
              change={e => selectedValues(e)}
              labelText={''}
            />
          ) : (
            <FilterMultiSelect
              id={props.id}
              sourceData={sourceOptions}
              selectedValues={props.selectedValues}
              change={e => selectedValues(e)}
              labelText={''}
            />
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramTermNameFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  withIs: propTypes.bool,
};

ProgramTermNameFilter.defaultProps = {
  displayText: 'Term Name',
  selectedValues: [],
  withIs: false,
};

export default enhance(ProgramTermNameFilter);

export const ProgramTermNameFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramTermNameFilterMemo({
    selectedValues = null,
    onFilterChange,
    sourceData = [],
    withIs = false,
  }) {
    return (
      <ProgramTermNameFilter
        id={'program_term_name'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('program_term_name', values);
        }}
        displayText={`Term Name`}
        sourceData={sourceData}
        withIs={withIs}
      />
    );
  }),
);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';
import FilterNumericRange from '../../filterTypes/filterNumericRange';

export const enhance = compose(
  withStyles(sFilterAccordion),
);

function ProgramCreditMinFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);

  useEffect(() => {
    if (props.selectedValues) {
      const isData = (
        Number.isInteger(parseInt(props.selectedValues[1])) ||
        Number.isInteger(parseInt(props.selectedValues[2])) ||
        Number.isInteger(parseInt(props.selectedValues[3]))
      ) ? true : false;
      const calcExpanded = manualExpanded || isData ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  const selectedValues = (values) => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>
            {props.displayText}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {!!props.selectedValues && (
            <FilterNumericRange
              id={props.id}
              selectedValues={props.selectedValues}
              change={(e) => selectedValues(e)}
            />
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramCreditMinFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ProgramCreditMinFilter.defaultProps = {
  displayText: 'Credit Range Minimum',
};

export default enhance(ProgramCreditMinFilter);

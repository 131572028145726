import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputSelect from '../../../library/inputSelect';
import InputText from '../../../library/inputText';
import sFilterNumericRange from './filterNumericRange.scss';
import _isEqual from 'lodash/isEqual';

export const enhance = compose(withStyles(sFilterNumericRange));

function FilterNumericRange(props) {
  const [loading, setLoading] = useState(true);
  const [rangeType, setRangeType] = useState(props.selectedValues[0] || 'is');
  const [singleNumericValue, setSingleNumericValue] = useState(props.selectedValues[1]);
  const [fromNumericValue, setFromNumericValue] = useState(props.selectedValues[2]);
  const [toNumericValue, setToNumericValue] = useState(props.selectedValues[3]);
  const [defaultIndex, setDefaultIndex] = useState();
  const typeOptions = [
    {value: 'is', label: 'Is'},
    {value: 'is_between', label: 'Is between (inclusive)'},
    {value: 'is_greater', label: 'Is greater than or equal to'},
    {value: 'is_less', label: 'Is less than or equal to'}
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.selectedValues[0]) {
      let defaultIndexValue = typeOptions.find(item => item.value === props.selectedValues[0]);
      if (!defaultIndexValue || defaultIndexValue === undefined) {
        defaultIndexValue = typeOptions[0];
      }

      setDefaultIndex(defaultIndexValue);
      setRangeType(props.selectedValues[0]);
      setSingleNumericValue(props.selectedValues[1]);
      setFromNumericValue(props.selectedValues[2]);
      setToNumericValue(props.selectedValues[3]);
    } else {
      let defaultIndexValue = typeOptions.find(item => item.value === rangeType);
      setDefaultIndex(defaultIndexValue);
      setSingleNumericValue('');
      setFromNumericValue('');
      setToNumericValue('');
    }
  }, [props.selectedValues]);

  useEffect(() => {
    let filterValues = [];
    let saveType = rangeType;

    if (typeof saveType === 'object') {
      saveType = typeOptions[0].value;
    }

    if (saveType === 'is_between') {
      filterValues.push(saveType);
      filterValues.push('');
      filterValues.push(Number.isInteger(parseInt(fromNumericValue)) ? parseInt(fromNumericValue) : '');
      filterValues.push(Number.isInteger(parseInt(toNumericValue)) ? parseInt(toNumericValue) : '');
    } else {
      filterValues.push(saveType);
      filterValues.push(Number.isInteger(parseInt(singleNumericValue)) ? parseInt(singleNumericValue) : '');
      filterValues.push('');
      filterValues.push('');
    }

    if (!loading) {
      if (!_isEqual(filterValues, props.selectedValues)) {
        props.change(filterValues);
      }
    }
  }, [rangeType, singleNumericValue, fromNumericValue, toNumericValue]);

  return (
    <div className={sFilterNumericRange.numericRangeFilter}>
      <InputSelect
        id="rangeType"
        labelText=""
        styles={{
          menu: provided => ({ ...provided, zIndex: 99999 })
        }}
        options={typeOptions}
        onChange={(e) => {
          setRangeType(e.value);
        }}
        defaultValue={defaultIndex}
        value={defaultIndex}
      />

      {rangeType && rangeType !=='is_between' && (
        <div className={sFilterNumericRange.singleValue}>
          <div className={sFilterNumericRange.singleValue}>
            <InputText
              type="number"
              placeholder=""
              value={Number.isInteger(parseInt(singleNumericValue)) ? singleNumericValue : ''}
              labelText="Number"
              id="singleDuration"
              name="singleDuration"
              size="normal"
              labelSize="normal"
              onChange={e => {setSingleNumericValue(e.target.value)}}
            />
          </div>
        </div>
      )}

      {rangeType && rangeType ==='is_between' && (
        <div className={sFilterNumericRange.rangeValues}>
          <div className={sFilterNumericRange.rangeValue}>
            <InputText
              type="number"
              placeholder=""
              value={Number.isInteger(parseInt(fromNumericValue)) ? fromNumericValue : ''}
              labelText="Number"
              id="fromDuration"
              name="fromDuration"
              size="normal"
              labelSize="normal"
              onChange={e => {setFromNumericValue(e.target.value) }}
            />
          </div>
          <span className={sFilterNumericRange.separator}>and</span>
          <div className={sFilterNumericRange.rangeValue}>
            <InputText
              type="number"
              placeholder=""
              value={Number.isInteger(parseInt(toNumericValue)) ? toNumericValue : ''}
              labelText="Number"
              id="toDuration"
              name="toDuration"
              size="normal"
              labelSize="normal"
              onChange={e => {setToNumericValue(e.target.value)}}
            />
          </div>
        </div>
      )}
    </div>
  );
}

FilterNumericRange.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

FilterNumericRange.defaultProps = {
  displayText: 'Filter Options',
  selectedValues: [],
};

export default enhance(FilterNumericRange);

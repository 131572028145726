import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import { withCookies } from 'react-cookie';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import InputSelect from '../../components/library/inputSelect';
import { fetchReportFilters, createReportFilter, deleteReportFilter } from '../../../actions/reportsActions';
import Button from '../library/button';
import InputText from '../library/inputText';
import ViaIcon from '../../plans/components/ViaIcon';
import Hubspot from '../../../components/Hubspot';
import sRptFilter from './rptFilter.scss';

export const enhance = compose(withCookies, withStyles(sRptFilter));

function RptFilter(props) {
  const { cookies } = props;
  const userId = cookies.get('user_id');
  const dispatch = useDispatch();
  const filters = useSelector(state => state.reports.fetchReportFilters);
  const filterSave = useSelector(state => state.reports.createReportFilter);
  const filterDelete = useSelector(state => state.reports.deleteReportFilter);
  const [errorMsg, setErrorMsg] = useState('');
  const [options, setOptions] = useState([]);
  const [allFilters, setAllFilters] = useState();
  const [selected, setSelected] = useState();
  const [filterName, setFilterName] = useState('');
  const [filterShared, setFilterShared] = useState(false);
  const [formAction, setFormAction] = useState('select');

  useEffect(() => {
    dispatch(fetchReportFilters(props.type));
  }, []);

  useEffect(() => {
    if (!!filterDelete && !!filterDelete.data && !!filterDelete.data.success) {
      setFormAction('select');
      setSelected(null);
      dispatch(fetchReportFilters(props.type));
    }
  }, [filterDelete]);

  useEffect(() => {
    if (!!filterSave && !!filterSave.data && !!filterSave.data.attributes) {
      setFormAction('select');
      setSelected(filterSave.data);
      dispatch(fetchReportFilters(props.type));
    }
  }, [filterSave]);

  useEffect(() => {
    if (!!filters && !!filters.data) {
      setAllFilters(filters);
      const sortedList = _orderBy(filters.data, item => item.attributes.name, ['asc']);
      const opts = sortedList.map(item => {
        const shared = parseInt(userId) !== item.attributes.user_id ? '(shared)' : '';
        return { label: `${item.attributes.name} ${shared}`, value: item.id };
      });
      setOptions(opts);
    }
  }, [filters]);

  const filterChange = evt => {
    const selOption = _find(allFilters.data, filter => {
      return filter.id.toString() === evt.value.toString();
    });
    props.selectedFilter(JSON.parse(selOption.attributes.filter));
    setSelected(selOption);
  };

  const saveFilter = () => {
    if (!filterName || filterName.length < 1) {
      setErrorMsg('Name is required');
    } else {
      setErrorMsg('');
    }
    const getFilters = props.filters();
    const searchFilter = {
      filter: JSON.stringify(getFilters),
      filter_type: props.type,
      name: filterName,
      shared: filterShared,
    };
    dispatch(createReportFilter(searchFilter));
  };

  const updateAction = value => {
    setFormAction(value);
  };

  const deleteFilter = () => {
    dispatch(deleteReportFilter(selected.id));
  };

  return (
    <nav>
      <Hubspot />
      <div className={sRptFilter['wrapper']}>
        {formAction.toLowerCase() === 'select' && (
          <div className={sRptFilter['select']}>
            {!!options && options.length > 0 && (
              <div className={sRptFilter['select-dropdown']}>
                <InputSelect
                  labelText="Filters"
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 99999 }),
                  }}
                  options={options}
                  onChange={e => {
                    filterChange(e);
                  }}
                  defaultValue={
                    !!selected && selected.id
                      ? { value: selected.id, label: selected.attributes.name }
                      : { value: 'none', label: 'Select a filter...' }
                  }
                  value={
                    !!selected && selected.id
                      ? { value: selected.id, label: selected.attributes.name }
                      : { value: 'none', label: 'Select a filter...' }
                  }
                />
              </div>
            )}

            <div className={sRptFilter['select-options']}>
              {!!selected && (
                <>
                  {selected.attributes.user_id === parseInt(userId) && (
                    <span
                      className={sRptFilter['action']}
                      onClick={() => {
                        deleteFilter();
                      }}
                    >
                      <ViaIcon name="trash" color="darkText" size="xsmall" />
                      &nbsp;Delete
                    </span>
                  )}
                </>
              )}
              <span
                className={sRptFilter['action']}
                onClick={() => {
                  updateAction('add');
                }}
              >
                Create New Filter
              </span>
            </div>
          </div>
        )}
        {formAction.toLowerCase() === 'add' && (
          <div className={sRptFilter['new-filter']}>
            <InputText
              id="rptName"
              name="rptName"
              labelText="Name"
              isRequired
              placeholder="Name for the new filter"
              errorMessage={errorMsg}
              onChange={event => setFilterName(event.target.value)}
            />
            <div>
              <input
                type="checkbox"
                name="shared"
                defaultChecked={filterShared}
                onChange={() => {
                  setFilterShared(!filterShared);
                }}
              />
              <label id="sharedLabel" htmlFor="shared">
                &nbsp;&nbsp;Share with all admins{' '}
              </label>
            </div>
            <div className={sRptFilter['button-wrapper']}>
              <div className={sRptFilter['button-content']}>
                <Button
                  onClick={() => {
                    updateAction('select');
                  }}
                  display="secondary"
                  kind="solid"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
              <div className={sRptFilter['button-content']}>
                <Button onClick={saveFilter} display="primary" kind="solid" size="small">
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

RptFilter.propTypes = {
  type: propTypes.oneOf([
    'report_application_form',
    'report_application',
    'report_program_range',
    'report_traveler',
    'rpt_vt_plans',
    'rpt_vt_travelers',
    'rpt_safecheck_messages',
  ]),
  filters: propTypes.func.isRequired,
  selectedFilter: propTypes.func.isRequired,
};

export default enhance(RptFilter);

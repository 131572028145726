import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import {
  createReportFilter,
  deleteReportFilter,
  fetchReportFilters,
  updateReportFilter,
} from '../../../../../../actions/reportsActions';
import Paragraph from '../../../../../components/library/paragraph';
import InputText from '../../../../../components/library/inputText';
import Button from '../../../../../components/library/button';
import InputSelect from '../../../../../components/library/inputSelect';
import ProgramStatusFilter from '../../../../../components/filters/options/programStatusFilter';
import ProgramNameFilter from '../../../../../components/filters/options/programNameFilter';
import ProgramTagFilter from '../../../../../components/filters/options/programTagFilter';
import ProgramTermNameFilter from '../../../../../components/filters/options/programTermNameFilter';
import ProgramTermTimingFilter from '../../../../../components/filters/options/programTermTimingFilter';
import ProgramSubjectAreasFilter from '../../../../../components/filters/options/programSubjectAreasFilter';
import ProgramCountriesFilter from '../../../../../components/filters/options/programCountriesFilter';
import ProgramCitiesFilter from '../../../../../components/filters/options/programCitiesFilter';
import ProgramTypeFilter from '../../../../../components/filters/options/programTypeFilter';
import ProgramLanguageFilter from '../../../../../components/filters/options/programLanguageFilter';
import ProgramLanguageImmersionFilter from '../../../../../components/filters/options/programLanguageImmersionFilter';
import ProgramHousingTypeFilter from '../../../../../components/filters/options/programHousingTypeFilter';
import ProgramAuthorizableFilter from '../../../../../components/filters/options/programAuthorizableFilter';
import ProgramClientAdminsFilter from '../../../../../components/filters/options/programClientAdminsFilter';
import ProgramStartFilter from '../../../../../components/filters/options/programStartFilter';
import ProgramEndFilter from '../../../../../components/filters/options/programEndFilter';
import ViaIcon from '../../../../../plans/components/ViaIcon';
import ToastMessage from '../../../toastMessage';
import sLeftNavFilters from './leftNavFilters.scss';

export const enhance = compose(
  withCookies,
  withStyles(sLeftNavFilters),
  withRouter,
);

function LeftNavFilters(props) {
  const { cookies } = props;
  const userId = cookies.get('user_id');
  const dispatch = useDispatch();
  const SELECT_OPTION_NAME = 'selOption_internalprogram_dashboard'
  const selLocalOption = JSON.parse(localStorage.getItem(SELECT_OPTION_NAME))
  const [savedOptions, setSavedOptions] = useState([]);
  const customAliases = useSelector(state => state.profile.customAliases);
  const filters = useSelector(state => state.reports.fetchReportFilters);
  const createFilter = useSelector(state => state.reports.createReportFilter);
  const filterDelete = useSelector(state => state.reports.deleteReportFilter);
  const updateFilter = useSelector(state => state.reports.updateReportFilter);
  const [saveFilterName, setSaveFilterName] = useState('');
  const [savedFilterOptions, setSavedFilterOptions] = useState([]);
  const [selectedFilterOption, setSelectedFilterOption] = useState();
  const [allFilters, setAllFilters] = useState();
  const [isFilters, setIsFilters] = useState(false);
  const [newFilter, setNewFilter] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveMsg, setSaveMsg] = useState('');

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchReportFilters(props.filterType));
      setSelectedFilterOption(!!selLocalOption && selLocalOption)
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(
    () => {
      let myFilters = [];
      let myTeamFilters = [];
      let teamFilters = [];

      if (!!filters && !!filters.data) {
        setAllFilters(filters);

        const sortedList = _orderBy(filters.data, item => item.attributes.name, ['asc']);
        sortedList.forEach(item => {
          if (parseInt(userId) === item.attributes.user_id) {
            if (item.attributes.shared) {
              myTeamFilters.push({ label: `${item.attributes.name}`, value: item.id });
            } else {
              myFilters.push({ label: `${item.attributes.name}`, value: item.id });
            }
          }

          if (parseInt(userId) !== item.attributes.user_id) {
            teamFilters.push({ label: `${item.attributes.name}`, value: item.id });
          }
        });

        const groupOptions = [
          {
            icon: <ViaIcon name="solo" color="gray" size="mini" />,
            label: 'My filters',
            options: myFilters,
          },
          {
            icon: <ViaIcon name="eye" color="gray" size="small" />,
            label: 'My filters visible to team',
            options: myTeamFilters,
          },
          {
            icon: <ViaIcon name="group" color="gray" size="small" />,
            label: 'Team filters',
            options: teamFilters,
          },
        ];

        if (!!newFilter) {
          setNewFilter(null);
          const selOption = _find(filters.data, filter => {
            return filter.id.toString() === newFilter.id.toString();
          });
          setSelectedFilterOption(selOption);
        }

        setSavedFilterOptions(groupOptions);
      }
    },
    [filters],
  );

  useEffect(
    () => {
      let buildDefaultValues = [];
      if (props.savedFilters && !!props.savedFilters.buildSaveFilter && props.savedFilters.buildSaveFilter.length > 0) {
        props.savedFilters.buildSaveFilter.forEach(item => {
          let defaultValues = item.filterList;
          buildDefaultValues[item.name] = defaultValues;
        });
      }

      setIsFilters(filterCheck());
      setSavedOptions(buildDefaultValues);
    },
    [props],
  );

  useEffect(
    () => {
      if (createFilter.data) {
        setNewFilter(createFilter.data);
        setSaveMsg('Your filter has been saved');
        setSaveSuccess(true);
        localStorage.setItem(SELECT_OPTION_NAME, JSON.stringify(createFilter.data))
        dispatch(fetchReportFilters(props.filterType));
      }
    },
    [createFilter],
  );

  useEffect(
    () => {
      if (filterDelete.data) {
        setSelectedFilterOption(null);
        setSaveMsg('Your filter has been deleted');
        setSaveSuccess(true);
        localStorage.removeItem(SELECT_OPTION_NAME)
        dispatch(fetchReportFilters(props.filterType));
      }
    },
    [filterDelete],
  );

  useEffect(
    () => {
      if (updateFilter.data) {
        setSaveSuccess(true);
        dispatch(fetchReportFilters(props.filterType));
      }
    },
    [updateFilter],
  );

  const onFilterChange = (filter, values) => {
    setSelectedFilterOption(null);
    props.change(filter, values);
  };

  const filterCheck = () => {
    let filterLength = 0;
    if (!!props.savedFilters && !!props.savedFilters.buildSaveFilter) {
      props.savedFilters.buildSaveFilter.map(item => {
        !!item.filterList ? (filterLength = filterLength + item.filterList.length) : 0;
      });
    }

    return filterLength > 0;
  };

  const saveFilter = () => {
    const getFilters = props.filters();
    const searchFilter = {
      filter: JSON.stringify(getFilters),
      filter_type: props.filterType,
      name: saveFilterName,
      shared: false,
    };

    setSaveFilterName('');
    dispatch(createReportFilter(searchFilter));
  };

  const filterChange = evt => {
    const selOption = _find(allFilters.data, filter => {
      return filter.id.toString() === evt.value.toString();
    });

    const setOptions = JSON.parse(selOption.attributes.filter);
    const savedFilters = _get(setOptions, 'buildSaveFilter') || setOptions;
    const savedSort = _get(setOptions, 'sortOption') || {};
    props.selectedFilter(savedFilters, savedSort);
    localStorage.setItem(SELECT_OPTION_NAME, JSON.stringify(selOption))
    setSelectedFilterOption(selOption);
  };

  const deleteFilter = () => {
    dispatch(deleteReportFilter(selectedFilterOption.id));
  };

  const updateFilterShare = shared => {
    const search_filter = {
      shared: shared,
    };

    setNewFilter(selectedFilterOption);
    dispatch(updateReportFilter(selectedFilterOption.id, search_filter));
  };

  const clearFilters = () => {
    setSelectedFilterOption(null);
    localStorage.removeItem(SELECT_OPTION_NAME)
    if (!!props.savedFilters && !!props.savedFilters.buildSaveFilter) {
      props.savedFilters.buildSaveFilter.forEach(item => {
        if (!!item.filterList && item.filterList.length > 0) {
          onFilterChange(item.name, []);
        }
      });
    }
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    borderBottom: '1px solid #ebebeb',
    textTransform: 'none',
  };

  const groupBadgeStyles = {
    color: '#767676',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    textAlign: 'center',
    paddingRight: '8px',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span style={groupBadgeStyles}>{data.icon}</span>
      <span>{data.label}</span>
    </div>
  );

  return (
    <div className={sLeftNavFilters.wrapper}>
      <div className={sLeftNavFilters.filterHeader}>
        <div className={sLeftNavFilters['filterHeader-title']}>
          <Paragraph bold>Filters</Paragraph>
          <Paragraph color="secondary" bold size="normal">
            <span className={sLeftNavFilters.clear} onClick={clearFilters}>
              Clear Filters
            </span>
          </Paragraph>
        </div>

        <div className={sLeftNavFilters['filterHeader-options']}>
          <InputSelect
            noOptionsMessage={() => 'Save your first filter below'}
            labelText=""
            styles={{
              menu: provided => ({ ...provided, zIndex: 99999 }),
              placeholder: provided => ({ ...provided, color: '#767676', fontStyle: 'italic' }),
            }}
            options={savedFilterOptions}
            formatGroupLabel={formatGroupLabel}
            onChange={e => {
              filterChange(e);
            }}
            placeholder="Select a filter..."
            defaultValue={
              !!selectedFilterOption && selectedFilterOption.id
                ? { value: selectedFilterOption.id, label: selectedFilterOption.attributes.name }
                : ''
            }
            value={
              !!selectedFilterOption && selectedFilterOption.id
                ? { value: selectedFilterOption.id, label: selectedFilterOption.attributes.name }
                : ''
            }
          />
        </div>
      </div>
      <div className={sLeftNavFilters['filter-options']}>
        <div className={sLeftNavFilters.optWrapper}>
          <ProgramStatusFilter
            id={'status'}
            selectedValues={savedOptions['status']}
            change={values => {
              onFilterChange('status', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramNameFilter
            id={'program_name'}
            selectedValues={savedOptions['program_name']}
            displayText={`${customAliases.alias_program} Name`}
            change={values => {
              onFilterChange('program_name', values);
            }}
            source={'internal'}
            sourceData={_map(props.programs, 'program_name')}
            withIs
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramTermNameFilter
            id={'term_names'}
            selectedValues={savedOptions['term_names']}
            change={values => {
              onFilterChange('term_names', values);
            }}
            withIs
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramTagFilter
            id={'term_tags'}
            selectedValues={savedOptions['term_tags']}
            change={values => {
              onFilterChange('term_tags', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramTermTimingFilter
            id={'term_timing'}
            selectedValues={savedOptions['term_timing']}
            change={values => {
              onFilterChange('term_timing', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramStartFilter
            id={'term_starts'}
            selectedValues={savedOptions['term_starts'] || null}
            change={values => {
              onFilterChange('term_starts', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramEndFilter
            id={'term_ends'}
            selectedValues={savedOptions['term_ends'] || null}
            change={values => {
              onFilterChange('term_ends', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramSubjectAreasFilter
            id={'program_subject_areas'}
            selectedValues={savedOptions['program_subject_areas']}
            change={values => {
              onFilterChange('program_subject_areas', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramCountriesFilter
            id={'countries'}
            selectedValues={savedOptions['countries']}
            change={values => {
              onFilterChange('countries', values);
            }}
            displayText={`${customAliases.alias_program} Country`}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramCitiesFilter
            id={'cities'}
            selectedValues={savedOptions['cities']}
            displayText={`${customAliases.alias_program} City`}
            change={values => {
              onFilterChange('cities', values);
            }}
            sourceData={_map(props.programs, 'cities')}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramTypeFilter
            id={'types'}
            selectedValues={savedOptions['types']}
            change={values => {
              onFilterChange('types', values);
            }}
            displayText={`${customAliases.alias_program} Type`}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramLanguageFilter
            id={'instructional_languages'}
            selectedValues={savedOptions['instructional_languages']}
            change={values => {
              onFilterChange('instructional_languages', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramLanguageImmersionFilter
            id={'language_immersion'}
            selectedValues={savedOptions['language_immersion']}
            change={values => {
              onFilterChange('language_immersion', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramHousingTypeFilter
            id={'program_housing_types'}
            selectedValues={savedOptions['program_housing_types']}
            change={values => {
              onFilterChange('program_housing_types', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramClientAdminsFilter
            id={'program_contact'}
            selectedValues={savedOptions['program_contact']}
            change={values => {
              onFilterChange('program_contact', values);
            }}
            displayText={`${customAliases.alias_program} Contact`}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramClientAdminsFilter
            id={'program_administrators'}
            selectedValues={savedOptions['program_administrators']}
            change={values => {
              onFilterChange('program_administrators', values);
            }}
            displayText={`${customAliases.alias_program} Administrators`}
          />
        </div>

        <div className={sLeftNavFilters.divider} />

        <div className={sLeftNavFilters.optWrapper}>
          <ProgramAuthorizableFilter
            id={'authorizable'}
            selectedValues={savedOptions['authorizable']}
            change={values => {
              onFilterChange('authorizable', values);
            }}
          />
        </div>

        <div className={sLeftNavFilters.divider} />
        <div className={sLeftNavFilters.optBottomSpacer} />
      </div>

      <div className={sLeftNavFilters.filterFooter}>
        {!selectedFilterOption && (
          <>
            <div className={sLeftNavFilters.saveName}>
              <InputText
                name="save_filter_search"
                id="save_filter_search"
                placeholder="Type filter name"
                type="text"
                onChange={e => setSaveFilterName(e.target.value)}
                value={saveFilterName}
                disabled={!isFilters}
              />
            </div>
            <div className={sLeftNavFilters.saveButton}>
              <Button
                display="primary"
                kind="solid"
                size="filterSave"
                disabled={!isFilters || saveFilterName.length === 0}
                onClick={() => saveFilter()}
              >
                Save
              </Button>
            </div>
          </>
        )}
        {!!selectedFilterOption && (
          <>
            {selectedFilterOption.attributes.user_id === parseInt(userId) && (
              <div className={sLeftNavFilters.deleteFilter} onClick={deleteFilter}>
                <Paragraph size="normal" bold>
                  Delete filter
                </Paragraph>
              </div>
            )}
            {!selectedFilterOption.attributes.shared &&
              selectedFilterOption.attributes.user_id === parseInt(userId) && (
                <div className={sLeftNavFilters.shareButton}>
                  <Button
                    display="primary"
                    kind="solid"
                    size="filterSave"
                    disabled={!isFilters}
                    onClick={() => {
                      setSaveMsg('Your filter is now available to your team');
                      updateFilterShare(true);
                    }}
                  >
                    Make available to team
                  </Button>
                </div>
              )}
            {!!selectedFilterOption.attributes.shared &&
              selectedFilterOption.attributes.user_id === parseInt(userId) && (
                <div className={sLeftNavFilters.shareButton}>
                  <Button
                    display="text"
                    kind="outline"
                    size="filterSave"
                    disabled={!isFilters}
                    onClick={() => {
                      setSaveMsg('Team access has been removed');
                      updateFilterShare(false);
                    }}
                  >
                    Remove team access
                  </Button>
                </div>
              )}
            {!!selectedFilterOption.attributes.shared &&
              selectedFilterOption.attributes.user_id !== parseInt(userId) && (
                <div className={sLeftNavFilters.shareButton}>
                  <Button
                    display="primary"
                    kind="solid"
                    size="filterSave"
                    disabled={!isFilters}
                    onClick={() => setSelectedFilterOption(null)}
                  >
                    Save as my own
                  </Button>
                </div>
              )}
          </>
        )}
      </div>
      {!!saveSuccess &&
        saveMsg.length > 0 && (
          <ToastMessage
            message={saveMsg}
            show={saveSuccess}
            onClose={() => {
              setSaveSuccess(false);
              setSaveMsg('');
            }}
            isSuccess={true}
          />
        )}
    </div>
  );
}

LeftNavFilters.propTypes = {
  change: propTypes.func.isRequired,
  filters: propTypes.func.isRequired,
  filterType: propTypes.string.isRequired,
  programs: propTypes.array.isRequired,
  savedFilters: propTypes.object,
  selectedFilter: propTypes.func.isRequired,
};

export default enhance(LeftNavFilters);

import React from 'react';

const buildTemplateColumns = template => {
  let newColumns = [];

  if (template?.attributes?.questions) {
    template.attributes.questions.forEach(q => {
      newColumns.push({
        name: `${q.id.toString()}-1`,
        label: q.label || 'Unlabeled Question',
        options: {
          filter: false,
          display: true,
          sort: false,
          filterList: [],
          setCellProps: () => ({ style: { minWidth: '250px' } }),
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              />
            );
          },
        },
      });
    });
  }

  return newColumns;
};

export { buildTemplateColumns };

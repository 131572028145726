exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";@-webkit-keyframes B_T5a{to{-webkit-transform:rotate(1turn)}}@keyframes B_T5a{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2yD5f{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._2yD5f ._31uEU{margin:24px 40px}._2yD5f ._31uEU p{margin-bottom:24px}._2yD5f ._1TAqc{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;padding:12px 20px;margin:12px 40px}@media only screen and (max-width:599px){._2yD5f ._1TAqc{-ms-flex-direction:column;flex-direction:column}}._2yD5f ._1TAqc ._3mDE8{-ms-flex:1 1;flex:1 1;margin:12px 20px}._2yD5f ._3ON-R{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid  #ebebeb;margin:12px 40px;-ms-flex-pack:end;justify-content:flex-end}@media only screen and (max-width:599px){._2yD5f ._3ON-R{-ms-flex-direction:column;flex-direction:column}}._2yD5f ._3ON-R ._7VDWz{margin-left:12px}@media only screen and (max-width:599px){._2yD5f ._3ON-R ._7VDWz{margin-top:12px}}", ""]);

// exports
exports.locals = {
	"batch-update-custom-field": "_2yD5f",
	"content": "_31uEU",
	"row": "_1TAqc",
	"item": "_3mDE8",
	"footer": "_3ON-R",
	"button-content": "_7VDWz",
	"spin": "B_T5a"
};
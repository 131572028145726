import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelectWithIs from '../../filterTypes/filterMultiSelectWithIs';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ProgramProviderTermNameFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(
    () => {
      if (props.selectedValues) {
        const isValues =
          props.selectedValues[0] && props.selectedValues[1] && props.selectedValues[1].length > 0 ? true : false;
        const calcExpanded = manualExpanded || isValues ? true : false;

        setExpanded(calcExpanded);
      }

      let options = props.sourceData.filter(option => option !== '');
      
      let availableOptions = options.join(';').split(';').map(option => option.trim());

      let blankOptions = props.sourceData.some(option => option === '') ? ['Blank'] : [];
      const availableValues = buildSelectOptions(availableOptions, true, true);
      const blankValues = buildSelectOptions(blankOptions, false, false);
      const groupOptions = [{ label: '', options: blankValues }, { label: 'Available', options: availableValues }];

      setSourceOptions(groupOptions);
    },
    [props],
  );

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {props.withIs ? (
            <FilterMultiSelectWithIs
              id={props.id}
              sourceData={sourceOptions}
              selectedValues={props.selectedValues}
              change={e => selectedValues(e)}
              labelText={''}
            />
          ) : (
            <FilterMultiSelect
              id={props.id}
              sourceData={sourceOptions}
              selectedValues={props.selectedValues}
              change={e => selectedValues(e)}
              labelText={''}
            />
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramProviderTermNameFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  withIs: propTypes.bool,
};

ProgramProviderTermNameFilter.defaultProps = {
  displayText: 'Provider Term Name',
  selectedValues: [],
  withIs: false,
};

export default enhance(ProgramProviderTermNameFilter);

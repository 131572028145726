import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FilterCheckBox from '../../filterTypes/filterCheckBox';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ProgramInternalAuthorizedFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const sourceOptions = [
    { label: 'Internal', value: 'Internal' },
    { label: 'Authorized', value: 'Authorized' },
  ];

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterCheckBox
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramInternalAuthorizedFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ProgramInternalAuthorizedFilter.defaultProps = {
  displayText: 'Internal / Authorized',
  selectedValues: [],
};

export default enhance(ProgramInternalAuthorizedFilter);

export const ProgramInternalAuthorizedFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramInternalAuthorizedFilterMemo({
    selectedValues = null,
    onFilterChange,
  }) {
    return (
      <ProgramInternalAuthorizedFilter
        id={'internal_authorized'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('internal_authorized', values);
        }}
      />
    );
  }),
);

import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { getTags } from '../../../../../actions/tagsActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

let calledTags = false; 

function ProgramTagFilter(props) {
  const dispatch = useDispatch();
  const tags = useSelector(state => state.tags.tags);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    if (tags && tags.length > 0) {
      let options = [];

      tags.map(item => {
        if (item.name.length > 0) {
          options.push(item.name);
        }
      });

      const optionValues = buildSelectOptions(options, true, true);
      setSourceOptions(optionValues);
    }
  }, [tags]);

  useEffect(() => {
    let unmounted = false;

    if(!calledTags){
      if (!unmounted) {
        calledTags = true; 
        dispatch(getTags());
      }
    }
    
    return () => {
      unmounted = true;
    };
  }, []);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramTagFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ProgramTagFilter.defaultProps = {
  displayText: 'Term Tags',
  selectedValues: [],
};

export default enhance(ProgramTagFilter);

export const ProgramTagFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramTagFilterMemo({
    selectedValues = null,
    onFilterChange,
    displayText = 'Term Tags',
    id,
  }) {
    return (
      <ProgramTagFilter
        id={id}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange(id, values);
        }}
        displayText={displayText}
      />
    );
  }),
);

exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";@-webkit-keyframes _2GUWW{to{-webkit-transform:rotate(1turn)}}@keyframes _2GUWW{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2LwS2{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._2LwS2 ._13KJl{margin:24px 40px}._2LwS2 ._13KJl p{margin-bottom:24px}._2LwS2 ._1YAvU{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;padding:12px 20px;margin:12px 40px}@media only screen and (max-width:599px){._2LwS2 ._1YAvU{-ms-flex-direction:column;flex-direction:column}}._2LwS2 ._1YAvU ._2xXHv{-ms-flex:1 1;flex:1 1;margin:12px 20px}._2LwS2 ._2kANR{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid  #ebebeb;margin:12px 40px;-ms-flex-pack:end;justify-content:flex-end}@media only screen and (max-width:599px){._2LwS2 ._2kANR{-ms-flex-direction:column;flex-direction:column}}._2LwS2 ._2kANR ._3VjiR{margin-left:12px}@media only screen and (max-width:599px){._2LwS2 ._2kANR ._3VjiR{margin-top:12px}}", ""]);

// exports
exports.locals = {
	"batch-update-message-recipient": "_2LwS2",
	"content": "_13KJl",
	"row": "_1YAvU",
	"item": "_2xXHv",
	"footer": "_2kANR",
	"button-content": "_3VjiR",
	"spin": "_2GUWW"
};
import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { getTags } from '../../../../../actions/tagsActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';
import { fetchProgramHousingTypes } from '../../../../../actions/lookupActions';

export const enhance = compose(withStyles(sFilterAccordion));

function ProgramHousingTypeFilter(props) {
  const dispatch = useDispatch();
  const programHousingTypes = useSelector(state => state.lookup.allProgramHousingTypes);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!programHousingTypes.programHousingTypes) {
        dispatch(fetchProgramHousingTypes());
      } else {
        let options = [];

        programHousingTypes.programHousingTypes.map(item => {
          if (item.attributes.name.length > 0 && item.attributes.name.toLowerCase() !== 'not sure') {
            options.push(item.attributes.name);
          }
        });

        const optionValues = buildSelectOptions(options, true, true);
        setSourceOptions(optionValues);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [programHousingTypes]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramHousingTypeFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ProgramHousingTypeFilter.defaultProps = {
  displayText: 'Housing Type',
  selectedValues: [],
};

export default enhance(ProgramHousingTypeFilter);

export const ProgramHousingTypeFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramHousingTypeFilterMemo({ selectedValues, onFilterChange }) {
    return (
      <ProgramHousingTypeFilter
        id={'preferred_program_housings'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('preferred_program_housings', values);
        }}
        displayText={'Preferred Housing'}
      />
    );
  }),
);

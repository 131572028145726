import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import { useSelector } from 'react-redux';

import sModal from 'react-responsive-modal/styles.css';
import SkipLink from '../navigation/SkipLink';
import DetailHeader from '../navigation/DetailHeader';

import { breakpoints } from '../../utils/breakpoints';
// import useSession from '../../../../hooks/useSession';
// import SessionExpireModal from '../../../../components/SessionExpireModal';

export const enhance = compose(withStyles(sModal));

const Container = styled.div`
  display: block;
  background-color: var(--theme_color_light);
`;

const DivPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.4375rem;
  min-height: 90vh;
  background-color: #ffffff;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.showSideNav || !props.isTraveler ? 'flex-end' : 'space-between')};
  align-items: center;
`;

const AccountNavContainer = styled.div`
  display: none;

  @media ${breakpoints.desktop} {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 2rem 0 1.5rem;
  }
`;

const ClearDiv = styled.div`
  clear: both;
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
`;

function DetailPageWrapper({ children, noPadding }) {
  // const { showSessionExpire, handleCloseSessionModal, countDownSeconds } = useSession();

  return (
    <>
      <SkipLink />
      <Container>
        <DetailHeader />
        <ClearDiv />
        <DivPageContainer>
          <ContentContainer>
            <Content id="main-container" noPadding={noPadding}>
              {children}
            </Content>
          </ContentContainer>
        </DivPageContainer>
      </Container>

      {/*{showSessionExpire && <SessionExpireModal show={showSessionExpire} handleClose={handleCloseSessionModal} countDownSeconds={countDownSeconds} />}*/}
    </>
  );
}

DetailPageWrapper.propTypes = {
  noPadding: PropTypes.bool,
  showSideNav: PropTypes.bool,
  routeSegment: PropTypes.string,
  layout: PropTypes.string,
  legacyWrapper: PropTypes.bool,
  showMenuDropdown: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isVisitor: PropTypes.bool,
};

DetailPageWrapper.defaultProps = {
  noPadding: false,
  routeSegment: '',
  layout: 'full',
  legacyWrapper: false,
  showSideNav: true,
  showMenuDropdown: true,
  isVisitor: false,
};

export default enhance(DetailPageWrapper);

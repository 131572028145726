exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1oX_V{to{-webkit-transform:rotate(1turn)}}@keyframes _1oX_V{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1kAfB .KXVth{margin-top:16px}", ""]);

// exports
exports.locals = {
	"alternatesMultiSelect": "_1kAfB",
	"typeOptions": "KXVth",
	"spin": "_1oX_V"
};
import React, { useState, useEffect, Suspense, lazy } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, FieldArray } from 'formik';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import InputText from '../../../../components/library/inputText';
import PlanTypeSelect from '../../selects/planTypeSelect';
import PlanStatusSelect from '../../selects/planStatusSelect';
import RegistrationStatusSelect from '../../selects/registrationStatusSelect';
import PlanAvailability from '../../selects/planAvailability';
import InputDate from '../../../../components/library/inputDate';
import InputTextArea from '../../../../components/library/inputTextArea';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../../sites/components/library/paragraph';
import PlanSuccess from './planSuccess';
import { addPlan, patchPlan, getAllPlans } from '../../../../../actions/plans';
import LocationSearch from '../../../../components/library/locationSearch';
import ViaIcon from '../../ViaIcon';
import sPlanForm from './planForm.scss';

const WysiwygEditor = lazy(() => import('../../../../components/library/wysiwygEditor'));

function PlanForm(props) {
  const dispatch = useDispatch();
  const planSubmit = useSelector(state => state.plans.addPatchPlan);
  const permissions = useSelector(state => state.profile.permissions);
  const [success, setSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newPlan, setNewPlan] = useState(null);
  const [showAddress, setShowAddress] = useState();
  const isEdit = !!(props.plan && props.plan.id);
  let counter = 0;

  const setSelected = values => {
    setShowAddress(values);
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let locs = [
      {
        id: '',
        formatted_address: 'test',
        google_place_id: '',
        country_alpha2_code: '',
        country_common_name: '',
        county_or_region: '',
        locality: '',
        postal_code: '',
        postal_code_suffix: '',
        state_or_province: '',
        state_or_province_code: '',
        street: '',
        street_number: '',
        lat: '',
        lng: '',
        time_zone: '',
        time_zone_offset: '',
        mapUrl: '',
        start: '',
        end: '',
        delete: false,
      },
    ];

    const getLocs = _get(props, 'plan.attributes.locations');
    if (getLocs && getLocs.length > 0) {
      locs = _get(props, 'plan.attributes.locations').map(loc => ({
        id: loc.id,
        formatted_address: loc.formatted_address,
        google_place_id: loc.google_place_id,
        country_alpha2_code: loc.country_alpha2_code,
        country_common_name: loc.country_common_name,
        county_or_region: loc.county_or_region,
        locality: loc.locality,
        postal_code: loc.postal_code,
        postal_code_suffix: loc.postal_code_suffix,
        state_or_province: loc.state_or_province,
        state_or_province_code: loc.state_or_province_code,
        street: loc.street,
        street_number: loc.street_number,
        lat: loc.lat,
        lng: loc.lng,
        time_zone: loc.time_zone,
        time_zone_offset: loc.time_zone_offset,
        mapUrl: `https://www.google.com/maps/search/?api=1&query=${loc.lat},${loc.lng}&query_place_id=${loc.google_place_id}`,
        start: moment(loc.start_date).format('YYYY-MM-DD'),
        end: moment(loc.end_date).format('YYYY-MM-DD'),
        delete: false,
      }));

      setSelected(locs);
    }

    return {
      name: _get(props, 'plan.attributes.name') || '',
      plan_type_id: _get(props, 'plan.attributes.plan_type_id') || '',
      plan_type_name: _get(props, 'plan.attributes.plan_type_name') || '',
      description: _get(props, 'plan.attributes.description') || '',
      private: _get(props, 'plan.attributes.private', true),
      private_name: _get(props, 'plan.attributes.private') === false ? 'Public' : 'Private',
      group: _get(props, 'plan.attributes.group', props.isGroup),
      removed: _get(props, 'plan.attributes.removed') || false,
      plan_registration_status_id: _get(props, 'plan.attributes.plan_registration_status_id') || 1,
      plan_registration_status_name: _get(props, 'plan.attributes.plan_registration_status_name') || 'Open',
      plan_status_id: _get(props, 'plan.attributes.plan_status_id') || 1,
      plan_status_name: _get(props, 'plan.attributes.plan_status_name') || 'Registered',
      notes: _get(props, 'plan.attributes.notes') || '',
      locations: locs,
    };
  });

  useEffect(() => {
    if (planSubmit) {
      if (planSubmit.loading) {
        setSaving(true);
      }

      if (saving && !!planSubmit.plan) {
        if (isEdit) {
          setSaving(false);
          props.onClose(false);
        } else {
          dispatch(getAllPlans());
          setNewPlan(planSubmit.plan);
          setSuccess(true);
          setSaving(false);
        }
      }

      if (saving && !!planSubmit.error) {
        setSaving(false);
      }
    }
  }, [planSubmit]);

  const customStyles = {
    placeholder: provided => ({
      ...provided,
      color: 'black',
    }),
  };

  return (
    <>
      {success ? (
        <PlanSuccess
          plan={newPlan}
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <div className={sPlanForm['create-plan']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};
              values.description = values.description.trim();
              if (!values.name) {
                errors.name = 'Plan Name Required';
              }
              if (!values.plan_type_id) {
                errors.plan_type_id = 'Plan Type Required';
              }
              if (!values.plan_registration_status_id) {
                errors.plan_registration_status_id = 'Registration Status Required';
              }
              if (!values.plan_status_id) {
                errors.plan_status_id = 'Plan Status Required';
              }
              values.locations.map((loc, index) => {
                if (!loc.delete) {
                  if (!loc.formatted_address || !loc.country_alpha2_code) {
                    errors[`locations[${index}].location`] = 'Address must include at least a city and country';
                  }
                  if (!loc.start) {
                    errors[`locations[${index}].start`] = 'Start Date Required';
                  }
                  if (!loc.end) {
                    errors[`locations[${index}].end`] = 'End Date Required';
                  }
                  if (loc.start && moment(loc.end).isBefore(moment(loc.start), 'day')) {
                    errors[`locations[${index}].end`] = 'End Date must be after Start Date';
                  }
                }
              });

              return errors;
            }}
            onSubmit={values => {
              const filteredList = values.locations.filter(l => !!l.id || !l.delete);
              const locs = filteredList.map(loc => {
                if (loc.delete) {
                  return {
                    id: loc.id,
                    _destroy: true,
                  };
                } else {
                  return {
                    id: loc.id,
                    formatted_address: loc.formatted_address,
                    google_place_id: loc.google_place_id,
                    country_alpha2_code: loc.country_alpha2_code,
                    country_common_name: loc.country_common_name,
                    county_or_region: loc.county_or_region,
                    locality: loc.locality,
                    postal_code: loc.postal_code,
                    postal_code_suffix: loc.postal_code_suffix,
                    state_or_province: loc.state_or_province,
                    state_or_province_code: loc.state_or_province_code,
                    street: loc.street,
                    street_number: loc.street_number,
                    lat: loc.lat,
                    lng: loc.lng,
                    time_zone: loc.time_zone,
                    time_zone_offset: loc.time_zone_offset,
                    mapUrl: loc.mapUrl,
                    start_date: loc.start,
                    end_date: loc.end,
                    delete: false,
                  };
                }
              });

              const submitValues = {
                name: values.name,
                description: values.description,
                group: values.group,
                plan_type_id: Number(values.plan_type_id),
                private: values.private,
                removed: values.removed,
                locations_attributes: locs,
                plan_registration_status_id: Number(values.plan_registration_status_id),
                plan_status_id: Number(values.plan_status_id),
                notes: values.notes,
              };

              if (isEdit) {
                dispatch(patchPlan(props.plan.id, submitValues));
              } else {
                dispatch(addPlan(submitValues));
              }
            }}
          >
            {({ values, handleSubmit, errors, setFieldValue, handleChange, validateForm }) => (
              <Form>
                <div className={sPlanForm.subHeader}>
                  <Paragraph italic centered>
                    Please complete all required fields
                  </Paragraph>
                </div>
                <div className={sPlanForm.row}>
                  <div className={sPlanForm.item}>
                    <Field name="name">
                      {({ form }) => (
                        <InputText
                          id="name"
                          name="name"
                          labelText="Plan Name"
                          helpText=""
                          errorMessage={errors.name}
                          placeholder="What is the name of this plan?"
                          isRequired
                          value={values.name}
                          onChange={e => form.setFieldValue('name', e.target.value)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sPlanForm.row}>
                  <div className={sPlanForm.item}>
                    <Field name="plan_type_id">
                      {({ field, form }) => (
                        <PlanTypeSelect
                          labelText="Plan Type"
                          isRequired
                          id="plan_type_id"
                          name="plan_type_id"
                          styles={customStyles}
                          defaultValue={
                            values.plan_type_name
                              ? { value: values.plan_type_id, label: values.plan_type_name }
                              : { value: '', label: 'Select the Plan Type' }
                          }
                          errorMessage={errors.plan_type_id}
                          onChange={e => {
                            form.setFieldValue('plan_type_id', e.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sPlanForm.item}>
                    <Field name="private">
                      {({ field, form }) => (
                        <PlanAvailability
                          labelText="Plan Availability"
                          isRequired
                          id="private"
                          name="private"
                          styles={customStyles}
                          isDisabled={!props.isGroup}
                          errorMessage={errors.private}
                          defaultValue={{ value: defaultValues.private, label: defaultValues.private_name }}
                          onChange={e => {
                            form.setFieldValue('private', e.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sPlanForm.row}>
                  <div className={sPlanForm.item}>
                    <Field name="plan_status_id">
                      {({ field, form }) => (
                        <PlanStatusSelect
                          labelText="Plan Status"
                          isRequired
                          id="plan_status_id"
                          name="plan_status_id"
                          styles={customStyles}
                          isDisabled={
                            props.as === 'traveler' ||
                            (props.as !== 'traveler' &&
                              !permissions.travel_plans_settings.includes('edit_plan_and_registration_status'))
                          }
                          defaultValue={{ value: defaultValues.plan_status_id, label: defaultValues.plan_status_name }}
                          errorMessage={errors.plan_status_id}
                          onChange={e => {
                            form.setFieldValue('plan_status_id', e.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sPlanForm.item}>
                    <Field name="plan_registration_status_id">
                      {({ field, form }) => (
                        <RegistrationStatusSelect
                          labelText="Registration Status"
                          isRequired
                          id="plan_registration_status_id"
                          name="plan_registration_status_id"
                          styles={customStyles}
                          defaultValue={{
                            value: defaultValues.plan_registration_status_id,
                            label: defaultValues.plan_registration_status_name,
                          }}
                          isDisabled={
                            props.as !== 'traveler' &&
                            !permissions.travel_plans_settings.includes('edit_plan_and_registration_status')
                          }
                          errorMessage={errors.plan_registration_status_id}
                          onChange={e => {
                            form.setFieldValue('plan_registration_status_id', e.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                {props.as === 'traveler' ? null : (
                  <div className={sPlanForm.row}>
                    <div className={sPlanForm.item}>
                      <Field name="end_date">
                        {({ field, form }) => (
                          <InputTextArea
                            id="notes"
                            name="notes"
                            labelText="Plan Notes (Admin Only)"
                            value={values.notes}
                            onChange={e => {
                              form.setFieldValue('notes', e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )}
                <FieldArray name="locations">
                  {({ push }) => (
                    <>
                      {values.locations.map((loc, index) => {
                        if (index === 0) {
                          counter = 0;
                        }
                        if (!loc.delete) {
                          counter++;
                          return (
                            <div key={`loc-${index}`} className={sPlanForm['address-block']}>
                              <div className={sPlanForm.underlinedRow}>
                                <div className={sPlanForm.item}>
                                  <Paragraph size="small" bold>
                                    {values.locations.length > 1 ? `${counter}. ` : ''}
                                    LOCATION
                                    {index > 0 ? (
                                      <span
                                        className={sPlanForm.trash}
                                        onClick={() => {
                                          loc.delete = true;
                                          validateForm();
                                        }}
                                      >
                                        <ViaIcon name="trash" color="darkText" size="xsmall" />
                                      </span>
                                    ) : null}
                                  </Paragraph>
                                </div>
                              </div>
                              <div className={sPlanForm.row}>
                                <div className={sPlanForm.item}>
                                  <InputDate
                                    labelText="Start Date"
                                    errorMessage={errors[`locations[${index}].start`]}
                                    name={`locations[${index}].start`}
                                    isRequired
                                    withPortal={false}
                                    styles={loc.start ? customStyles : null}
                                    placeholder={moment(loc.start).isValid() ? loc.start : 'Select Date...'}
                                    onChange={e => {
                                      loc.start = moment(e).format('YYYY-MM-DD');
                                    }}
                                  />
                                </div>
                                <div className={sPlanForm.item}>
                                  <InputDate
                                    labelText="End Date"
                                    errorMessage={errors[`locations[${index}].end`]}
                                    name={`locations[${index}].end`}
                                    isRequired
                                    withPortal={false}
                                    styles={loc.end ? customStyles : null}
                                    placeholder={moment(loc.end).isValid() ? loc.end : 'Select Date...'}
                                    onChange={e => {
                                      loc.end = moment(e).format('YYYY-MM-DD');
                                    }}
                                  />
                                </div>
                              </div>
                              <div className={sPlanForm.row}>
                                <div className={sPlanForm.item}>
                                  <Field name="location">
                                    {({ form }) => (
                                      <LocationSearch
                                        id={`locations[${index}].location`}
                                        name={`locations[${index}].location`}
                                        isRequired
                                        labelText="City and Country Search"
                                        placeholder="Type in City and Country..."
                                        selectHandler={e => {
                                          if (!e?.loading && e?.formatted_address && e?.google_place_id) {
                                            loc.formatted_address = e.formatted_address;
                                            loc.google_place_id = e.google_place_id;
                                            loc.country_alpha2_code = e.country_alpha2_code;
                                            loc.country_common_name = e.country_common_name;
                                            loc.county_or_region = e.county_or_region;
                                            loc.locality =
                                              e.locality.length === 0
                                                ? loc.formatted_address.split(',')[0]
                                                : e.locality;
                                            loc.postal_code = e.postal_code;
                                            loc.postal_code_suffix = e.postal_code_suffix;
                                            loc.state_or_province = e.state_or_province;
                                            loc.state_or_province_code = e.state_or_province_code;
                                            loc.street = e.street;
                                            loc.street_number = e.street_number;
                                            loc.lat = e.lat.toString();
                                            loc.lng = e.lng.toString();
                                            loc.time_zone = e.time_zone;
                                            loc.time_zone_offset = e.time_zone_offset;
                                            (loc.mapUrl = e.mapUrl), setSelected(values.locations);
                                            validateForm();
                                          }
                                        }}
                                        errorMessage={errors[`locations[${index}].location`]}
                                        value={loc.location}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div className={sPlanForm.item}>
                                  <Paragraph size="small" bold>
                                    Selected City and Country
                                  </Paragraph>
                                  {!showAddress || !showAddress[index] ? (
                                    <Paragraph size="small" italic>
                                      Please search for your housing address.
                                    </Paragraph>
                                  ) : (
                                    <>
                                      <Paragraph size="small">
                                        Address:{' '}
                                        {showAddress[index].locality && showAddress[index].country_common_name ? `${showAddress[index].locality}, ${showAddress[index].country_common_name}` : `${showAddress[index].formatted_address}`}
                                      </Paragraph>
                                      <Paragraph size="small">Timezone: {showAddress[index].time_zone}</Paragraph>
                                      <Paragraph size="small">
                                        <a href={showAddress[index].mapUrl} rel="noopener noreferrer" target="_blank">
                                          See it on a map
                                        </a>
                                      </Paragraph>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <div className={sPlanForm.addBlock}>
                        <div className={sPlanForm.addBlockContent}>
                          <span
                            className={sPlanForm.addLoc}
                            onClick={() =>
                              push({
                                id: '',
                                formatted_address: '',
                                google_place_id: '',
                                country_alpha2_code: '',
                                country_common_name: '',
                                county_or_region: '',
                                locality: '',
                                postal_code: '',
                                postal_code_suffix: '',
                                state_or_province: '',
                                state_or_province_code: '',
                                street: '',
                                street_number: '',
                                lat: '',
                                lng: '',
                                time_zone: '',
                                time_zone_offset: '',
                                mapUrl: '',
                                start: '',
                                end: '',
                                delete: false,
                              })
                            }
                          >
                            + Add Another City, Country, Start & End Date
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>
                <div className={sPlanForm.row}>
                  <div className={sPlanForm.item}>
                    <Paragraph size="small" bold>
                      Description
                    </Paragraph>
                    <Suspense fallback={<h1>Still Loading…</h1>}>
                      <Field
                        value={values.description}
                        id="description"
                        name="description"
                        component={WysiwygEditor}
                        setFieldValue={setFieldValue}
                      />
                    </Suspense>
                  </div>
                </div>

                <div className={sPlanForm['button-row']}>
                  <div className={sPlanForm['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sPlanForm['button-content']}>
                    <Button display="primary" kind="solid" size="medium" type="submit" loading={saving}>
                      {isEdit ? 'Save Plan' : props.isGroup ? 'Save Group Plan' : 'Save New Plan'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

PlanForm.propTypes = {
  onClose: propTypes.func,
  isGroup: propTypes.bool,
  as: propTypes.string.isRequired,
};

PlanForm.defaultProps = {
  isGroup: false,
};

export default withStyles(sPlanForm)(PlanForm);

exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2pzWq{to{-webkit-transform:rotate(1turn)}}@keyframes _2pzWq{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.rX0Xd{-ms-flex-direction:column;flex-direction:column;width:100%}.rX0Xd,.rX0Xd ._20Uzr{display:-ms-flexbox;display:flex}.rX0Xd ._20Uzr{-ms-flex-direction:row;flex-direction:row;padding-bottom:24px}.rX0Xd ._20Uzr ._19PK9{-ms-flex:1 1;flex:1 1;display:-ms-inline-flexbox;display:inline-flex}.rX0Xd ._20Uzr ._19PK9 svg{padding-right:12px;cursor:pointer;margin:4px}.rX0Xd ._20Uzr ._3UfN7{width:324px;z-index:1000}.rX0Xd .VjCD8{z-index:1;left:0;top:0;width:100%;height:100%;overflow:hidden;background-position:50%;background-size:cover;-moz-text-align-last:center;text-align-last:center;padding:16px 0;margin:12px 0}.rX0Xd .VjCD8 img{-o-object-fit:contain;object-fit:contain;width:100%;height:auto}.rX0Xd ._1QoGw{margin:12px 0}.rX0Xd ._1QoGw p{padding:12px 0}.rX0Xd ._3KX-6{-ms-flex:1 1;flex:1 1;padding-bottom:24px}.rX0Xd ._3KX-6 ._30t8A{padding-top:12px}.rX0Xd ._2NJ-j{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.rX0Xd ._2NJ-j ._1i7UK{-ms-flex:1 1;flex:1 1;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;border-bottom:2px solid #ebebeb;padding:16px 0}.rX0Xd ._2NJ-j ._1i7UK ._27Vvi img{height:60px;width:60px;background-position:50%;background-size:cover;border-radius:50%;-o-object-fit:cover;object-fit:cover}.rX0Xd ._2NJ-j ._1i7UK ._2_Z-Z{width:350px;overflow-wrap:break-word}.rX0Xd ._2NJ-j ._1i7UK ._1LCj1,.rX0Xd ._2NJ-j ._1i7UK ._2_Z-Z{padding-left:24px;-ms-flex-item-align:center;align-self:center}.rX0Xd ._2NJ-j ._1i7UK ._1LCj1{width:200px}.rX0Xd ._2NJ-j ._1i7UK .qQEyU{padding-left:24px;-ms-flex-item-align:center;align-self:center}.rX0Xd ._2NJ-j ._1i7UK ._2X9lN{text-decoration:underline;cursor:pointer}.rX0Xd ._2NJ-j .LXuY4{-ms-flex:1 1;flex:1 1}.rX0Xd ._2NJ-j ._2caZY{padding:52px 0}.rX0Xd ._2NJ-j ._2caZY ._1Xe6L{text-decoration:underline;cursor:pointer}", ""]);

// exports
exports.locals = {
	"ov-travelers": "rX0Xd",
	"traveler-type-header": "_20Uzr",
	"traveler-view-type-options": "_19PK9",
	"ov-travelers-action-select": "_3UfN7",
	"background-image": "VjCD8",
	"no-travelers-container": "_1QoGw",
	"ov-travelers-headline": "_3KX-6",
	"ov-travelers-headline-counts": "_30t8A",
	"ov-travelers-traveler-wrapper": "_2NJ-j",
	"ov-travelers-traveler": "_1i7UK",
	"ov-travelers-traveler-image": "_27Vvi",
	"ov-travelers-traveler-info": "_2_Z-Z",
	"ov-travelers-traveler-counts": "_1LCj1",
	"ov-travelers-traveler-link": "qQEyU",
	"ov-travelers-traveler-link-content": "_2X9lN",
	"ov-travelers-table": "LXuY4",
	"add-travelers-link-wrapper": "_2caZY",
	"add-link": "_1Xe6L",
	"spin": "_2pzWq"
};
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputSelect from '../../../library/inputSelect';
import InputText from '../../../library/inputText';
import sFilterDurationRange from './filterDurationRange.scss';
import _isEqual from 'lodash/isEqual';

export const enhance = compose(withStyles(sFilterDurationRange));

function FilterDurationRange(props) {
  const [loading, setLoading] = useState(true);
  const [rangeType, setRangeType] = useState(props.selectedValues[0] || 'is');
  const [durationType, setDurationType] = useState(props.selectedValues[1] || 'week');
  const [singleDurationValue, setSingleDurationValue] = useState(props.selectedValues[2]);
  const [fromDurationValue, setFromDurationValue] = useState(props.selectedValues[3]);
  const [toDurationValue, setToDurationValue] = useState(props.selectedValues[4]);
  const [defaultIndex, setDefaultIndex] = useState();
  const [defaultDurationIndex, setDefaultDurationIndex] = useState();
  const typeOptions = [
    {value: 'is', label: 'Is'},
    {value: 'is_between', label: 'Is between (inclusive)'},
    {value: 'is_greater', label: 'Is greater than or equal to'},
    {value: 'is_less', label: 'Is less than or equal to'}
  ];
  const durationOptions = [
    {value: 'week', label: 'Week(s)'},
    {value: 'day', label: 'Day(s)'},
    {value: 'month', label: 'Months(s)'},
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.selectedValues[0]) {
      let defaultIndexValue = typeOptions.find(item => item.value === props.selectedValues[0]);
      if (!defaultIndexValue || defaultIndexValue === undefined) {
        defaultIndexValue = typeOptions[0];
      }

      setDefaultIndex(defaultIndexValue);

      let defaultDurationIndexValue = durationOptions.find(item => item.value === props.selectedValues[1]);
      if (!defaultDurationIndexValue || defaultDurationIndexValue === undefined) {
        defaultDurationIndexValue = durationOptions[0];
      }

      setDefaultDurationIndex(defaultDurationIndexValue);
      setRangeType(props.selectedValues[0]);
      setSingleDurationValue(props.selectedValues[2]);
      setFromDurationValue(props.selectedValues[3]);
      setToDurationValue(props.selectedValues[4]);
    } else {
      let defaultIndexValue = typeOptions.find(item => item.value === rangeType);
      setDefaultIndex(defaultIndexValue);
      let defaultDurationIndexValue = durationOptions.find(item => item.value === durationType);
      setDefaultDurationIndex(defaultDurationIndexValue);
      setSingleDurationValue('');
      setFromDurationValue('');
      setToDurationValue('');
    }
  }, [props.selectedValues]);

  useEffect(() => {
    let filterValues = [];
    let saveType = rangeType;
    let saveDurationType = durationType;

    if (typeof saveType === 'object') {
      saveType = typeOptions[0].value;
    }

    if (typeof saveDurationType === 'object') {
      saveDurationType = durationOptions[0].value;
    }

    if (saveType === 'is_between') {
      filterValues.push(saveType);
      filterValues.push(saveDurationType);
      filterValues.push('');
      filterValues.push(fromDurationValue ? fromDurationValue.toString() : '');
      filterValues.push(toDurationValue ? toDurationValue.toString() : '');
    } else {
      filterValues.push(saveType);
      filterValues.push(saveDurationType);
      filterValues.push(singleDurationValue ? singleDurationValue.toString() : '');
      filterValues.push('');
      filterValues.push('');
    }

    if (!loading) {
      if (!_isEqual(filterValues, props.selectedValues)) {
        props.change(filterValues);
      }
    }
  }, [rangeType, durationType, singleDurationValue, fromDurationValue, toDurationValue]);

  return (
    <div className={sFilterDurationRange.durationRangeFilter}>
      <InputSelect
        id="rangeType"
        labelText=""
        styles={{
          menu: provided => ({ ...provided, zIndex: 99999 })
        }}
        options={typeOptions}
        onChange={(e) => {
          setRangeType(e.value);
        }}
        defaultValue={defaultIndex}
        value={defaultIndex}
        labelSize="normal"
      />

      {rangeType && rangeType !=='is_between' && (
        <div className={sFilterDurationRange.singleValue}>
          <div className={sFilterDurationRange.singleValue}>
            <InputText
              type="number"
              step="0.1"
              placeholder=""
              value={singleDurationValue || ''}
              labelText="Number"
              id="singleDuration"
              name="singleDuration"
              size="normal"
              labelSize="normal"
              onChange={e => {setSingleDurationValue(e.target.value)}}
            />
          </div>
        </div>
      )}

      {rangeType && rangeType ==='is_between' && (
        <div className={sFilterDurationRange.rangeValues}>
          <div className={sFilterDurationRange.rangeValue}>
            <InputText
              type="number"
              step="0.1"
              placeholder=""
              value={fromDurationValue || ''}
              labelText="Number"
              id="fromDuration"
              name="fromDuration"
              size="normal"
              labelSize="normal"
              onChange={e => {setFromDurationValue(e.target.value) }}
            />
          </div>
          <span className={sFilterDurationRange.separator}>and</span>
          <div className={sFilterDurationRange.rangeValue}>
            <InputText
              type="number"
              step="0.1"
              placeholder=""
              value={toDurationValue || ''}
              labelText="Number"
              id="toDuration"
              name="toDuration"
              size="normal"
              labelSize="normal"
              onChange={e => {setToDurationValue(e.target.value)}}
            />
          </div>
        </div>
      )}
      <div className={sFilterDurationRange.timeframe}>
        <InputSelect
          id="durationRange"
          labelText="Timeframe"
          styles={{
            menu: provided => ({ ...provided, zIndex: 99999 })
          }}
          options={durationOptions}
          onChange={(e) => {
            setDurationType(e.value);
          }}
          value={defaultDurationIndex}
          defaultValue={defaultDurationIndex}
          labelSize="normal"
        />
      </div>

    </div>
  );
}


FilterDurationRange.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

FilterDurationRange.defaultProps = {
  displayText: 'Filter Options',
  selectedValues: [],
};

export default enhance(FilterDurationRange);

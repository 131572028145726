import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputSelect from '../../../library/inputSelect';
import sStyle from './filterQuestionCompletedSelect.scss';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
export const enhance = compose(withStyles(sStyle));

function FilterQuestionCompletedSelect(props) {
  const isInitialMount = useRef(true);
  const [defaultValues, setDefaultValues] = useState([]);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState(props.selectedValues[0]);
  const [selectedCheckboxOption, setSelectedCheckboxOption] = useState(props.selectedValues[1]);
  const [answerOptions, setAnswerOptions] = useState([
    { label: 'Completed', value: 'Completed', selected: false },
    { label: 'Not Completed', value: 'Not Completed', selected: false },
  ]);

  useEffect(
    () => {
      if (props.clearValues) {
        setDefaultValues([]);
        setAnswerOptions([
          { label: 'Completed', value: 'Completed', selected: false },
          { label: 'Not Completed', value: 'Not Completed', selected: false },
        ]);
      } else {
        let defaultSelect = props.selectedValues[0];
        if (defaultSelect) {
          let val = parseInt(defaultSelect, 10);
          let obj = props.sourceData.find(o => o.value === val);
          setDefaultValues(obj);
        }

        let checkedVal = props.selectedValues[1];
        let options = _cloneDeep(answerOptions);

        if (checkedVal) {
          if (checkedVal[0] === 'Completed') {
            options[0].selected = true;
          }
          if (checkedVal[0] === 'Not Completed') {
            options[1].selected = true;
          }
        } else {
          options[0].selected = false;
          options[1].selected = false;
        }

        setAnswerOptions(options);
      }
    },
    [props],
  );

  const selectedValues = values => {
    let filterValues = [values.value];
    setDefaultValues(values);
    setSelectedDropdownValues(filterValues);
  };

  const changeCheckValue = value => {
    const answerSelected = selectedDropdownValues && selectedDropdownValues.length > 0;
    const options = answerOptions;
    const i = options.findIndex(item => item.value === value.target.value);

    if (answerSelected) {
      options[i].selected = value.target.checked;
    }

    let filterValues = [];
    if (options && options.length > 0) {
      options.forEach(item => {
        if (item.selected) {
          filterValues.push(item.value);
        }
      });
    }

    setAnswerOptions(options);
    setSelectedCheckboxOption(filterValues);
    props.change(filterValues);
  };

  useEffect(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        let filterValues = [];
        if (selectedDropdownValues && selectedDropdownValues.length > 0) {
          filterValues[0] = selectedDropdownValues;
        }
        if (selectedCheckboxOption && selectedCheckboxOption.length > 0) {
          filterValues[1] = selectedCheckboxOption;
        }

        if (!_isEqual(filterValues, props.selectedValues)) {
          props.change(filterValues);
        }

        return () => {};
      }
    },
    [selectedDropdownValues, selectedCheckboxOption],
  );

  return (
    <div className={sStyle.selectWrapper}>
      <InputSelect
        id={props.id}
        labelText={props.labelText}
        styles={{
          menu: provided => ({ ...provided, zIndex: 99999 }),
        }}
        options={props.sourceData}
        onChange={e => selectedValues(e)}
        defaultValue={defaultValues}
        value={defaultValues}
        labelSize="normal"
      />

      <div className={sStyle.checkOptions}>
        {answerOptions &&
          answerOptions.map(opt => {
            return (
              <label className={sStyle.container} htmlFor={opt.value} key={opt.value}>
                <input
                  type="checkbox"
                  name={opt.value}
                  value={opt.value}
                  aria-label={opt.value}
                  checked={opt.selected}
                  onChange={e => changeCheckValue(e)}
                />
                <span className={sStyle.checkmark} />
                <span className={sStyle.label}>{opt.label}</span>
              </label>
            );
          })}
      </div>
    </div>
  );
}

FilterQuestionCompletedSelect.propTypes = {
  id: propTypes.string.isRequired,
  change: propTypes.func.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
  clearValues: propTypes.bool,
};

FilterQuestionCompletedSelect.defaultProps = {
  labelText: 'Question Completed',
  selectedValues: [],
  clearValues: false,
};

export default enhance(FilterQuestionCompletedSelect);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import _isEqual from 'lodash/isEqual';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputSelect from '../../../library/inputSelect';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import sFilterMultiSelectWithIs from './filterMultiSelectWithIs.scss';

export const enhance = compose(withStyles(sFilterMultiSelectWithIs));

function FilterMultiSelectWithIs(props) {
  const [defaultIsIndex, setDefaultIsIndex] = useState();
  const [selectedOptionValue, setSelectedOptionValue] = useState([]);
  const [isType, setIsType] = useState(props.selectedValues[0] || 'is');
  const [loading, setLoading] = useState(true);
  const isOptions = [
    {label: 'Is', value: 'is'},
    {label: 'Is Not', value: 'is not'},
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.selectedValues[0]) {
      let defaultIndexValue = isOptions.find(item => item.value === props.selectedValues[0]);
      if (!defaultIndexValue || defaultIndexValue === undefined) {
        defaultIndexValue = isOptions[0];
      }
      setIsType(props.selectedValues[0]);
      setDefaultIsIndex(defaultIndexValue);

    } else {
      let defaultIndexValue = isOptions.find(item => item.value === isType);
      setDefaultIsIndex(defaultIndexValue);
    }

    if (props.selectedValues[1] && props.selectedValues[1].length > 0) {
      const buildDefaultOptionValue = buildSelectOptions(props.selectedValues[1], true, true);
      setSelectedOptionValue(buildDefaultOptionValue);
    } else {
      setSelectedOptionValue([]);
    }

  }, [props.selectedValues]);


  useEffect(() => {
    let savedFilter = props.selectedValues;
    let filterValues = [];
    let filterTermValues = [];
    let saveType = isType;

    if (typeof saveType === 'object') {
      saveType = isOptions[0].value;
    }

    if (!!selectedOptionValue && selectedOptionValue.length > 0 && selectedOptionValue[0] !== null && selectedOptionValue[0] !== undefined) {
      selectedOptionValue.forEach((item) => {
        filterTermValues.push(item.value);
      });
    }

    filterValues.push(saveType);
    filterValues.push(filterTermValues);

    if (savedFilter.length !== 2) {
      savedFilter = [];
      savedFilter.push('is');
      savedFilter.push([]);
    }

    if (!loading) {

      filterValues[1].sort();
      savedFilter[1].sort();

      if (!_isEqual(filterValues, savedFilter)) {
        props.change(filterValues);
      }
    }
  }, [isType, selectedOptionValue]);

  return (
    <div className={sFilterMultiSelectWithIs.multiSelectWithIs}>
      <InputSelect
        defaultValue={defaultIsIndex}
        id={'isOptions'}
        labelText={''}
        labelSize="normal"
        options={isOptions}
        onChange={e => {
          setIsType(e.value);
        }}
        styles={{
          menu: provided => ({...provided, zIndex: 99999})
        }}
        value={defaultIsIndex}
      />

      <div className={sFilterMultiSelectWithIs.options}>
        <InputSelect
          defaultValue={selectedOptionValue}
          id={'opt-select'}
          isMulti
          labelText={''}
          labelSize="normal"
          options={props.sourceData}
          onChange={e => {
            setSelectedOptionValue(e);
          }}
          styles={{
            menu: provided => ({...provided, zIndex: 99999})
          }}
          value={selectedOptionValue}
        />
      </div>

    </div>
  );
}

FilterMultiSelectWithIs.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  sourceData: propTypes.array.isRequired,
  selectedValues: propTypes.array,
};

FilterMultiSelectWithIs.defaultProps = {
  labelText: '',
  selectedValues: [],
};

export default enhance(FilterMultiSelectWithIs);

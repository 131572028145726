import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { fetchRaceOptions } from '../../../../../actions/lookupActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function ApplicationRaceOptionsFilter(props) {
  const dispatch = useDispatch();
  const dataOptions = useSelector(state => state.lookup.allRaceOptions);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!dataOptions.raceOptions) {
        dispatch(fetchRaceOptions());
      } else {
        let options = [];

        dataOptions.raceOptions.map(item => {
          options.push(item);
        });

        const optionValues = buildSelectOptions(options, false, true);
        const predefined = [
          { label: 'Not Requested', value: 'Not Requested' },
          { label: 'Not Provided', value: 'Not Provided' },
        ];

        const groupOptions = [
          { label: '', options: predefined },
          { label: '', options: optionValues },
        ];

        if (!props.hidePresetValues) {
          setSourceOptions(groupOptions);
        } else {
          setSourceOptions(optionValues);
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, [dataOptions]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ApplicationRaceOptionsFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  hidePresetValues: propTypes.bool,
};

ApplicationRaceOptionsFilter.defaultProps = {
  displayText: 'Race',
  selectedValues: [],
  hidePresetValues: false,
};

export default enhance(ApplicationRaceOptionsFilter);

export const ApplicationRaceOptionsFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ApplicationRaceOptionsFilterMemo({
    selectedValues = null,
    onFilterChange,
    hidePresetValues = false,
  }) {
    return (
      <ApplicationRaceOptionsFilter
        id={'race'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('race', values);
        }}
        hidePresetValues={hidePresetValues}
      />
    );
  }),
);

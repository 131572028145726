import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import GenericStyledModal from './GenericStyledModal.modal';
import { WarningIcon } from '../../../../../styledComponents/styles/IconCustom.styled';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
`;

const IconWrapper = styled.div`
  align-self: center;
  padding-left: 34px;
`;

const ParagraphWrapper = styled.div`
  margin-left: 45px;
  margin-right: 37px;
`;

const Paragraph = styled.p`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const ParagraphWarning = styled(Paragraph)`
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  background: #ebebeb;
  display: flex;
  height: 111px;
  justify-content: center;
  left: 0;
  margin-top: 43px;
  top: 273px;
  width: 520px;
  margin-bottom: -20px;
  border-radius: 15px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;

export default function DeleteFormModal(props) {

  return (
    <GenericStyledModal
      header={`Delete ${props.status} ${props.submissionType}`}
      showing={props.showing}
      width='520px'
      onClose={() => {
        props.onClose();
      }}
    >
      <Container>
        <Content>
          <IconWrapper>
            <WarningIcon size='100px' />
          </IconWrapper>
          <ParagraphWrapper>
            <Paragraph>{`You are about to delete a ${props.status} ${props.submissionType}. This action cannot be undone.`}</Paragraph>
            <ParagraphWarning>{`Do you want to delete ${props.deleteModalData.name} and all its content?`}</ParagraphWarning>
          </ParagraphWrapper>
        </Content>
        <ButtonContainer>
          <ButtonDiv>
            <ButtonMediumNunito
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </ButtonMediumNunito>
          </ButtonDiv>
          <ButtonDiv>
            <AccentButtonMediumNunito
              onClick={() => {
                props.deleteAction(props.deleteModalData);
              }}
            >
              Delete Form
            </AccentButtonMediumNunito>
          </ButtonDiv>
        </ButtonContainer>
      </Container>
    </GenericStyledModal>
  );
}

DeleteFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  deleteModalData: PropTypes.object.isRequired,
  submissionType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Formik, Form, Field } from 'formik';
//import getWithdrawalOptions from '../../../../travelerProfile/utils/options/withdrawalOptions';
import { reasonOptions } from '../../../../../helpers';
import {
  updateApplicationStatuses,
  getAppStatusOptions,
  fetchFormsReport,
} from '../../../../../actions/reportsActions';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import InputSelect from '../../../../components/library/inputSelect';
import Paragraph from '../../../../components/library/paragraph';
import ToastMessage from '../../toastMessage';
import sBatchUpdateApplicationStatus from './batchUpdateApplicationStatus.scss';
import InputText from '../../../../components/library/inputText';

export const enhance = compose(withStyles(sBatchUpdateApplicationStatus));

function BatchUpdateFormApplicationStatus(props) {
  const dispatch = useDispatch();

  const [submitValues, setSubmitValues] = useState();
  const [showConfirm, setShowConfirm] = useState(false);

  const applicationStatus = useSelector(state => state.reports.updateApplicationStatus);
  const statusOptions = useSelector(state => state.reports.fetchAppStatusOptions);
  const permissions = useSelector(state => state.profile.permissions);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [appStatusOptions, setAppStatusOptions] = useState(null);
  const withdrawReasons = reasonOptions(props.customAliases?.alias_program ? props.customAliases?.alias_program : "Program");

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        status: '',
        withdrawalSelected: [],
        withdrawReason: '',
      };
      return values;
    });
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      status: '',
      withdrawalSelected: [],
      withdrawReason: '',
    };
    return values;
  });

  useEffect(() => {
    dispatch(getAppStatusOptions());
  }, []);

  useEffect(() => {
    if (statusOptions?.data && !statusOptions?.error && !statusOptions.loading) {
      let options = [];

      statusOptions?.data.forEach(obj => {
        let disable = false;
        if (
          (obj.attributes.name.toLowerCase() === 'deferred' &&
            !permissions.traveler_applications_and_forms_settings.includes('defer_on_behalf_of_traveler')) ||
          (obj.attributes.name.toLowerCase() === 'withdrawn' &&
            !permissions.traveler_applications_and_forms_settings.includes('withdraw_on_behalf_of_traveler'))
        ) {
          disable = true;
        }

        options.push({
          value: obj.id,
          label: obj.attributes.name,
          isDisabled: disable,
        });
      });

      setAppStatusOptions(options);
    }
  }, [statusOptions, permissions]);

  useEffect(() => {
    if (applicationStatus) {
      if (applicationStatus.loading) {
        setSaving(true);
      }

      if (!applicationStatus.loading && !!applicationStatus.data) {
        if (applicationStatus.data) {
          setSaving(false);
          setSuccessMessage(applicationStatus.data);
          setSuccess(true);
        }
        if (applicationStatus.error) {
          setSaving(false);
          setFailureMessage(applicationStatus.error);
          setFailure(true);
        }
      }
    }
    resetForm();
    props.onClose(false);
  }, [applicationStatus]);

  return (
    <>
      <ViaModal
        showing={props.show}
        width="700px"
        onClose={() => {
          props.onClose(false);
        }}
        headerText="Update Status"
      >
        <div className={sBatchUpdateApplicationStatus['batch-update-form-status']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              if (values.status === '') {
                errors.status = 'Status required';
              }

              if (
                (values.status.label === 'Withdrawn' && values.withdrawalSelected.length === 0) ||
                (values.status.label === 'Deferred' && values.withdrawalSelected.length === 0)
              ) {
                errors.withdrawalSelected = 'Reason required';
              }
              if (values.withdrawalSelected.value === 'Other' && values.withdrawReason.length === 0) {
                errors.withdrawReason = 'Reason required';
              }

              return errors;
            }}
            onSubmit={values => {
              let buildReason = [];
              if (values?.withdrawalSelected?.label?.length) {
                buildReason.push(values.withdrawalSelected.label);
              }
              if (values?.withdrawReason?.length) {
                buildReason.push(values.withdrawReason);
              }

              const submitValues = {
                submission_ids: props?.applicationIds,
                submission_status_id: values?.status?.value,
                type: 'application',
                reasons: buildReason,
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowConfirm(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchUpdateApplicationStatus.row}>
                  <div className={sBatchUpdateApplicationStatus.item}>
                    <Field name="status">
                      {({ form }) => (
                        <InputSelect
                          id={values.status}
                          name={values.status}
                          labelText="Application Status"
                          aria-label="Application Status"
                          errorMessage={errors.status}
                          isRequired
                          helpText=""
                          isMulti={false}
                          styles={{
                            menu: provided => ({ ...provided, zIndex: 99999 }),
                            placeholder: provided => ({ ...provided, color: '#767676', fontStyle: 'italic' }),
                          }}
                          value={values.status}
                          options={appStatusOptions}
                          onChange={e => form.setFieldValue('status', e)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                {values?.status?.label?.toLowerCase() === 'withdrawn' ||
                values?.status?.label?.toLowerCase() === 'deferred' ? (
                  <div className={sBatchUpdateApplicationStatus.row}>
                    <div className={sBatchUpdateApplicationStatus.item}>
                      <Field name="withdrawalSelected">
                        {({ form }) => (
                          <InputSelect
                            id={values.withdrawalSelected}
                            name={values.withdrawalSelected}
                            labelText="Reason"
                            aria-label="Withdraw Reason"
                            errorMessage={errors.withdrawalSelected}
                            isRequired
                            helpText=""
                            isMulti={false}
                            value={values.withdrawalSelected}
                            options={withdrawReasons}
                            placeholder={withdrawReasons.label}
                            onChange={e => form.setFieldValue('withdrawalSelected', e)}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                ) : null}
                {values.withdrawalSelected.label === 'Other' ? (
                  <div className={sBatchUpdateApplicationStatus.row}>
                    <div className={sBatchUpdateApplicationStatus.item}>
                      <Field name="withdrawReason">
                        {({ form }) => (
                          <InputText
                            id={values.withdrawReason}
                            name={values.withdrawReason}
                            labelText="Other"
                            aria-label="Other Reason"
                            errorMessage={errors.withdrawReason}
                            isRequired
                            helpText=""
                            value={values.withdrawReason}
                            placeholder={withdrawReasons.label}
                            onChange={e => form.setFieldValue('withdrawReason', e.target.value)}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                ) : null}
                <div className={sBatchUpdateApplicationStatus.footer}>
                  <div className={sBatchUpdateApplicationStatus['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchUpdateApplicationStatus['button-content']}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      type="submit"
                      aria-label="Update General Form Status Button"
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <ViaModal
        headerText="Update Status"
        showing={showConfirm}
        width="700px"
        onClose={() => {
          resetForm();
          setShowConfirm(false);
        }}
      >
        <div className={sBatchUpdateApplicationStatus['batch-update-form-status']}>
          <div className={sBatchUpdateApplicationStatus.row}>
            <Paragraph size="normal">
              {`You are about to update the status of ${props.userIds.length} ${
                props.userIds.length === 1 ? 'form' : 'forms'
              }. ${
                props.customAliases.alias_travelers
              }, ${props.customAliases.alias_program.toLowerCase()} contact and default message recipients will receive email notifications of this change.`}
            </Paragraph>
          </div>

          <div className={sBatchUpdateApplicationStatus.row}>
            <Paragraph size="normal">Ready to update?</Paragraph>
          </div>
          <div className={sBatchUpdateApplicationStatus.footer}>
            <div className={sBatchUpdateApplicationStatus['button-content']}>
              <Button
                display="secondary"
                kind="solid"
                size="medium"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className={sBatchUpdateApplicationStatus['button-content']}>
              <Button
                display="primary"
                kind="solid"
                size="medium"
                type="submit"
                disabled={saving}
                onClick={() => {
                  dispatch(updateApplicationStatuses(submitValues, props.selectedTemplateId, props.dayOffset));
                  props.setSelectedPrograms();
                  if (props.formContent) {
                    dispatch(fetchFormsReport(props.dayOffset));
                  }
                  setShowConfirm(false);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </ViaModal>

      {success ? (
        <ToastMessage
          message={successMessage}
          show={success}
          isSuccess
          newestOnTop={false}
          onClose={() => {
            setSuccess(false);
          }}
        />
      ) : null}
      {failure ? (
        <ToastMessage
          message={failureMessage}
          isSuccess={false}
          show={failure}
          newestOnTop={false}
          onClose={() => {
            setFailure(false);
          }}
        />
      ) : null}
    </>
  );
}

BatchUpdateFormApplicationStatus.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchUpdateFormApplicationStatus);

import React from 'react';
import Select  from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Error from './Error';
import Sync from '../../icons/Sync';
import ErrorRequired from './ErrorRequired';

import { breakpoints } from '../../../utils/breakpoints';

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.125rem auto 0 auto;
  max-width: ${props => (props.fullWidth ? '100%' : '32rem')}


  @media ${breakpoints.tablet} {
    margin-top: 3.125rem;

    ${props => {
      if (props.noMarginTop) {
        return `
          margin-top: 0;
        `;
      }
    }};
  }

  ${props => {
    if (props.noMarginTop) {
      return `
        margin-top: 0;
      `;
    }
  }};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    ${props => {
      if (props.labelToSide) {
        return `
          flex-direction: row;
          align-items: center;

          label {
            margin-bottom: 0;
          }
        `;
      }
    }};

    ${props => {
      switch (props.labelToSide) {
        case 'left':
          return `
            label {
              margin-right: 0.4375rem;
            }
          `;
        case 'right':
          return `
            label {
              margin-left: 0.4375rem;
            }
          `;
        default:
          return `
          `;
      }
    }};
  }
`;

const SelectLabel = styled.label`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;

  svg {
    margin-bottom: 2px;
    margin-left: 7px;
  }
`;

export default function SelectInput({
  id,
  value,
  error,
  label,
  isMulti,
  options,
  disabled,
  labelToSide,
  noMarginTop,
  placeholder,
  defaultValue,
  handleOnChange,
  required,
  fullWidth = false
}) {
  const customStyles = {
    container: styles => ({
      ...styles,
      flexGrow: '1',
    }),
    control: (styles, state) => ({
      ...styles,
      padding: '0.25rem',
      backgroundColor: state.isDisabled ? '#eeeeee' : '#ffffff',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: state.isFocused ? '#255a94' : '#c2c2c2',
      borderRadius: '2px',
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: '400',
      fontSize: '0.875rem',
      color: '#666666',
      lineHeight: '140%',
      outline: 'none',
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: '#373737',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: styles => ({
      ...styles,
      zIndex: '20',
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#D2E4ED',
      borderRadius: '50px',
      fontFamily: "'Nunito', sans-serif",
      fontWeight: '600',
      fontSize: '0.875rem',
      color: '#373737',
      lineHeight: '120%',
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isFocused || state.isSelected ? '#D2E4ED' : '#ffffff',
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: '400',
      fontSize: '0.875rem',
      color: '#373737',
      lineHeight: '140%',
    }),
    placeholder: (styles,state) => ({
      ...styles,
      fontStyle:"italic"
    })
  };
  return (
    <SelectContainer noMarginTop={noMarginTop} fullWidth={fullWidth}>
      <InputContainer labelToSide={labelToSide}>
        {labelToSide !== 'right' && (
          <SelectLabel htmlFor={id}>
            {label}
            {required && <ErrorRequired />}
            {disabled && <Sync />}
          </SelectLabel>
        )}
        <Select
          value={value}
          defaultValue={defaultValue}
          aria-label={`${label} `}
          inputId={id}
          aria-live="assertive"
          isMulti={isMulti}
          name={id}
          options={options}
          styles={customStyles}
          placeholder={placeholder}
          isDisabled={disabled}
          onChange={handleOnChange}
          menuPlacement="auto"
          theme={theme => ({
            ...theme,
            colors: {
              primary: '#255a94',
            },
          })}
        />
        {labelToSide === 'right' && (
          <SelectLabel htmlFor={id}>
            {label}
            {required && <ErrorRequired />}
            {disabled && <Sync />}
          </SelectLabel>
        )}
      </InputContainer>
      {error && <Error message={error} />}
    </SelectContainer>
  );
}

SelectInput.propTypes = {
  error: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  labelToSide: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.object]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};

SelectInput.defaultProps = {
  error: '',
  isMulti: false,
  disabled: false,
  labelToSide: '',
  defaultValue: '',
  noMarginTop: false,
  placeholder: 'Select an option',
};

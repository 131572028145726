import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _concat from 'lodash/concat';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import {
  fetchActivityTypes,
  fetchHousingTypes,
  fetchTransportationTypes,
  clearDetailTypes,
} from '../../../../../actions/lookupActions';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function PlanDetailTypeFilter(props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const activityTypes = useSelector(state => state.lookup.allActivityTypes);
  const transportationTypes = useSelector(state => state.lookup.allTransportationTypes);
  const housingTypes = useSelector(state => state.lookup.allHousingTypes);

  useEffect(
    () => {
      dispatch(clearDetailTypes());
      if (props.source === 'activity') {
        dispatch(fetchActivityTypes());
      } else if (props.source === 'transportation') {
        dispatch(fetchTransportationTypes());
      } else if (props.source === 'housing') {
        dispatch(fetchHousingTypes());
      }
    },
    [props.source],
  );

  useEffect(
    () => {
      if (!!activityTypes.activityTypes && activityTypes.activityTypes.length > 0) {
        setOptions(activityTypes.activityTypes);
      } else if (!!transportationTypes.transportationTypes && transportationTypes.transportationTypes.length > 0) {
        setOptions(transportationTypes.transportationTypes);
      } else if (!!housingTypes.housingTypes && housingTypes.housingTypes.length > 0) {
        setOptions(housingTypes.housingTypes);
      }
    },
    [activityTypes, transportationTypes, housingTypes],
  );

  useEffect(
    () => {
      if (!!options) {
        const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
        setExpanded(calcExpanded);
      }

      let valueNames = [];

      !!options &&
        options.forEach(item => {
          valueNames = _concat(valueNames, item.attributes.name);
        });
      const optionValues = buildSelectOptions(valueNames, true, true);

      setSourceOptions(optionValues);
    },
    [options],
  );

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

PlanDetailTypeFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  source: propTypes.string.isRequired,
};

PlanDetailTypeFilter.defaultProps = {
  displayText: '',
  selectedValues: [],
};

export default enhance(PlanDetailTypeFilter);

exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _29W9D{to{-webkit-transform:rotate(1turn)}}@keyframes _29W9D{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1tznW ._3t9Tl{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;padding-top:8px;-ms-flex-align:end;align-items:flex-end}._1tznW ._3t9Tl .B9Rl1{width:104px}._1tznW ._3t9Tl ._1kwku{font-size:14px;font-weight:400}._1tznW ._3ge1m{padding-top:8px;width:104px}._1tznW .tpG3A{padding-top:8px;-ms-flex-align:end;align-items:flex-end}", ""]);

// exports
exports.locals = {
	"durationRangeFilter": "_1tznW",
	"rangeValues": "_3t9Tl",
	"rangeValue": "B9Rl1",
	"separator": "_1kwku",
	"singleValue": "_3ge1m",
	"timeframe": "tpG3A",
	"spin": "_29W9D"
};
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import FilterQuestionCompletedSelect from '../../filterTypes/filterQuestionCompletedSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function SubmissionQuestionFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(
    () => {
      if (props.selectedValues) {
        const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
        setExpanded(calcExpanded);
      }

      const optionValues = props.sourceData;

      setSourceOptions(optionValues);
    },
    [props],
  );

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterQuestionCompletedSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
            clearValues={props.clearValues}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

SubmissionQuestionFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
  clearValues: propTypes.bool,
};

SubmissionQuestionFilter.defaultProps = {
  displayText: 'Question Completed',
  selectedValues: [],
  clearValues: false,
};

export default enhance(SubmissionQuestionFilter);

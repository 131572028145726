import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import classnames from 'classnames';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Arrow from '../../../../../../../../public/img/arrow.svg';
import Headline from '../../../../../../components/library/headline';
import sOvLeftNav from './ovLeftNav.scss';

export const enhance = compose(
  withStyles(sOvLeftNav),
  withRouter,
);

function OvLeftNav(props) {
  const selectedPlan = useSelector(state => state.plans.plan);
  const [activeOption, setActiveOption] = useState(props.defaultSelected);

  useEffect(() => {
    props.parentCallback(activeOption);
  }, [activeOption]);

  return (
    <div className={sOvLeftNav['ov-left-nav']}>
      <div className={sOvLeftNav['nav-option']}>
        <div
          tabIndex={0}
          role="button"
          aria-label="Select Plan Overview"
          className={
            classnames(
              sOvLeftNav.selection,
              activeOption === 'planOverview' ? sOvLeftNav.isSelected : ''
            )
          }
          onClick={() => {setActiveOption('planOverview')}}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setActiveOption('planOverview');
            }
          }}
        >
          <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"12px",
            lineHeight:"1.1em"
          }} tag="div" as="div" upper>
            {!!selectedPlan && !!selectedPlan.plan && selectedPlan.plan.attributes.group ? 'GROUP' : 'PLAN'} OVERVIEW
          </Headline>
        </div>
      </div>
      <div className={sOvLeftNav['nav-option']}>
        <div
          tabIndex={0}
          role="button"
          aria-label="Select Travelers Plan"
          className={
            classnames(
              sOvLeftNav.selection,
              activeOption === 'planTravelers' ? sOvLeftNav.isSelected : ''
            )
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setActiveOption('planTravelers');
            }
          }}
          onClick={() => {setActiveOption('planTravelers')}}
        >
          <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"12px",
            lineHeight:"1.1em"
          }} tag="div" as="div" upper>WHO'S GOING</Headline>
        </div>
      </div>
      {!!selectedPlan && !!selectedPlan.plan &&
      (selectedPlan.plan.attributes.group) &&
        <div className={sOvLeftNav['nav-option']}>
          <div
            tabIndex={0}
            role="button"
            aria-label="Select Group Plan"
            className={
              classnames(
                sOvLeftNav.selection,
                activeOption === 'groupPlan' ? sOvLeftNav.isSelected : ''
              )
            }
            onClick={() => {
              setActiveOption('groupPlan')
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setActiveOption('groupPlan');
              }
            }}
          >
            <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"12px",
            lineHeight:"1.1em"
          }} tag="div" as="div" upper>
              GROUP ITINERARY
            </Headline>
          </div>
        </div>
      }

      {!!selectedPlan &&
      !!selectedPlan.plan &&
      !selectedPlan.plan.attributes.joinable &&
      props.as !== 'admin' &&
      <div className={sOvLeftNav['nav-option']}>
        <div
          tabIndex={0}
          role="button"
          aria-label="Select My Itinerary"
          className={
            classnames(
              sOvLeftNav.selection,
              activeOption === 'myItinerary' ? sOvLeftNav.isSelected : ''
            )
          }
          onClick={() => {setActiveOption('myItinerary')}}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setActiveOption('myItinerary');
            }
          }}
        >
          <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"12px",
            lineHeight:"1.1em"
          }} tag="div" as="div" upper>
            MY ITINERARY
          </Headline>
        </div>
      </div>
      }
    </div>
  );
}

OvLeftNav.propTypes = {
  backTo: propTypes.string.isRequired,
  defaultSelected: propTypes.oneOf(['planOverview', 'planTravelers', 'myItinerary', 'groupPlan']),
  parentCallback: propTypes.func,
  as: propTypes.oneOf(['traveler', 'admin', 'lead']).isRequired,
};

OvLeftNav.defaultProps = {
  defaultSelected: 'planOverview',
};

export default enhance(OvLeftNav);

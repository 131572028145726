import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../../components/library/headline';
import Paragraph from '../../../../../components/library/paragraph';
import Button from '../../../../../components/library/button';
import InputText from '../../../../../components/library/inputText';
import { Formik } from 'formik';
import { inviteSingleTraveler } from '../../../../../../actions/invitationsActions';
import ToastMessage from '../../../toastMessage';
import InviteMultipleTravelers from '../inviteMultipleTravelers';
import sInviteSingleTraveler from './inviteSingleTraveler.scss';

export const enhance = compose(withStyles(sInviteSingleTraveler));

function InviteSingleTraveler(props) {
  const invitedTravelers = useSelector(state => state.adminInvitations);
  const dispatch = useDispatch();
  const [showBatch, setShowBatch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [message, setMessage] = useState('');
  const customAliases = useSelector(state => state.profile.customAliases);

  useEffect(
    () => {
      if (invitedTravelers.singleInviteCode !== null) {
        if (invitedTravelers.singleInviteCode === 200) {
          props.onClose(false);
          setMessage(invitedTravelers.message);
          setSuccess(true);
        } else if (invitedTravelers.singleInviteCode !== 200) {
          props.onClose(false);
          setMessage(invitedTravelers.message);
          setFailure(true);
        }
      }
    },
    [invitedTravelers],
  );

  return (
    <>
      {showBatch ? (
        <InviteMultipleTravelers
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
          }}
          validate={values => {
            const errors = {};
            if (!values.first_name) {
              errors.first_name = 'First Name Required';
            }
            if (!values.last_name) {
              errors.last_name = 'Last Name Required';
            }
            if (!values.email) {
              errors.email = 'Email Required';
            }
            return errors;
          }}
          onSubmit={values => {
            // setSaving(true);
            dispatch(inviteSingleTraveler({ ...values }));
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={sInviteSingleTraveler['invite-one-traveler']}>
                <Headline tag="h2" as="h2" centered>
                  Add A Single {customAliases.alias_traveler}
                </Headline>
                <div className={sInviteSingleTraveler.container}>
                  <div className={sInviteSingleTraveler.input}>
                    <InputText
                      labelText="First Name"
                      name="first_name"
                      id="first_name"
                      placeholder="First Name"
                      ariaLabel="First name input"
                      type="text"
                      isRequired
                      errorMessage={errors.first_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                    />
                  </div>
                  <div className={sInviteSingleTraveler.input}>
                    <InputText
                      labelText="Last Name"
                      name="last_name"
                      id="last_name"
                      placeholder="Last Name"
                      ariaLabel="Last Name input"
                      type="text"
                      isRequired
                      errorMessage={errors.last_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                    />
                  </div>
                  <div className={sInviteSingleTraveler.input}>
                    <InputText
                      labelText="Email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      ariaLabel="Valid email address input"
                      type="text"
                      isRequired
                      errorMessage={errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </div>
                  <div
                    className={sInviteSingleTraveler.toggleContainer}
                    onClick={() => setShowBatch(true)}
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.keyCode === 32 || e.keyCode === 13) {
                        setShowBatch(true);
                      }
                    }}
                  >
                    <Paragraph ariaLabel="Click to toggle view to add multiple travelers by csv">
                      Add Multiple {customAliases.alias_travelers}
                    </Paragraph>
                  </div>
                  <div>
                    <div className={sInviteSingleTraveler['button-row']}>
                      <div className={sInviteSingleTraveler['button-content']}>
                        <Button
                          display="secondary"
                          kind="solid"
                          size="medium"
                          ariaLabel="Cancel"
                          onClick={() => {
                            props.onClose(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      {values.first_name && values.last_name && values.email ? (
                        <div className={sInviteSingleTraveler['button-content']}>
                          <Button display="primary" kind="solid" size="medium" type="submit" ariaLabel="Add Traveler">
                            Add
                          </Button>
                        </div>
                      ) : (
                        <div className={sInviteSingleTraveler['button-content']}>
                          <Button
                            display="secondary"
                            kind="solid"
                            size="medium"
                            disabled
                            ariaLabel="Add Traveler- disabled"
                          >
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
      <ToastMessage
        message={message}
        show={success}
        onClose={() => {
          setSuccess(false);
          setMessage('');
        }}
        source="invite_travelers"
        isSuccess={true}
      />
      <ToastMessage
        message={message}
        show={failure}
        onClose={() => {
          setFailure(false);
          setMessage('');
        }}
        source="invite_travelers"
        isSuccess={false}
      />
    </>
  );
}

InviteSingleTraveler.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

InviteSingleTraveler.defaultProps = {
  show: false,
};

export default enhance(InviteSingleTraveler);

import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputSelect from '../../../library/inputSelect';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import sFilterAlternatesMultiSelect from './filterAlternatesMultiSelect.scss';
import _isEqual from 'lodash/isEqual';
export const enhance = compose(withStyles(sFilterAlternatesMultiSelect));

function FilterAlternatesMultiSelect(props) {
  const isInitialMount = useRef(true);
  const [filterOptions, setFilterOptions] = useState(props.sourceData);
  const [defaultValues, setDefaultValues] = useState([]);
  const [defaultOptionValue, setDefaultOptionValue] = useState([]);
  const [selectedAltValues, setSelectedAltValues] = useState(props.selectedValues[0]);
  const [selectedAltOption, setSelectedAltOption] = useState (props.selectedValues[1]);
  let altOptions = [];
  for (let i = 1; i < 21; i++) {
    altOptions.push({'label': `Alternate ${i}`, value: `Alternate ${i}`});
  }

  const selectedValues = (values) => {
    let filterValues = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        filterValues.push(item.value);
      });
    }
    setDefaultValues(values);
    setSelectedAltValues(filterValues);
  }

  const selectedOptions = (values) => {
    let filterValues = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        filterValues.push(item.value);
      });
    }

    setSelectedAltOption(values);
    setSelectedAltOption(filterValues);
  }

  useEffect(() => {
    if (props.sourceData) {
      setFilterOptions(props.sourceData);
    }
  }, [props]);

  useEffect(() => {
    let defValues = [];
    let defSelectedValues = [];
    let defOptions = [];
    let defSelectedOptions = [];

    if (props.selectedValues) {
      if (props.selectedValues[0] && props.selectedValues[0].length > 0) {
        defValues = props.selectedValues[0];
        const buildDefaultValues = buildSelectOptions(props.selectedValues[0], true, true);
        defSelectedValues = buildDefaultValues;
      }

      if (props.selectedValues[1] && props.selectedValues[1].length > 0) {
        defOptions = props.selectedValues[1];
        const buildDefaultOptionValue = buildSelectOptions(props.selectedValues[1], true, true);
        defSelectedOptions = buildDefaultOptionValue;
      }
    }

    setSelectedAltValues(defValues);
    setDefaultValues(defSelectedValues);
    setSelectedAltOption(defOptions);
    setDefaultOptionValue(defSelectedOptions);

    return () => {};
  }, [props.selectedValues]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      let filterValues = [];
      if (selectedAltValues && selectedAltValues.length > 0) {
        filterValues[0] = selectedAltValues;
      }
      if (selectedAltOption && selectedAltOption.length > 0){
        filterValues[1] = selectedAltOption;
      }

      if (!_isEqual(filterValues, props.selectedValues)) {
        props.change(filterValues);
      }

      return () => {};

    }
  }, [selectedAltValues, selectedAltOption]);

  return (
    <div className={sFilterAlternatesMultiSelect.alternatesMultiSelect}>
      <InputSelect
        isMulti
        id={props.id}
        labelText={props.labelText}
        styles={{
          menu: provided => ({...provided, zIndex: 99999})
        }}
        options={filterOptions}
        onChange={(e) => selectedValues(e)}
        defaultValue={defaultValues}
        value={defaultValues}
        labelSize="normal"
      />

      <div className={sFilterAlternatesMultiSelect.typeOptions}>
        <InputSelect
          isMulti
          id={'alt-types'}
          labelText={'Alternate priority'}
          styles={{
            menu: provided => ({...provided, zIndex: 99999})
          }}
          options={altOptions}
          onChange={(e) => selectedOptions(e)}
          defaultValue={defaultOptionValue}
          value={defaultOptionValue}
          labelSize="normal"
        />
      </div>
    </div>
  );
}

FilterAlternatesMultiSelect.propTypes = {
  id: propTypes.string.isRequired,
  change: propTypes.func.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
  showGroupCounts: propTypes.bool,
};

FilterAlternatesMultiSelect.defaultProps = {
  labelText: 'Filter Alternates Options',
  selectedValues: [],
  showGroupCounts: false,
};

export default enhance(FilterAlternatesMultiSelect);

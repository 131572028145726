import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

export const Card = styled(Link)`
  position: relative;
  grid-column: span 4 / span 4;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 5px 15px rgba(55, 55, 55, 0.25);
  width: 320px;

  &:hover,
  &:focus {
    transform: translateY(-5px);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    .apply-now-banner {
      display: block;
    }
  }

  &:hover {
    text-decoration: none;
  }

  @media ${breakpoints.desktop} {
    grid-column: span 1 / span 1;
  }

  ${buttonFocus};
`;


export const ProgramCards = styled.div`
  position: relative;
  grid-column: span 4 / span 4;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 5px 15px rgba(55, 55, 55, 0.25);
  width: 320px;
  cursor:pointer;
  &:hover,
  &:focus {
    transform: translateY(-5px);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border: 1px solid #373737;
    .apply-now-banner {
      display: block;
    }
  }

  &:hover {
    text-decoration: none;
  }

  @media ${breakpoints.desktop} {
    grid-column: span 1 / span 1;
  }

  ${buttonFocus};
`;

export const IconImage = styled.img`
  width: 20px;
  height: auto;
`;

export const ApplyNowBanner = styled.div`
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem 0;
  background-color: var(--theme_color_accent);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  text-align: center;
  letter-spacing: 0.0025em;
  line-height: 120%;
`;

export const CardImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 200px;
  background-image: ${props => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  border-radius: 8px;
`;

export const Organization = styled.span`
  margin-top: 0.6875rem;
  margin-left: 0.8125rem;
  padding: 0.25rem 1.125rem;
  background-color: var(--theme_color_accent);
  border-radius: 50px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.0025em;
  line-height: 120%;
`;

export const MatchPercentage = styled.div`
  width: 100%;
  padding: 0.3125rem 0.5625rem 0.3125rem 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  text-align: right;
  letter-spacing: 0.0025em;
  line-height: 120%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${({ percentage }) => {
    switch (true) {
      case percentage > 50:
        return `
          background-color: #DAFDD7;
          color: #0E5B2F;
        `;
      case percentage <= 50 && percentage > 10:
        return `
          background-color: #FFEFD4;
          color: #6D4603;
        `;
      default:
        return `
          background-color: #CCCCCC;
          color: #373737;
        `;
    }
  }};
`;

export const FavoriteButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;

  ${buttonFocus};
`;

export const ProgramInfoContainer = styled.div`
  padding: 1.0625rem 0 1rem 0;

  span {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #043544;
    letter-spacing: 0.0025em;
    line-height: 120%;
  }
`;

export const ProgramHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ProgramName = styled.span`
  margin-bottom: 0.375rem;
  font-size: 1.3125rem;
  color: #043544;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  line-height: 1;
`;

export const ProgramDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ProgramDetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.78125rem;

  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    fill: #6c6c6c;
  }
`;

export const ProgramSubjectAreas = styled.div`
  padding: 1.125rem 0 1rem 0;
  border-top: 1px solid #dbdada;
`;

export const SubjectAreasHeading = styled.span`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;
`;

export const SubjectAreasList = styled.ul`
  margin-bottom: 0;
  padding-inline-start: 30px;
  list-style-type: disc;

  li {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #6c6c6c;
    letter-spacing: 0.0025em;
    line-height: 120%;
  }
`;

export const AdditionalItemsText = styled.span`
  display: block;
  margin-top: 0.5625rem;
  margin-left: ${props => (props.forSubjects ? '1rem' : '0')};
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.625rem !important;
  color: #6b6b6b !important;
  letter-spacing: 0.0025em;
  line-height: 120%;
`;

import React from 'react';
import PropTypes from 'prop-types';

export default function MinusIcon({ fillColor }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.48462 0.5C3.90056 0.5 0.984619 3.41594 0.984619 7C0.984619 10.5841 3.90056 13.5 7.48462 13.5C11.0687 13.5 13.9846 10.5841 13.9846 7C13.9846 3.41594 11.0687 0.5 7.48462 0.5ZM9.98462 7.5H4.98462C4.85201 7.5 4.72483 7.44732 4.63107 7.35355C4.5373 7.25979 4.48462 7.13261 4.48462 7C4.48462 6.86739 4.5373 6.74021 4.63107 6.64645C4.72483 6.55268 4.85201 6.5 4.98462 6.5H9.98462C10.1172 6.5 10.2444 6.55268 10.3382 6.64645C10.4319 6.74021 10.4846 6.86739 10.4846 7C10.4846 7.13261 10.4319 7.25979 10.3382 7.35355C10.2444 7.44732 10.1172 7.5 9.98462 7.5Z"
        fill="#446372"
      />
    </svg>
  );
}

MinusIcon.propTypes = {
  fillColor: PropTypes.string,
};

MinusIcon.defaultProps = {
  fillColor: 'currentColor',
};

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../../components/library/headline';
import Button from '../../../../../components/library/button';
import Paragraph from '../../../../../components/library/paragraph';
import inviteSampleUrl from '../../../../../../../public/files/traveler_invite.csv';
import Dropzone from 'react-dropzone';
import InviteSingleTraveler from '../inviteSingleTraveler';
import { batchInviteTravelers } from '../../../../../../actions/invitationsActions';
import ToastMessage from '../../../toastMessage';
import sBatchAddTravelerToPlan from './inviteMultipleTravelers.scss';

export const enhance = compose(withStyles(sBatchAddTravelerToPlan));

function InviteMultipleTravelers(props) {
  const [saving, setSaving] = useState(false);
  const [showSoloAdd, setShowSoloAdd] = useState(false);
  const [extensionError, setExtensionError] = useState(false);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const dropZoneRef = useRef('dropzone');
  const invitedTravelers = useSelector(state => state.adminInvitations);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [message, setMessage] = useState('');
  const customAliases = useSelector(state => state.profile.customAliases);

  useEffect(
    () => {
      if (invitedTravelers.batchInviteCode !== null) {
        if (invitedTravelers.batchInviteCode === 200) {
          props.onClose(false);
          setMessage(invitedTravelers.batchMessage);
          setSuccess(true);
        } else {
          props.onClose(false);
          setMessage(invitedTravelers.batchMessage);
          setFailure(true);
        }
      }
    },
    [invitedTravelers.batchInviteCode],
  );

  const onDrop = files => {
    let file = files[0];
    let extension = file.name
      .split('.')
      .pop()
      .toLowerCase();

    if (extension !== 'csv') {
      setExtensionError(true);
    } else if (extension === 'csv') {
      setExtensionError(false);
    }

    setFile(file);
  };

  const handleBatchInviteTravelers = () => {
    setSaving(true);
    if (file && !extensionError) {
      const reader = new FileReader();

      reader.readAsBinaryString(file);

      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        dispatch(batchInviteTravelers(fileAsBinaryString));
      };
    }
  };

  return (
    <>
      {showSoloAdd ? (
        <InviteSingleTraveler
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <div className={sBatchAddTravelerToPlan['invite-multiple-travelers-to-plan']}>
          <Headline tag="h2" as="h2" centered>
            Upload a .CSV file to add multiple {customAliases.alias_travelers}
          </Headline>
          <div className={sBatchAddTravelerToPlan.downloadContainer}>
            <a href={inviteSampleUrl} target="_blank" download="InvitationTemplate">
              Download Batch Template
            </a>
          </div>
          <div className={sBatchAddTravelerToPlan.dropzoneContainer}>
            <Dropzone
              onDrop={onDrop}
              ref={dropZoneRef}
              activeStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
              tabIndex={0}
              onKeyDown={e => {
                if (e.keyCode === 32 || e.keyCode === 13) {
                  e => {
                    e.preventDefault();
                    dropZoneRef.open();
                  };
                }
              }}
            >
              <Paragraph ariaLabel="Add file by clicking or dragging a csv file here">
                {file ? file.name : 'Click or drag a CSV file here'}
              </Paragraph>
            </Dropzone>
          </div>
          {extensionError && (
            <div style={{ width: '30%', margin: '0 auto' }}>
              <div role="alert">
                <Paragraph color="error" size="small">
                  The file uploaded must have a .CSV extension
                </Paragraph>
              </div>
            </div>
          )}
          <div className={sBatchAddTravelerToPlan.instructions}>
            <Paragraph size="small" color="error">
              Invitations will not be sent to {customAliases.alias_travelers.toLowerCase()} with existing invitations
            </Paragraph>
          </div>
          <div
            className={sBatchAddTravelerToPlan.downloadContainer}
            tabIndex={0}
            onClick={() => setShowSoloAdd(true)}
            onKeyDown={e => {
              if (e.keyCode === 32 || e.keyCode === 13) {
                setShowSoloAdd(true);
              }
            }}
          >
            <Paragraph ariaLabel="Click to toggle view to add only a single traveler">
              Add a Single {customAliases.alias_traveler}
            </Paragraph>
          </div>

          <div>
            <div className={sBatchAddTravelerToPlan['button-row']}>
              <div className={sBatchAddTravelerToPlan['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  ariaLabel="Cancel"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              {file && !extensionError ? (
                <div className={sBatchAddTravelerToPlan['button-content']}>
                  <Button
                    display="primary"
                    kind="solid"
                    size="medium"
                    onClick={() => handleBatchInviteTravelers()}
                    ariaLabel="Upload CSV - Disabled"
                  >
                    Upload
                  </Button>
                </div>
              ) : (
                <div className={sBatchAddTravelerToPlan['button-content']}>
                  <Button display="secondary" kind="solid" size="medium" disabled ariaLabel="Upload CSV">
                    Upload
                  </Button>
                </div>
              )}
            </div>
          </div>
          <ToastMessage
            message={message}
            show={success}
            onClose={() => {
              setSuccess(false);
              setMessage('');
            }}
            source="invite_travelers"
            isSuccess={true}
          />
          <ToastMessage
            message={message}
            show={failure}
            onClose={() => {
              setFailure(false);
              setMessage('');
            }}
            source="invite_travelers"
            isSuccess={false}
          />
        </div>
      )}
    </>
  );
}

InviteMultipleTravelers.propTypes = {
  show: propTypes.bool,
  onClose: propTypes.func.isRequired,
};

export default enhance(InviteMultipleTravelers);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _uniq from 'lodash/uniq';
import {
  AccentButtonMediumOpenSans,
  ButtonMediumOpenSans,
} from '../../../../../styledComponents/styles/Buttons.styled';
import { replaceCustomAliasesHandlebars } from '../../../../../shared/helpers/General';
import { sendSafeCheckEnrollmentRequest } from '../../../../../actions/safecheckActions';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { themeVia } from '../../../../../styledComponents/settings/theme';
import ToastMessage from '../../../../../sites/viaGlobal/components/toastMessage';
import ViaModal from '../../../../../sites/components/library/viaModal';

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  button {
    margin: 15px;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin: 15px;
  }
`;
const Instructions = styled.div`
  margin-bottom: 30px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #373737;
  width: 75%;
`;

function SendSafeCheckEnrollmentRequest(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastFailure, setShowToastFailure] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const safeCheckEnrollmentRequest = useSelector(state => state.safecheck.safeCheckEnrollmentRequest);
  const selectedUsers = _uniq(
    props.selectedTravelerData
      .filter(traveler => traveler.safe_check_enrollment_status !== 'Opted Out')
      .map(traveler => traveler.traveler_id),
  );

  useEffect(
    () => {
      if (safeCheckEnrollmentRequest.data) {
        setToastMessage(safeCheckEnrollmentRequest.data);
        setShowToastSuccess(true);
      } else if (safeCheckEnrollmentRequest.error) {
        setToastMessage(safeCheckEnrollmentRequest.error);
        setShowToastFailure(true);
      }
    },
    [safeCheckEnrollmentRequest],
  );

  return (
    <ThemeProvider theme={themeVia}>
      <ViaModal
        showing={props.show}
        headerText={'Send SafeCheck Enrollment Request'}
        onClose={() => props.onClose()}
        width={'550px'}
      >
        <FlexContainer>
          <Instructions>
            You are about to send a SafeCheck Enrollment Request to {`${selectedUsers.length} `}
            {selectedUsers.length === 1 ? `${customAliases.alias_traveler} ` : `${customAliases.alias_travelers} `}
            who {selectedUsers.length === 1 ? `has` : `have`} not previously enrolled or opted out.
          </Instructions>

          <Instructions>This action will send them a mailer to enroll in SafeCheck.</Instructions>

          <Instructions>Do you want to continue?</Instructions>
          <FooterContainer>
            <FooterButtonContainer>
              <ButtonMediumOpenSans aria-label="Cancel" onClick={() => props.onClose()}>
                Cancel
              </ButtonMediumOpenSans>
              <AccentButtonMediumOpenSans
                aria-label="Send Enrollment Request"
                disabled={selectedUsers.length === 0}
                onClick={() => {
                  dispatch(sendSafeCheckEnrollmentRequest(selectedUsers));
                  props.onClose();
                }}
              >
                Send
              </AccentButtonMediumOpenSans>
            </FooterButtonContainer>
          </FooterContainer>
        </FlexContainer>
      </ViaModal>
      <ToastMessage
        message={toastMessage}
        show={showToastSuccess}
        isSuccess
        onClose={() => {
          setShowToastSuccess(false);
          setToastMessage('');
        }}
      />
      <ToastMessage
        message={toastMessage}
        show={showToastFailure}
        isSuccess={false}
        onClose={() => {
          setShowToastFailure(false);
          setToastMessage('');
        }}
      />
    </ThemeProvider>
  );
}

export default SendSafeCheckEnrollmentRequest;

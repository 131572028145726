import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import FilterNumericCheckboxRange from '../../filterTypes/filterNumericCheckboxRange';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(
  withStyles(sFilterAccordion),
);

function GenericNumericFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);

  useEffect(() => {
    if (props.selectedValues) {
      const isData = (props.selectedValues[1] || props.selectedValues[2] || props.selectedValues[3]) || (props.selectedValues[4] &&props.selectedValues[4].length > 0) ? true : false;
      const calcExpanded = manualExpanded || isData ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  const selectedValues = (values) => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>
            {props.displayText}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {!!props.selectedValues && (
            <FilterNumericCheckboxRange
              id={props.id}
              selectedValues={props.selectedValues}
              change={(e) => selectedValues(e)}
              step={'1'}
              hidePresetValues={props.hidePresetValues}
            />
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

GenericNumericFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  hidePresetValues: propTypes.bool,
};

GenericNumericFilter.defaultProps = {
  displayText: '',
  hidePresetValues: false,
};

export default enhance(GenericNumericFilter);

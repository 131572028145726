import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import _get from 'lodash/get';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { ThemeProvider } from 'styled-components';
import sModal from 'react-responsive-modal/styles.css';
import { fetchCurrentUser } from '../actions/profileActions';
import { useThemeColors } from '../shared/hooks/useThemeColors';
import AdminHeader from './AdminHeader';
import GlobalStyles from './styles/GlobalStyles';
import loadedFonts from '../shared/fonts/loadFonts.css';
import userId from '../shared/helpers/userId';

import { themeVia } from './settings/theme';

function AdminLayout(props) {
  const { children, includedHeader } = props;
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.profile);
  const [isLoaded, setIsLoaded] = useState(false);
  const isBrandingSet = useThemeColors();

  if (!userId || typeof userId === 'undefined') {
    window.location.assign(`/traveler/sign_in`);
  }

  useEffect(() => {
    if (userProfile && userProfile.userLoading) {
      // if (!userProfile) {
      dispatch(fetchCurrentUser(userId));
    }
  }, []);

  useEffect(() => {
    if (userProfile && !userProfile.userLoading) {
      const isTraveler = _get(userProfile.currentUser, ['is_traveler?']) || false;
      if (isTraveler) {
        window.location.assign(`/`);
      }

      setIsLoaded(true);
    }
  }, [userProfile]);

  return (
    <>
      <ThemeProvider theme={themeVia}>
        <GlobalStyles />
        {isLoaded && includedHeader ? <AdminHeader /> : null}
        {isLoaded && isBrandingSet ? children : null}
      </ThemeProvider>
    </>
  );
}

AdminLayout.propTypes = {
  children: propTypes.node,
  includedHeader: propTypes.bool,
};

AdminLayout.defaultProps = {
  includedHeader: true,
};

export default compose(withStyles(loadedFonts, sModal))(AdminLayout);

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createTravelerMessage } from '../../../../../../actions/profileActions';
import { withCookies } from 'react-cookie';
import s from './CreateMessage.css';
import attach from '../../../../../../../public/img/ico-clip.svg';
import attachmentIcon from '../../../../../../../public/img/ico-page.svg';
import DraftEditorSingle from '../../../../../../components/Admin/ProgramView/DraftEditor/DraftEditorSingle';

class CreateMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      body: '',
      attachments: [],
      submitReady: false,
      submitSubjectReady: false,
      submitBodyReady: false,
      reset: false,
      showError: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.displayErrors = this.displayErrors.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.messageCreate == 200) {
      this.setState({ subject: '', body: '<p> </p>', attachments: [], reset: true });
    }
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    if (value.length > 0 && name == 'subject') {
      this.setState({ submitSubjectReady: true });
    } else if (value.length > 0 && name == undefined) {
      this.setState({ submitBodyReady: true, showError: false });
    } else if (value.length <= 0 && name == 'subject') {
      this.setState({ submitSubjectReady: false });
    } else if (value.length <= 0 && name == undefined) {
      this.setState({ submitBodyReady: false });
    }
    if (name == undefined) {
      this.setState({
        ['body']: value,
        showError: false,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  onDrop() {
    this.setState(prevState => ({
      attachments: [...prevState.attachments, ...event.target.files]
    }))
  }

  removeAttachment(removeAttachmentIndex) {
    let filteredArray = this.state.attachments.filter((file, index) => index !== removeAttachmentIndex);
    this.setState({ attachments: filteredArray });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.subject.length == 0 || this.state.body.length == 0) {
      this.displayErrors();
    } else {
      this.props.createTravelerMessage(this.props.id, this.props.role, {
        subject: this.state.subject,
        body: this.state.body,
        attachments: this.state.attachments,
        attachmentCount: this.state.attachments.length,
      });
      this.setState({ subject: '', body: '', attachments: [] });
    }
  }

  displayErrors() {
    if (this.state.body.length == 0) {
      this.setState({ showError: true });
    }
  }

  setReset = () => {
    this.setState({ reset: false });
  };

  render() {
    const avatar = this.props.role === 'traveler' ? this.props.avatar : this.props.currentUserAvatar;

    const attachments = this.state.attachments.map((fileAttachment, index) => {
      return (
        <div className="row ml-0">
          <div className="col-8 p-0">
            <div className={s.attachment} key={index}>
              <img src={attachmentIcon} />
              <span className={s.extension}>{fileAttachment.name.split('.').pop()}</span>
              <div className={s.fileInfo}>
                <div className={s.fileName}>{fileAttachment.name}</div>
              </div>
              <span className={s.removeAttachment} onClick={() => this.removeAttachment(index)}>
                Remove
              </span>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className={s.container}>
        <img
          src={avatar}
          className={s.avatar}
          alt="User avatar"
        />
        <form className={s.inputContainer} onSubmit={this.handleSubmit}>
          <div className="row mb-3 ml-0">
            <input
              className={`${s.subjectInput} ml-4 col-8`}
              placeholder="Subject Line"
              name="subject"
              autoComplete="off"
              onChange={this.handleInputChange}
              value={this.state.subject}
            />

            <div className="fileUploadWrapper col-1">
              <label htmlFor="file-input">
                <img src={attach} className={s.attachIcon} />
              </label>
              <input id="file-input" type="file" onChange={e => this.onDrop(e)} style={{ display: 'none' }} multiple />
            </div>
          </div>

          <div className="row ml-0">
            <div className="col-8 ml-4 p-0">
              <DraftEditorSingle
                description={this.state.body}
                descriptionEditChild={this.handleInputChange}
                status="newMessage"
                reset={this.state.reset}
                setReset={this.setReset}
              />
            </div>

            <div className={s.buttonWrapper}>
              <button type="submit" className={s.newButton}>
                Send
              </button>
            </div>
          </div>

          {this.state.showError && <div className={` ml-4 ${s.emptyBody}`}>Message cannot be empty</div>}

          {this.state.attachments.length > 0 && attachments}
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  messageCreate: state.profile.messageCreate,
  customBranding: state.profile.customBranding || {},
});

export default compose(
  withCookies,
  withStyles(s),
  connect(
    mapStateToProps,
    { createTravelerMessage },
  ),
)(CreateMessage);

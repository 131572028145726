import React from 'react';
import 'url-search-params-polyfill';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectTravelerProfileTab } from '../../../../actions/profileActions';
import Ability from '../../../../hoc/Ability';
import s from './Sidebar.css';

class Sidebar extends React.Component {
  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let tab = params.get('tab');
    if (tab) {
      this.props.selectTravelerProfileTab(tab);
    }
  }

  buildLink(tab) {
    let path = `/traveler?tab=${tab}`;
    if (this.props.role === 'admin') {
      const pathname = window.location.pathname.split('/');
      const travelerId = pathname[3];
      path = `/client/travelers/${travelerId}?tab=${tab}`;
    }
    return path;
  }

  handleClick(e, tab) {
    this.props.selectTravelerProfileTab(tab);
    if (history.pushState) {
      const path = this.buildLink(tab);
      let newurl = window.location.protocol + '//' + window.location.host + path;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  render() {
    const { role } = this.props;
    const aliasProgram = this.props.customAliases ? this.props.customAliases.alias_program : 'Program';
    const aliasTraveler = this.props.customAliases ? this.props.customAliases.alias_traveler : 'Traveler';
    let aliasPrograms = this.props.customAliases ? `${this.props.customAliases.alias_programs}` : 'Programs';
    aliasPrograms = (role == 'admin') ? `${aliasTraveler} ${aliasPrograms}` : `My ${aliasPrograms}`;
    if (role == 'admin') {
      return (
        <div className={s.sidebar}>
          <Ability module="traveler_information_settings" action="view_and_send_messages">
            <li
              className={this.props.activeTab === 'messages' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'messages')}
            >
              MESSAGES
            </li>
          </Ability>
          <Ability module="traveler_information_settings" action="view_and_create_notes">
            <li
              className={this.props.activeTab === 'notes' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'notes')}
            >
              NOTES
            </li>
          </Ability>
          {this.props.clientFeatureList.legacy && (
            <li
              className={this.props.activeTab === 'programs' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'programs')}
            >
              {aliasPrograms}
            </li>
          )}
          {this.props.clientFeatureList.legacy && (
            <li
              className={this.props.activeTab === 'events' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'events')}
            >
              EVENTS
            </li>
          )}
          <Ability module="traveler_information_settings" action="view_traveler_profile">
            <li
              className={this.props.activeTab === 'profile' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'profile')}
            >
              {aliasTraveler} INFO
            </li>
          </Ability>
          {this.props.clientFeatureList.legacy && (
            <Ability module="traveler_information_settings" action="view_program_preferences">
              <li
                className={this.props.activeTab === 'preferences' ? s.activeFilter : s.filter}
                onClick={e => this.handleClick(e, 'preferences')}
              >
                {aliasProgram} PREFERENCES
              </li>
            </Ability>
          )}
        </div>
      );
    } else if (role == 'traveler') {
      return (
        <div className={s.sidebar}>
          <li
            className={this.props.activeTab === 'messages' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'messages')}
          >
            MESSAGES
          </li>
          <li
            className={this.props.activeTab === 'profile' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'profile')}
          >
            {aliasTraveler} INFO
          </li>
          {this.props.clientFeatureList.legacy && (
            <li
              className={this.props.activeTab === 'preferences' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'preferences')}
            >
              {aliasProgram} PREFERENCES
            </li>
          )}
          {this.props.clientFeatureList.legacy && (
            <li
              className={this.props.activeTab === 'events' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'events')}
            >
              EVENTS
            </li>
          )}
          {this.props.clientFeatureList.legacy && (
            <li
              className={this.props.activeTab === 'programs' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'programs')}
            >
              {aliasPrograms}
            </li>
          )}
        </div>
      );
    }
  }
}

export default compose(
  withStyles(s),
  connect(
    null,
    { selectTravelerProfileTab },
  ),
)(Sidebar);

import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import LoadingScreen from '../../../styledComponents/styles/LoadingScreen';
import userId from '../../../shared/helpers/userId';
import loadedFonts from '../../../shared/fonts/loadFonts.css';
import { themeVia } from '../../../styledComponents/settings/theme';
import { getTravelerEvents } from '../actions/travelerEventsActions';
import { useThemeColors } from '../../../shared/hooks/useThemeColors';
import GlobalStyles from '../../../styledComponents/styles/GlobalStyles';
import { getCurrentOrganization } from '../actions/currentOrganizationActions';
import { getCurrentUser, getTravelerProfile, getInboundOrganization } from '../actions/travelerProfileActions';
import { getUserDetails } from '../../../actions/profileActions';
import { Cookies } from 'react-cookie';
// import useSession from '../../../hooks/useSession';
// import SessionExpireModal from '../../../components/SessionExpireModal';

function Layout({ children, allowNoUser }) {
  const dispatch = useDispatch();
  const isBrandingSet = useThemeColors();
  const { travelerProfileLoading } = useSelector(state => state.travelerProfile);
  const { travelerEventsLoading, events } = useSelector(state => state.travelerEvents);
  const { currentOrganizationLoading } = useSelector(state => state.currentOrganization);
  // const { organizationLoading: currentOrganizationLoading } = useSelector(state => state.auth);
  const { clientFeatureList, currentUser } = useSelector(state => state.profile);
  const [loading, setLoading] = useState(true);
  // const { showSessionExpire, handleCloseSessionModal, countDownSeconds } = useSession();

  const cookies = new Cookies();
  const token = cookies.get('token');

  useEffect(() => {
    let unmounted = false;

    if (!userId && !allowNoUser) {
      window.location.assign('/clear_cookies');
    }

    if (!unmounted && token && userId) {
      dispatch(getTravelerProfile(userId, 'traveler'));
      dispatch(getCurrentOrganization());
      
      // if(!currentUser || !clientFeatureList) {
        dispatch(getCurrentUser(userId));
      // }
      dispatch(getUserDetails(userId));
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted && token && userId && clientFeatureList?.inbound && currentUser?.via_international) {
      dispatch(getInboundOrganization(userId));
    }
    return () => {
      unmounted = true;
    };
  }, [clientFeatureList?.inbound, currentUser?.via_international]);

  useEffect(() => {
    if (window.location.pathname.includes('events') && events.length === 0) {
      dispatch(getTravelerEvents(userId));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const authLoadingCheck = token && userId;
    const baseInfoLoadingCheck = travelerProfileLoading || currentOrganizationLoading;

    if (window.location.pathname.includes('events')) {
      setLoading(authLoadingCheck ? baseInfoLoadingCheck || travelerEventsLoading : false);

      return;
    }

    setLoading(authLoadingCheck ? baseInfoLoadingCheck : false);
  }, [travelerProfileLoading, currentOrganizationLoading, travelerEventsLoading, window.location.pathname]);

  return (
    <ThemeProvider theme={themeVia}>
      <GlobalStyles />
      <div>{isBrandingSet && !loading ? children : <LoadingScreen displayText="Loading..." />}</div>
      {/* {showSessionExpire && (
        <SessionExpireModal
          show={showSessionExpire}
          handleClose={handleCloseSessionModal}
          countDownSeconds={countDownSeconds}
        />
      )} */}
    </ThemeProvider>
  );
}

export default compose(withStyles(loadedFonts))(Layout);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  allowNoUser: PropTypes.bool,
};
